import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import { parse as parseQueryParams } from "query-string";
import PersonSelector from "../../../People/containers/PersonSelector";
import ProductsList from "../sportsProductsNewQuote/ProductsList";
import ProductsListTempSelector from "./productListTempSelector";
import CashBoxList from "./cashboxList";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { CODE_VALIDITY } from "../couponValidator";
import s from "./styles.scss";
import isNil from "lodash.isnil";
import find from "lodash.find";
import { userHasPermission } from "../../../utils/accessTree";
import permissionCodes from "../../../utils/permissionCodes";
import LineaPagoModal from "../sportsProductsNewQuote/LineaPagoModal";
import reduce from "lodash.reduce";

const tokenizationProductId = 10005069;

interface Person {
  personaId: number;
  nombre: string;
  primerApellido: string;
  tipo: string;
}

export interface PRODUCT_STATE {
  sportsProductName: String;
  quantity: number;
  responsible: { value: number; label: string };
  discountCode: string;
  subTotal: number;
  club: string;
  clubId: number;
  sportsProductId: number;
  mesesSinIntereses: number;
  priceObject: { id: number };
  itemId: number;
  discountObject: CODE_VALIDITY;
  tipoItem: string;
  total: number;
  montoIva: number;
  montoDescuento: number;
  porPagar: boolean;
  cotizacionProductoId: number;
  clienteId: number;
  nombreCliente: String;
}

const itemToProduct = ({
  objetoPrecio,
  mesesSinIntereses,
  producto: { id: productoId, nombre },
  unidades,
  responsable,
  codigoDescuento,
  club: { clubId, nombre: nombreClub },
  itemId,
  tipoItem,
  objetoDescuento,
  total,
  subtotal,
  estatus,
  iva,
  montoDescuento,
  montoIva,
  porPagar,
  eliminable,
  nombreCliente
}) => {
  return {
    itemId,
    subtotal,
    sportsProductName: nombre,
    quantity: unidades,
    responsible: responsable
      ? userToOption(responsable)
      : { label: "", value: "" },
    discountCode: codigoDescuento || "",
    club: nombreClub,
    sportsProductId: productoId,
    priceObject: objetoPrecio ? objetoPrecio : null,
    mesesSinIntereses: mesesSinIntereses || 0,
    clubId,
    tipoItem,
    total,
    discountObject: objetoDescuento ? objetoDescuento : null,
    estatus,
    iva,
    montoDescuento,
    montoIva,
    porPagar,
    eliminable,
    nombreCliente
  };
};

enum SEARCH_TYPE {
  PERSON_ID = "PERSON_ID",
  PARTNER_MEMBERSHIP = "PARTNER_MEMBERSHIP",
  MULTI = "MULTI"
}

const getLinkCaja = cajaId => `/productos/caja/${cajaId}`;

const userToOption = ({ userId, name, firstSurname, secondSurname }) => ({
  label: `${name} ${firstSurname} ${secondSurname}`,
  value: userId
});

interface Props {
  clubs: { clubId: number; nombre: string }[];
  agreements: any;
  saveSPQuote: (data: object) => any;
  history: any;
  location: any;
  getQuoteByPersonId: (personId: number, params: object) => any;
  getQuoteByMembership: (params: object) => any;
  getQuoteFilteredClubAgreement: (params: object) => any;
  getCashboxPersonId: (personId: number) => any;
  getCashboxByMembership: (params: object) => any;
  deleteProducts: (productId: string) => any;
  deletingProduct: boolean;
  deletingProductError: object;
}

interface State {
  productList: PRODUCT_STATE[];
  selectedPerson: Person;
  selectedPersonFacturacion: Person;
  checkboxes: {
    id: number;
    clienteId: number;
    estatus: string;
    fechaCreacion: string;
    items: number;
  }[];
  indexDeleteProduct: number;
  freezeObject: object;
  searchBy: SEARCH_TYPE;
  searchByMembershipClubId: number;
  searchByMembershipPublicId: string;
  searchByMultiClubId: number;
  searchByMultiPublicId: string;
  searchByMultiAggreementId: number;

  quoteClientId: number;
  quoteClientType: String;

  showModal: boolean;
  productListMultipleSelection: PRODUCT_STATE[];

  generateCashboxWithPaymentLineConfirmModalIsOpen: boolean;
  generateCashboxWithPaymentLineAmount: number;
  generateCashboxWithPaymentLineHasMembOrTokenization: boolean;
  generateCashboxWithPaymentLineHasMaintenances: boolean;
}

class SportsProductsQuotesSelector extends React.Component<Props, State> {
  state = {
    productList: [],
    selectedPerson: null,
    selectedPersonFacturacion: null,
    checkboxes: null,
    indexDeleteProduct: null,
    freezeObject: {},
    searchBy: SEARCH_TYPE.MULTI,
    searchByMembershipClubId: 0,
    searchByMembershipPublicId: "",
    searchByMultiClubId: 0,
    searchByMultiPublicId: "",
    searchByMultiAggreementId: 0,
    quoteClientId: null,
    quoteClientType: null,
    showModal: false,
    productListMultipleSelection: [],

    generateCashboxWithPaymentLineConfirmModalIsOpen: false,
    generateCashboxWithPaymentLineAmount: 0,
    generateCashboxWithPaymentLineHasMembOrTokenization: false,
    generateCashboxWithPaymentLineHasMaintenances: false
  };

  clear = (searchBy: SEARCH_TYPE) => {
    this.setState({
      productList: [],
      selectedPerson: null,
      selectedPersonFacturacion: null,
      checkboxes: null,
      indexDeleteProduct: null,
      freezeObject: {},
      searchBy,
      searchByMembershipClubId: -1,
      searchByMembershipPublicId: "",
      searchByMultiClubId: 0,
      searchByMultiPublicId: "",
      searchByMultiAggreementId: 0,
      quoteClientId: null,
      quoteClientType: null,
      showModal: false,
      productListMultipleSelection: [],

      generateCashboxWithPaymentLineConfirmModalIsOpen: false,
      generateCashboxWithPaymentLineAmount: 0,
      generateCashboxWithPaymentLineHasMembOrTokenization: false,
      generateCashboxWithPaymentLineHasMaintenances: false
    });
  };

  clearData = () => {
    this.setState({
      productList: [],
      checkboxes: null,
      productListMultipleSelection: []
    });
  };

  setFreezeObject = data => {
    this.setState({ freezeObject: data });
  };

  componentDidMount() {
    const { idPublico, clubId } = parseQueryParams(this.props.location.search);
    this.setState({
      searchByMultiClubId: clubId,
      searchByMultiPublicId: idPublico
    });
  }

  componentDidUpdate() {
    if (!isNil(this.state.indexDeleteProduct)) {
      if (
        this.props.deletingProduct == false &&
        isNil(this.props.deletingProductError)
      ) {
        let valDelete = this.state.productList[this.state.indexDeleteProduct]
          .itemId;
        let copyFreezeObject = Object.keys(this.state.freezeObject).reduce(
          (object, key) => {
            if (key != valDelete) {
              object[key] = this.state.freezeObject[key];
            }
            return object;
          },
          {}
        );
        this.state.productList.splice(this.state.indexDeleteProduct, 1);
        const productList = [...this.state.productList];
        this.setState({
          productList,
          indexDeleteProduct: null,
          freezeObject: copyFreezeObject
        });
      }
    }
  }

  setChecked = (productId, checkedValue) => {
    const product = find(this.state.productList, p => p.itemId == productId);
    product.porPagar = checkedValue;
  };

  loadQuoteDataByPersonId = () => {
    const { personaId, nombre, primerApellido } = this.state.selectedPerson;
    this.props.getQuoteByPersonId(personaId, {}).then(({ value: { data } }) => {
      const { productos, cliente, tipoCliente } = data;
      const { personaId } = cliente;

      productos.forEach(p => {
        p["nombreCliente"] = `${nombre} ${primerApellido}`;
      });
      const productList = productos.map(itemToProduct);

      this.setState({
        productList,
        quoteClientId: personaId,
        quoteClientType: tipoCliente
      });
    });

    this.props
      .getCashboxPersonId(personaId)
      .then(({ value: { data: checkboxes } }) => {
        this.setState({ checkboxes });
      });
  };

  loadQuoteDataByMembership = () => {
    const {
      searchByMembershipClubId: clubId,
      searchByMembershipPublicId: membresia
    } = this.state;

    this.props
      .getQuoteByMembership({ clubId, membresia })
      .then(({ value: { data } }) => {
        const { productos, cliente, tipoCliente } = data;
        const { personaId } = cliente;

        const productList = productos.map(itemToProduct);
        this.setState({
          productList,
          quoteClientId: personaId,
          quoteClientType: tipoCliente
        });
      })
      .catch(({ message }) => {
        errorNotification(message);
      });

    this.props
      .getCashboxByMembership({ clubId, membresia })
      .then(({ value: { data: checkboxes } }) => {
        this.setState({ checkboxes });
      })
      .catch(({ message }) => {
        errorNotification(message);
      });
  };

  loadQuoteDataByMultiple = callback => {
    const {
      searchByMultiClubId: clubId,
      searchByMultiPublicId: membresia,
      searchByMultiAggreementId: convenioId
    } = this.state;

    const execute = ({ value: { data } }) => {
      const { productos } = data;

      const currentAdded = [...this.state.productList].map(
        (x: PRODUCT_STATE) => x.itemId
      );

      const productListMultipleSelection = productos
        .map(itemToProduct)
        .filter((x: PRODUCT_STATE) => currentAdded.indexOf(x.itemId) < 0);

      this.setState(
        {
          productListMultipleSelection
        },
        callback
      );
    };

    const noItemsFound = "No se encontraron elementos";

    if (!!clubId && !!membresia) {
      this.props
        .getQuoteByMembership({ clubId, membresia })
        .then(execute)
        .catch(({ message }) => {
          errorNotification(noItemsFound);
        });
    } else if (!!clubId || !!convenioId) {
      this.props
        .getQuoteFilteredClubAgreement({
          clubId: clubId || null,
          convenioId: convenioId || null
        })
        .then(execute)
        .catch(({ message }) => {
          errorNotification(noItemsFound);
        });
    }
  };

  setPerson = (selectedPerson: Person) => {
    if (!isNil(selectedPerson)) {
      this.setState(
        {
          selectedPerson
        },
        () => this.loadQuoteDataByPersonId()
      );
    }
  };

  setPersonFacturacion = (selectedPerson: Person) => {
    if (!isNil(selectedPerson)) {
      const { personaId, tipo } = selectedPerson;
      let quoteClientType = null;

      switch (tipo) {
        case "EXTERNO":
        case "PROSPECTO":
          quoteClientType = "EXTERNO";
          break;
        case "CLIENTE_TITULAR":
        case "CLIENTE_INTEGRANTE":
        case "CLIENTE":
          quoteClientType = "SOCIO";
          break;
        case "EMPLEADO":
          quoteClientType = "EMPLEADO";
          break;
      }

      this.setState({
        selectedPersonFacturacion: selectedPerson,
        quoteClientId: personaId,
        quoteClientType
      });
    }
  };

  deleteProduct = idx => {
    this.setState({ indexDeleteProduct: idx });
  };

  productStateToProduct = (p: PRODUCT_STATE) => ({
    itemId: p.itemId ? p.itemId : null,
    productoId: p.sportsProductId,
    responsableId: p.responsible && p.responsible.value,
    codigoDescuento: p.discountObject ? p.discountObject.codigoDescuento : null,
    unidades: p.quantity,
    precioId: p.priceObject ? p.priceObject.id : null,
    clubId: p.clubId,
    mesesSinIntereses: p.mesesSinIntereses,
    porPagar: p.porPagar,
    total: p.total
  });

  saveProducts = (
    productos,
    f,
    withPaymentLine: boolean = false,
    paymentLineConfig: any = false
  ) => {
    const {
      quoteClientId,
      quoteClientType,
      searchBy,
      selectedPersonFacturacion
    } = this.state;

    if (!productos.length) {
      errorNotification("Debe agregar items a la lista.");
      return;
    }

    if (searchBy === SEARCH_TYPE.MULTI && !selectedPersonFacturacion) {
      errorNotification(
        "Debe seleccionar la persona para el pago y facturación de la caja."
      );
      return;
    }

    if (!quoteClientId || !quoteClientType) {
      errorNotification("No se puede crear una caja sin propietario");
      return;
    }

    this.props
      .saveSPQuote({
        productos,
        clienteId: quoteClientId,
        tipoCliente: quoteClientType,
        lineaDePago: withPaymentLine,
        lineaPagoTokenizar:
          withPaymentLine && !!paymentLineConfig
            ? paymentLineConfig.lineaPagoTokenizar
            : false,
        lineaDePagoTotal:
          withPaymentLine && !!paymentLineConfig
            ? paymentLineConfig.lineaDePagoTotal
            : null,
        lineaPagoMesesSinIntereses:
          withPaymentLine && !!paymentLineConfig
            ? paymentLineConfig.lineaPagoMesesSinIntereses
            : 0
      })
      .then(({ value: { data: { cajaId, error, message } } }) => {
        if (error) {
          errorNotification(message);
        } else {
          f(cajaId);
        }
      });
  };

  onClickSaveCashbox = () => {
    const productos = this.state.productList.map(this.productStateToProduct);

    this.saveProducts(productos, cajaId => {
      if (!isNil(cajaId)) {
        this.props.history.push(getLinkCaja(cajaId));
      }
    });
  };

  onClickSaveWithPaymentLine = () => {
    const productos = [...this.state.productList];

    if (productos.length <= 0) {
      errorNotification(
        "No hay productos seleccionables para generar la línea de pago "
      );
      return;
    }

    const aPagar = reduce(
      productos,
      (acc, { total }) => acc + total,
      0
    ).toFixed(2);

    if (aPagar <= 0) {
      errorNotification(
        "El monto para generar la línea de pago no puede ser 0"
      );
      return;
    }

    const hasMnts = productos.some(x => x.tipoItem == "MANTENIMIENTO");
    const hasMembOrTokenization = productos.some(
      x =>
        x.tipoItem == "MEMBRESIA" ||
        (x.tipoItem == "PRODUCTO" && x.sportsProductId == tokenizationProductId)
    );

    this.setState({
      generateCashboxWithPaymentLineConfirmModalIsOpen: true,
      generateCashboxWithPaymentLineAmount: aPagar,
      generateCashboxWithPaymentLineHasMembOrTokenization: hasMembOrTokenization,
      generateCashboxWithPaymentLineHasMaintenances: hasMnts
    });
  };

  saveWithPaymentLine = (tokenizar: boolean, msi: number) => {
    infoNotification("Generando línea de pago");

    const productos = [...this.state.productList].map(
      this.productStateToProduct
    );

    const { generateCashboxWithPaymentLineAmount } = this.state;

    this.saveProducts(
      productos,
      cajaId => {
        successNotification("Línea de pago generada correctamente.");
        this.clear(SEARCH_TYPE.PARTNER_MEMBERSHIP);
      },
      true,
      {
        lineaDePagoTotal: generateCashboxWithPaymentLineAmount,
        lineaPagoTokenizar: false,
        lineaPagoMesesSinIntereses: msi
      }
    );
  };

  onChangeSearchBy = e => {
    this.clear(e.target.value);
  };

  onChangeSearchFilters = fieldName => {
    return event => {
      const value = event.target.value;

      switch (fieldName) {
        case "searchByMultiPublicId":
          this.state.searchByMultiAggreementId = "";
          break;
        case "searchByMultiAggreementId":
          this.state.searchByMultiPublicId = "";
          break;
      }

      this.setState(state => {
        return { ...state, [fieldName]: value };
      });
    };
  };

  searchByPartnerMembershipFilters = () => {
    this.clearData();
    this.loadQuoteDataByMembership();
  };

  //Multiple search behavior
  searchByMultipleFilters = () => {
    if (
      !!this.state.searchByMultiClubId &&
      (!!this.state.searchByMultiPublicId ||
        !!this.state.searchByMultiAggreementId)
    ) {
      infoNotification("Consultando cargos...");
      this.loadQuoteDataByMultiple(() => this.toggleModal());
    }
  };

  setCheckedTempSelection = (productId, checkedValue) => {
    const product = find(
      this.state.productListMultipleSelection,
      p => p.itemId == productId
    );
    product.porPagar = checkedValue;
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  clearSearchByMultipleState = () => {
    this.setState({
      productListMultipleSelection: []
    });
  };

  handleCancelMultipleProductoSelectionEdit = () => {
    this.toggleModal();
    this.clearSearchByMultipleState();
  };

  handleAcceptMultipleProductoSelectionEdit = e => {
    e.preventDefault();
    const {
      productList: pList,
      productListMultipleSelection: pListMultiSelection
    } = this.state;
    const productList = [
      ...pList,
      ...pListMultiSelection.filter((x: PRODUCT_STATE) => x.porPagar)
    ];

    productList.forEach(x => {
      x.eliminable = false;
    });
    this.setState(
      {
        productList
      },
      () => this.handleCancelMultipleProductoSelectionEdit()
    );
  };

  render() {
    if (!userHasPermission(permissionCodes.SHOPPING_CART_MULTIPLE)) {
      return (
        <Container className="w-100 p-0 my-3">
          <div className="alert alert-danger">
            No tiene permisos para realizar cargos múltiples.
          </div>
        </Container>
      );
    }

    return (
      <Container className="w-100 p-0 my-3">
        <p className={`${s.grayTitle} w-100 my-1`}>Buscar por</p>

        <div className="m-2 text-center">
          {/* <label className="d-inline-block p-2 mx-3">
            <input
              type="radio"
              id="searchByPersonId"
              value={SEARCH_TYPE.PERSON_ID}
              onChange={this.onChangeSearchBy}
              checked={this.state.searchBy === SEARCH_TYPE.PERSON_ID}
            />{" "}
            Persona
          </label>

          <label className="d-inline-block p-2 mx-3">
            <input
              type="radio"
              id="searchByPartnerMembership"
              value={SEARCH_TYPE.PARTNER_MEMBERSHIP}
              onChange={this.onChangeSearchBy}
              checked={this.state.searchBy === SEARCH_TYPE.PARTNER_MEMBERSHIP}
            />{" "}
            Membresia socio
          </label> */}

          <label className="d-inline-block p-2 mx-3">
            <input
              type="radio"
              id="searchByMulti"
              value={SEARCH_TYPE.MULTI}
              onChange={this.onChangeSearchBy}
              checked={this.state.searchBy === SEARCH_TYPE.MULTI}
            />{" "}
            Varios
          </label>
        </div>

        <div className="d-flex d-flex justify-content-center">
          <div
            className={
              this.state.searchBy === SEARCH_TYPE.MULTI ? "w-75" : "w-50"
            }
          >
            {this.state.searchBy === SEARCH_TYPE.PERSON_ID && (
              <PersonSelector
                selectedPerson={this.state.selectedPerson}
                setPerson={this.setPerson}
                cKey={"NSE79E8QLJ"}
              />
            )}

            {this.state.searchBy === SEARCH_TYPE.PARTNER_MEMBERSHIP && (
              <Row>
                <Col>
                  <BaseInput
                    label="Club"
                    name="byMembershipClubId"
                    id="byMembershipClubId"
                    type="select"
                    placeholder="Seleccionar Club"
                    value={this.state.searchByMembershipClubId}
                    onChange={this.onChangeSearchFilters(
                      "searchByMembershipClubId"
                    )}
                    size={"sm"}
                    options={[{ value: "", label: "Seleccionar Club" }].concat(
                      this.props.clubs
                        ? this.props.clubs.map(option => ({
                            value: option.clubId.toString(),
                            label: option.nombre
                          }))
                        : []
                    )}
                  />
                </Col>
                <Col>
                  <BaseInput
                    label={"Id Membresia Socio"}
                    name={"byMembershipPublicId"}
                    id={"byMembershipPublicId"}
                    placeholder="Id Membresia Socio"
                    value={this.state.searchByMembershipPublicId}
                    size="sm"
                    onChange={this.onChangeSearchFilters(
                      "searchByMembershipPublicId"
                    )}
                  />
                </Col>
                <Col>
                  <br />
                  <Button
                    size="sm"
                    className={`${s.searchButton} ${s.primaryButton} btn-block`}
                    onClick={this.searchByPartnerMembershipFilters}
                    disabled={
                      !this.state.searchByMembershipClubId ||
                      !this.state.searchByMembershipPublicId
                    }
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            )}

            {this.state.searchBy === SEARCH_TYPE.MULTI && (
              <Row>
                <Col>
                  <BaseInput
                    label="Club"
                    name="byMultipleClubId"
                    id="byMultipleClubId"
                    type="select"
                    placeholder="Seleccionar Club"
                    value={this.state.searchByMultiClubId}
                    onChange={this.onChangeSearchFilters("searchByMultiClubId")}
                    size={"sm"}
                    options={[{ value: "", label: "Seleccionar Club" }].concat(
                      (this.props.clubs || [])
                        .filter(x => !!x.migradoCrm2)
                        .map(option => ({
                          value: option.clubId.toString(),
                          label: option.nombre
                        }))
                    )}
                  />
                </Col>
                <Col>
                  <BaseInput
                    label={"Id Membresia Socio"}
                    name={"byMultiplePublicId"}
                    id={"byMultiplePublicId"}
                    placeholder="Id Membresia Socio"
                    value={this.state.searchByMultiPublicId}
                    size="sm"
                    onChange={this.onChangeSearchFilters(
                      "searchByMultiPublicId"
                    )}
                  />
                </Col>
                <Col>
                  <BaseInput
                    label="Convenio"
                    name="byMultipleAgreementId"
                    id="byMultipleAgreementId"
                    type="select"
                    placeholder="Seleccionar Club"
                    value={this.state.searchByMultiAggreementId}
                    onChange={this.onChangeSearchFilters(
                      "searchByMultiAggreementId"
                    )}
                    size={"sm"}
                    options={[
                      { value: "", label: "Seleccionar convenio" }
                    ].concat(
                      (this.props.agreements || [])
                        .sort((a, b) => a.nombre.localeCompare(b.nombre))
                        .map(option => ({
                          value: option.convenioId,
                          label: option.nombre
                        }))
                    )}
                  />
                </Col>
                <Col>
                  <br />
                  <Button
                    size="sm"
                    className={`${s.searchButton} ${s.primaryButton} btn-block`}
                    onClick={this.searchByMultipleFilters}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </div>

        {this.state.searchBy !== SEARCH_TYPE.MULTI && (
          <div>
            <p className={`${s.grayTitle} w-100 my-3`}>
              Listado de cajas abiertas
            </p>
            <Row>
              <Col>
                <CashBoxList
                  cashboxList={this.state.checkboxes}
                  selectCashbox={(id: number) =>
                    this.props.history.push(getLinkCaja(id))
                  }
                  loading={false}
                />
              </Col>
            </Row>
          </div>
        )}

        <p className={`${s.grayTitle} w-100 my-3`}>
          Lista de productos en carrito:
        </p>

        <ProductsList
          products={this.state.productList}
          deleteProduct={this.deleteProduct}
          minRows={6}
          loading={false}
          setChecked={this.setChecked}
          freezeObject={this.state.freezeObject}
          setFreezeObject={this.setFreezeObject}
          showClientNameColumn={true}
        />

        {this.state.searchBy === SEARCH_TYPE.MULTI && (
          <div>
            <p className={`${s.grayTitle} w-100 my-3`}>
              Persona para facturación
            </p>
            <div className="d-flex d-flex justify-content-center">
              <div className="w-50">
                <PersonSelector
                  selectedPerson={this.state.selectedPersonFacturacion}
                  setPerson={this.setPersonFacturacion}
                  cKey={"06BNFPWAA0"}
                />
              </div>
            </div>
          </div>
        )}

        <Row className="m-4">
          <Col />
          <Col className="p-0" xs="2">
            <Button
              className={`${s.primaryButton} my-1`}
              onClick={e => {
                e.preventDefault();
                this.onClickSaveCashbox();
              }}
              size="sm"
              block
            >
              Crear caja y Pagar
            </Button>
          </Col>
          <Col className="p-0 pl-1 pr-1" xs="3">
            <Button
              className={`${s.secondaryButton} my-1`}
              onClick={e => {
                e.preventDefault();
                this.onClickSaveWithPaymentLine();
              }}
              size="sm"
              block
            >
              Guardar y Generar línea de pago
            </Button>
          </Col>
        </Row>

        {/* MODAL */}

        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggleModal}
          fade={true}
          size="lg"
          backdrop="static"
          keyboard={false}
          className={`w-100 ${s.modalSelector}`}
        >
          <ModalHeader toggle={this.toggleModal}>
            Seleccione los productos de la lista
          </ModalHeader>
          <ModalBody>
            <ProductsListTempSelector
              products={this.state.productListMultipleSelection}
              loading={false}
              setChecked={this.setCheckedTempSelection}
              freezeObject={this.state.freezeObject}
              setFreezeObject={this.setFreezeObject}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              className={`${s.primaryButton} d-inline-block mr-2`}
              onClick={this.handleAcceptMultipleProductoSelectionEdit}
            >
              GUARDAR
            </Button>
            <Button
              className={`${s.secondaryButton} d-inline-block mr-2`}
              onClick={this.handleCancelMultipleProductoSelectionEdit}
            >
              CANCELAR
            </Button>
          </ModalFooter>
        </Modal>

        <LineaPagoModal
          isOpen={this.state.generateCashboxWithPaymentLineConfirmModalIsOpen}
          amount={this.state.generateCashboxWithPaymentLineAmount}
          showTokenizationControl={false}
          hasMembershipsOrTokenizationProducts={
            this.state.generateCashboxWithPaymentLineHasMembOrTokenization
          }
          hasMaintenancesProducts={
            this.state.generateCashboxWithPaymentLineHasMaintenances
          }
          generarLineaPago={(tokenizar, msi) => {
            this.setState({
              generateCashboxWithPaymentLineConfirmModalIsOpen: false,
              generateCashboxWithPaymentLineAmount: 0,
              generateCashboxWithPaymentLineHasMembOrTokenization: false,
              generateCashboxWithPaymentLineHasMaintenances: false
            });
            this.saveWithPaymentLine(tokenizar, msi);
          }}
          cancelar={() => {
            this.setState({
              generateCashboxWithPaymentLineConfirmModalIsOpen: false,
              generateCashboxWithPaymentLineAmount: 0,
              generateCashboxWithPaymentLineHasMembOrTokenization: false,
              generateCashboxWithPaymentLineHasMaintenances: false
            });
          }}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(SportsProductsQuotesSelector));
