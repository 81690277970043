import { Payment } from "./PaymentsTable";
import IAction from "../../../types/IAction";

export interface QUOTE {
  cliente: object;
  estatus: string;
  id: number;
  montoDescuentos: number;
  montoIva: number;
  productos: object[];
  subtotal: number;
  tipoCliente: string;
  total: number;
  pagos: Payment[];
  sucursalClubId: number;
  membresiaSocioEstatus: string;
  forzarLineaPago: boolean;
}

export interface Props {
  cashboxById: QUOTE;
  banksMonthsWithoutInterest: Banks[];
  waysPay: WayPay[];
  saving: boolean;
  match: any;
  personaId: string;
  loading: boolean;
  newCart: object;
  cartCreated: boolean;
  productsList: object[];
  history: any;
  isPending: boolean;
  amountPending: number;
  terminal: any;
  companiesTypes: object[];
  referencesNotUsed: object[];
  userContext: object;
  payQuote: (personaId: string, data: object) => any;
  saveSPQuote: (data: object) => any;
  pendingPayment: (amount: number) => any;
  payPending: () => any;
  getCashboxById: (id: string) => any;
  addLogueoRedux: (data: object) => any;
  clearLogueoRedux: () => any;
  getRefenceSantanderCargo: (
    clubId: string,
    idMovimiento: string,
    formaDePago: string
  ) => any;
  getRefenceSantanderComplemento: (
    clubId: string,
    monto: string,
    formaDePago: string
  ) => any;
  clearReference: () => any;
  getReferencesNotUsed: (clubId: string) => IAction;
}

interface Fields {
  referencia: string;
  numeroAutorizacion: string;
  origenPago: string;
  formaDePago: string;
  mesesSinIntereses: number;
  bancoId: string;
  monto: string | number;
  numeroCheque: string;
  numeroCuenta: string;
  empresaEmisoraId: string | number;
  tokenizar: boolean;
}

interface WayPay {
  id: string;
  nombre: string;
}

interface Banks {
  mesesSinInteresesId: string;
  bancoId: string;
  nombre: string;
  tresMeses: string;
  estatusTresMeses: boolean;
  seisMeses: string;
  estatusSeisMeses: boolean;
  nueveMeses: string;
  estatusNueveMeses: boolean;
  doceMeses: string;
  estatusDoceMeses: boolean;
}

export interface State {
  fields: Fields;
  mesesSinIntereses: string;
  optionsMonthsWithoutInterest: any[];
  products: any;
  pagos: Payment[];
  quote: QUOTE;
  isPending: boolean;
  newCart: any;
  freezeObject: object;
  reimpresionModal: boolean;
  objetoReimpresion: any;
  permission99: string[];
  getReference: boolean;
  referenciaOtorgadaPorTesoreria: boolean;
  pagoRealizadoPorApp: boolean;
  readCard: object;
  showModalNoSale: boolean;
  userMitTerminalCredentials: { mitUser: string; mitPassword: string };
  tmpMitUser: string;
  tmpMitPassword: string;
  showModalUpdateMitCredentials: boolean;
  showModalDatosFactValidacion: boolean;
  acceptedModalDatosFactValidacion: boolean;

  applyingMainPayment: boolean;
  applyingCardPayment: boolean;
}

export const mesesSinInteresesDefault = [
  {
    id: 0,
    nombre: "Contado",
    active: true
  }
];

export const mesesSinInteresesMonths = [
  {
    id: 3,
    nombre: "3 Meses sin intereses",
    active: false
  },
  {
    id: 6,
    nombre: "6 Meses sin intereses",
    active: false
  },
  {
    id: 9,
    nombre: "9 Meses sin intereses",
    active: false
  },
  {
    id: 12,
    nombre: "12 Meses sin intereses",
    active: false
  }
];

export const AMEX_BANK_NAME = ["American Express", "AMEX", "amex"];

export enum OrigenPlataformaVentaPago {
  CRM2 = "CRM2",
  APP = "APP",
  LINEA_PAGO = "LINEA_PAGO",
  PORTAL_WEB = "PORTAL_WEB",
  MANUAL_APP = "MANUAL_APP",
  TPV_ALAMBRICA = "TPV_ALAMBRICA"
}
