import {
  GetCmsClasesAsignables,
  GetCmsProductosOperacion,
  PostCmsAsignacion
} from "../../../reducers/comisionesV2";
import * as actions from "../../../actions/comisionesV2";
import { Preview } from "../../../../types/IAction";

export interface BAsignacionDispatcher {
  getCmsClasesAsignables: (
    params?: any
  ) => Promise<Preview<GetCmsClasesAsignables>>;
  getCmsProductosOperacion: (
    params?: any
  ) => Promise<Preview<GetCmsProductosOperacion>>;
  postCmsAsignacion: (
    body: any,
    esClasePersonalizada: boolean
  ) => Promise<Preview<PostCmsAsignacion>>;
}

export const bAsignacionDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => BAsignacionDispatcher = dispatch => ({
  getCmsClasesAsignables: (params?: any) => {
    return dispatch(actions.getCmsClasesAsignables(params));
  },
  getCmsProductosOperacion: (params?: any) => {
    return dispatch(actions.getCmsProductosOperacion(params));
  },
  postCmsAsignacion: (body: any, esClasePersonalizada: boolean) => {
    return dispatch(actions.postCmsAsignacion(body, esClasePersonalizada));
  }
});
