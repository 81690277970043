import {
  GetCmsProductosVenta,
  GetCmsVentas,
  PutCmsCoordinador,
  PutCmsInstructorPrincipal,
  PutComisionVendedor
} from "../../../reducers/comisionesV2";

export interface AVentaStore
  extends GetCmsProductosVenta,
    GetCmsVentas,
    PutComisionVendedor,
    PutCmsInstructorPrincipal,
    PutCmsCoordinador {
  userContext: any;
}

export const aVentaStore: (state) => AVentaStore = state => {
  const { comisionesV2, user } = state;
  return {
    cmsProductosVenta: comisionesV2.cmsProductosVenta,
    gettingCmsProductosVenta: comisionesV2.gettingCmsProductosVenta,
    gettingCmsProductosVentaError: comisionesV2.gettingCmsProductosVentaError,

    cmsVentas: comisionesV2.cmsVentas,
    gettingCmsVentas: comisionesV2.gettingCmsVentas,
    gettingCmsVentasError: comisionesV2.gettingCmsVentasError,

    puttingCmsVendedor: comisionesV2.puttingCmsVendedor,
    puttingCmsVendedorError: comisionesV2.puttingCmsVendedorError,

    puttingCmsInstructorPrincipal: comisionesV2.puttingCmsInstructorPrincipal,
    puttingCmsInstructorPrincipalError:
      comisionesV2.puttingCmsInstructorPrincipalError,

    puttingCmsCoordinador: comisionesV2.puttingCmsCoordinador,
    puttingCmsCoordinadorError: comisionesV2.puttingCmsCoordinadorError,

    userContext: user.userContext
  };
};
