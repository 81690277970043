import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";
import { UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "../../Home/components/common/confirmModal/confirmModal";

interface Props {
  id: string;
  tooltipTitle: string;
  icon: any;
  onClick: Function;
  color: String;
  colorBackground: String;
  toolTipPlacement: String;
  confirmModal: { title: string; message: string };
  size: string;
}

interface State {
  confirmAuthorizeModalIsOpen: boolean;
}

class BaseButtonWithIconAndTooltip extends React.Component<Props, State> {
  state = {
    confirmAuthorizeModalIsOpen: false
  };

  render() {
    const {
      id: buttonId,
      tooltipTitle,
      icon,
      onClick,
      color,
      toolTipPlacement,
      confirmModal,
      size
    } = this.props;

    return (
      <React.Fragment>
        {!!confirmModal && (
          <ConfirmModal
            title={confirmModal.title || "Confirmar"}
            message={
              confirmModal.message || "¿Desea continuar con la operación?"
            }
            isOpen={this.state.confirmAuthorizeModalIsOpen}
            ok={() => {
              this.setState({
                confirmAuthorizeModalIsOpen: false
              });

              if (!!onClick) {
                onClick();
              }
            }}
            cancel={() => {
              this.setState({
                confirmAuthorizeModalIsOpen: false
              });
            }}
          />
        )}
        <span
          id={buttonId}
          className={size == "sm" ? "ml-2" : "ml-3"}
          style={{
            cursor: "pointer",
            fontSize: size == "sm" ? "1em" : "1.2em"
          }}
          onClick={e => {
            e.preventDefault();
            if (!!confirmModal) {
              this.setState({
                confirmAuthorizeModalIsOpen: true
              });
            } else {
              if (!!onClick) {
                onClick();
              }
            }
          }}
        >
          <FontAwesomeIcon icon={icon} color={color || ""} />
          <UncontrolledTooltip
            placement={toolTipPlacement || "top"}
            target={buttonId}
          >
            {tooltipTitle}
          </UncontrolledTooltip>
        </span>
      </React.Fragment>
    );
  }
}

export default withStyles(s)(BaseButtonWithIconAndTooltip);
