import Reform from "@franleplant/reform";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import isNil from "lodash.isnil";
import moment from "moment";
import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { isArray, isFunction } from "util";
import { postValidarCodigo } from "../../../Home/actions/persons";
import { Person } from "../../../Home/components/oneDayPassActivatorPage/interfaces";
import { Prospect } from "../../../types-business/User";
import { errorNotification } from "../../../utils/notifications";
import permissionCodes from "../../../utils/permissionCodes";
import PeopleBasicDataForm, { getCleanFields } from "../PeopleBasicDataForm";
import s from "./styles.scss";
import { userHasPermission } from "../../../utils/accessTree";

const CONCESIONARIO_TIPO = "CONCESIONARIO";
const PROSPECTO_TIPO = "PROSPECTO";
const {
  PROSPECT_EDITION_OMITIR_CURP,
  PROSPECT_EDITION_OMITIR_VERIFICACION
} = permissionCodes;

interface Props {
  viewMode: boolean;
  prospectToEdit: Person;
  createProspect: (prospect: object) => any;
  history: any;
  match: any;
  editProspect: (personaId: string, prospect: any) => any;
  userId: string;
  creatingProspect: any;
  creatingProspectError: any;
  creatingProspectValues: any;
  onChange: (prospect: object) => any;
  disableDetailsButton: boolean;
  small: boolean;
  isConcesionario: boolean;
  isCorporativo: boolean;
  isProspecto: boolean;
  isMoralPersonType: boolean;
  empresasConvenio: any[];
  validateEmail?: (id: number, correo: string) => void;
  debouncedGetProspects: any;
}

interface State {
  fields: Prospect;
  correoValidado: boolean;
  correoActual: string;
  promptCodigo: string;
  promptMensaje: string;
  promptActivo: boolean;
  promptResolver: any;
  promptRejecter: any;
}

const dateInPastValidator = value => {
  const now = new Date();
  const date = new Date(value);
  return date >= now;
};

const prospectToFields = prospect => {
  const {
    correosElectronicos,
    telefonos,
    segundoApellido,
    medioProspeccion
  } = prospect;

  let correoElectronico = "";
  let correoValidado = false;
  let numeroTelefono = "";
  let extension = "";

  if (
    !isNil(correosElectronicos) &&
    isArray(correosElectronicos) &&
    correosElectronicos.length >= 1
  ) {
    const correoData =
      correosElectronicos.find(c => c.principal) || correosElectronicos[0];
    correoElectronico = correoData != null ? correoData.correo : "";
    correoValidado = correoData != null ? !!correoData.validado : false;
  }

  if (!isNil(telefonos) && isArray(telefonos) && telefonos.length >= 1) {
    const telefono = telefonos[0].numero || "";
    [extension, numeroTelefono] = telefono.split("-");
    if (!numeroTelefono) {
      numeroTelefono = extension;
      extension = "";
    }
  }

  const fields = {
    ...prospect,
    correoElectronico,
    correoValidado,
    numeroTelefono,
    extension,
    segundoApellido: segundoApellido || "",
    medioProspeccion: medioProspeccion || ""
  };

  return fields;
};

const loadValidationRules = props => {
  const tmp = {
    correoElectronico: { email: true, required: true, maxLength: 255 },
    nombre: { required: true },
    primerApellido: { required: true },
    segundoApellido: {},
    sexo: { required: true },
    medioProspeccion: { required: true },
    precioMembresia: { required: true },
    mantenimiento: { required: true },
    precioMantenimiento: { required: true },
    numeroTelefono: {
      required: true,
      phoneNumberDigits: value => (value || "").length < 10
    },
    fechaNacimiento: {
      required: true,
      dateMustBePast: value => dateInPastValidator(value)
    }
  };

  if (!props.isProspecto && props.prospectToEdit) {
    delete tmp["medioProspeccion"];
    delete tmp["precioMembresia"];
    delete tmp["mantenimiento"];
    delete tmp["precioMantenimiento"];
  }

  return tmp;
};

class PeopleBasicData extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: getCleanFields(),
    errors: {},
    prospectToEdit: null,
    correoValidado: false,
    correoActual: "",
    promptCodigo: "",
    promptMensaje: "",
    promptActivo: false,
    promptResolver: null,
    promptRejecter: null
  };

  validationRules = loadValidationRules(this.props);

  loadFields = () => {
    if (this.props.prospectToEdit) {
      const fields = prospectToFields(this.props.prospectToEdit);
      this.setState({
        fields,
        correoActual: fields.correoElectronico,
        correoValidado: fields.correoValidado
      });
    }
  };

  componentDidMount() {
    this.loadFields();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.fields !== this.state.fields &&
      isFunction(this.props.onChange)
    ) {
      this.props.onChange(this.state.fields);
    }

    if (prevProps.prospectToEdit !== this.props.prospectToEdit) {
      this.loadFields();
    }

    if (
      prevProps.creatingProspect !== this.props.creatingProspect &&
      !this.props.creatingProspect &&
      this.props.validateEmail &&
      this.props.creatingProspectValues
    ) {
      this.props.validateEmail(
        this.props.creatingProspectValues.idCorreoElectronico,
        this.state.fields.correoElectronico
      );
    }
  }

  prompting = msg => {
    return new Promise((resolve, reject) => {
      this.setState({
        promptCodigo: "",
        promptMensaje: msg,
        promptActivo: true,
        promptResolver: resolve,
        promptRejecter: reject
      });
    });
  };

  validarCodigoCorreo = async (): Promise<any> => {
    try {
      const codigo = await this.prompting(
        "Ingrese el código recibido en el correo electrónico"
      );
      if (codigo) {
        const response = await postValidarCodigo(
          this.state.fields.correoElectronico,
          codigo,
          this.state.fields.personaId
        );
        return response.data;
      } else {
        errorNotification("Ingrese el código correctamente");
        return null;
      }
    } catch (err) {
      errorNotification(
        (err &&
          err.response &&
          err.response.data &&
          err.response.data.message) ||
          "Error al validar el correo, intente de nuevo por favor"
      );
      return null;
    }
  };

  onSubmit = e => {
    e.preventDefault();

    // El CURP solo se actualiza
    // TODO: Funcionalidad de candados eliminada; borre este código después de tres meses a partir de Junio de 2023
    if (
      this.props.prospectToEdit &&
      !this.state.fields.curp &&
      !this.state.fields.esExtranjero &&
      !userHasPermission(PROSPECT_EDITION_OMITIR_CURP)
    ) {
      errorNotification("Debe ingresar la CURP si la persona no es extranjera");
      return;
    }

    if (this.re.validateFormFromState()) {
      if (!this.props.prospectToEdit) {
        this.props.createProspect({
          ...this.state.fields,
          asignadoA: this.props.userId
        });
      } else {
        const {
          correoElectronico,
          nombre,
          primerApellido,
          segundoApellido,
          fechaNacimiento,
          medioProspeccion,
          codigoPostal,
          colonia,
          numeroTelefono,
          extension,
          sexo,
          convenioId,
          convenioData,
          clubData,
          securitySpace,
          tipo,
          curp,
          folio,
          precioMembresia,
          mantenimiento,
          precioMantenimiento
        } = this.state.fields;
        this.props.editProspect(
          (this.props.prospectToEdit && this.props.prospectToEdit.personaId) ||
            (this.state.prospectToEdit && this.state.prospectToEdit.personaId),
          {
            correoElectronico,
            nombre,
            primerApellido,
            segundoApellido,
            fechaNacimiento,
            medioProspeccion: !!medioProspeccion ? medioProspeccion : null,
            codigoPostal,
            colonia,
            numeroTelefono,
            extension,
            sexo,
            convenioId,
            convenioData,
            clubData,
            securitySpace,
            tipo,
            curp,
            folio,
            precioMembresia,
            mantenimiento,
            precioMantenimiento
          }
        );
      }
    }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    email: _ => `Correo electrónico inválido`,
    minLength: ruleValue => `Debe tener al menos ${ruleValue} caracteres`,
    maxLength: ruleValue => `Deber tener mínimo ${ruleValue} caracteres`,
    default: _ => `Inválido`,
    dateMustBePast: _ => "La fecha debe estar en el pasado",
    phoneNumberDigits: _ =>
      "Debe ingresar los 10 dígitos del número de teléfono"
  };

  onChangeFactory = (fieldName, toUpperCase?: boolean) => {
    return event => {
      if (fieldName == "esExtranjero") {
        const tmp = event && event.target ? event.target.value : event;
        this.setState(state => {
          const fields = {
            ...state.fields,
            [fieldName]: tmp === "1" || tmp === true
          };

          return { ...state, error: {}, fields };
        });

        return;
      }

      const tmp = event.target.value;
      const value = !!toUpperCase ? (tmp || "").toUpperCase() : tmp;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };

        return { ...state, error: {}, fields };
      });

      // Validaciones customizadas para el Curp
      if (fieldName != "curp" && fieldName != "esExtranjero") {
        try {
          this.re.validateField(fieldName, value);
        } catch (e) {
          console.error(e);
        }
      }
    };
  };

  onChageCurp = (curpData, curp) => {
    if (curpData) {
      this.setState({
        fields: {
          ...this.state.fields,
          nombre: curpData.nombre,
          primerApellido: curpData.apellidoPaterno,
          segundoApellido: curpData.apellidoMaterno,
          fechaNacimiento: curpData.fechaNacimiento
        }
      });
    }

    if (this.props.debouncedGetProspects) {
      this.props.debouncedGetProspects({ curp });
    }
  };

  handleProspectDetail = e => {
    e.preventDefault();
    this.props.history.push(
      `/prospecto/${this.props.prospectToEdit.personaId}/detalle`
    );
  };

  onChangeFechaNacimiento = event => {
    const fechaNacimiento = moment(event).format("YYYY-MM-DD");
    this.setState(state => {
      const fields = {
        ...state.fields,
        ["fechaNacimiento"]: fechaNacimiento
      };
      return { ...state, error: {}, fields };
    });
  };

  onChangeNumber = fieldName => {
    return event => {
      const value = event.target.value.replace(/[^0-9\.]/gi, "");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };
        return { ...state, error: {}, fields };
      });

      try {
        this.re.validateField(fieldName, value);
      } catch (e) {
        console.error(e);
      }
    };
  };

  render() {
    const { prospectToEdit, creatingProspect, viewMode } = this.props;
    return (
      <>
        <div hidden={!this.state.promptActivo} className={s.promptShadow}>
          <div className={s.promptContainer}>
            <div className={s.promptMessage}>{this.state.promptMensaje}</div>
            <div className={s.promptInput}>
              <input
                type="text"
                onChange={e => this.setState({ promptCodigo: e.target.value })}
              />
            </div>
            <div className={s.promptButtons}>
              <button
                type="button"
                onClick={e => {
                  this.state.promptResolver(this.state.promptCodigo);
                  this.setState({ promptActivo: false });
                }}
              >
                Aceptar
              </button>
              <button
                type="button"
                onClick={e => {
                  this.state.promptResolver(null);
                  this.setState({
                    promptCodigo: null,
                    promptActivo: false
                  });
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
        <div className="formGroup">
          <PeopleBasicDataForm
            viewMode={this.props.viewMode}
            fields={this.state.fields}
            onChangeFactory={this.onChangeFactory}
            re={this.re}
            onChangeFechaNacimiento={this.onChangeFechaNacimiento}
            onChangeNumber={this.onChangeNumber}
            creatingProspectError={this.props.creatingProspectError}
            isMoralPersonType={this.props.isMoralPersonType}
            empresasConvenio={this.props.empresasConvenio}
            onChangeCurp={this.onChageCurp}
          />
          <Row>
            {!viewMode && prospectToEdit && (
              <Col xs={12} md={3}>
                <Button
                  className={`buttonMarginTop primaryButton`}
                  onClick={this.handleProspectDetail}
                  size="sm"
                  disabled={this.props.disableDetailsButton || creatingProspect}
                  block
                >
                  Detalle de Persona
                </Button>
              </Col>
            )}
            <Col />

            {!viewMode && (
              <Col xs={12} md={prospectToEdit ? "3" : "12"}>
                <Button
                  className={`buttonMarginTop primaryButton`}
                  onClick={e => this.onSubmit(e)}
                  size="sm"
                  disabled={creatingProspect}
                  block
                >
                  {creatingProspect
                    ? prospectToEdit
                      ? "Editando"
                      : "Creando"
                    : prospectToEdit
                    ? "Actualizar Datos Basicos"
                    : "Crear Persona"}
                </Button>
              </Col>
            )}
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(withStyles(s)(PeopleBasicData));
