import React, { Component } from "react";
import { ReduxConnect, Router } from "../../../../toolkit/decorators/Artifact";
import { cImparticionStore, CImparticionStore } from "./CImparticion.store";
import {
  cImparticionDispatcher,
  CImparticionDispatcher
} from "./CImparticion.dispatcher";
import CImparticionComponent from "../../../components/comisionesDeportivaV2Carrusel/steps/CImparticion/CImparticion";

interface Props extends CImparticionStore, CImparticionDispatcher {}
interface State {}

@Router()
@ReduxConnect(cImparticionStore, cImparticionDispatcher)
export default class CImparticionContainer extends Component<Props, State> {
  render() {
    return <CImparticionComponent {...this.props} />;
  }
}
