import FormBuilderConfig from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IUserSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";

export type Inputs = "razonRechazo";
export type Buttons = "aceptar" | "cancelar";

export const formConfigRechazar: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "razonRechazo",
      label: "* Razon de rechazo",
      bts: {
        mCol: 12
      },
      component: IUserSelectorBuilder
    }
  ],
  buttons: [
    {
      name: "aceptar",
      label: "Aceptar",
      isSubmit: true,
      bts: {
        mCol: 4
      }
    },
    {
      name: "cancelar",
      label: "Cancelar",
      isSubmit: true,
      bts: {
        mCol: 4
      }
    }
  ],
  validations: {
    razonRechazo: {
      required: true
    }
  },
  rows: []
};
