import React, { Component } from "react";
import { ReduxConnect, Router } from "../../../../toolkit/decorators/Artifact";
import { aVentaDispatcher, AVentaDispatcher } from "./AVenta.dispatcher";
import { aVentaStore, AVentaStore } from "./AVenta.store";
import AVentaComponent from "../../../components/comisionesDeportivaV2Carrusel/steps/AVenta/AVenta";

interface Props extends AVentaDispatcher, AVentaStore {}
interface State {}

@Router()
@ReduxConnect(aVentaStore, aVentaDispatcher)
export default class AVentaContainer extends Component<Props, State> {
  render() {
    return (
      <>
        <div className="mt-4">&nbsp;</div>
        <AVentaComponent {...this.props} />
      </>
    );
  }
}
