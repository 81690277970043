import { Club } from "../../../../types-business/Clubs";
import moment from "moment";

export interface USER_BASIC_INFO {
  userId: string;
  name: string;
  firstSurname: string;
  secondSurname: string;
}

export interface Props {
  allowedClubs: Club[];
  selectedClub: any;
  selectClub: any;
  selectedSeller: any;
  selectSeller: any;
  clubSellers: USER_BASIC_INFO[];
}

export interface State {
  changeActivityStatusModalIsOpen: boolean;
  changeActivityStatusModalActivityId: number;
  randomToForceReload: string;
}

export const utcDate = (str: string): Date => {
  const date = new Date((str || "").replace("Z", ""));
  //date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  return date;
};

export const transformEvent = event => {
  console.info(event);
  const res = event
    ? {
        title: event.tipo,
        start: utcDate(event.fechaInicio),
        end: utcDate(event.fechaFin),
        activityId: event.actividadId,
        phone: event.telefono || "N/D",
        persona: event.nombre,
        note: event.nota,
        status: event.status || "",
        createdDate: event.fechaRegistro,
        createdTime: event.horaRegistro,
        createdBy: event.creadoPorUsuarioId,
        createdByName: event.creadoPorUsuarioNombre
      }
    : event;

  return res;
};
