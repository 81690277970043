import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import ReactTable from "react-table";
import { Button, Col, Container, Input, Label, Row, TabPane } from "reactstrap";
import EmployeeSelector from "../../../../Selectors/components/EmployeeSelector/index";
import BaseInput from "../../../../toolkit/baseInput";
import { Club } from "../../../../types-business/Clubs";
import { userHasPermission } from "../../../../utils/accessTree";
import { handleRequestError } from "../../../../utils/helpers";
import { infoNotification } from "../../../../utils/notifications";
import permissionCodes from "../../../../utils/permissionCodes";
import {
  getAnnuitiesByMaintenanceAndClubRequest,
  getAnnuityQuoteRequest,
  saveAnnuityQuoteRequest
} from "../../../actions/annuities";
import MexicanCurrency from "../../common/currency";
import DatePicker from "../../common/DatePicker";
import AnnuityQuoteItem from "./annuityQuoteItem";
import s from "./styles.scss";
import moment from "moment";

const formatTime = (dateTime?) =>
  moment(dateTime)
    .utc()
    .format("YYYY-MM-DD");

interface Props {
  annuitiesAvailableMaintenances: any[]; // Lista de mantenimientos disponibles para anualidades
  currentMembershipPartners: any[]; // Lista de los socios actuales de la MEMBRESIA-SOCIO
  currentMembershipConfiguration: any; //Configuración (entidad) actual de la MEMBRESIA-SOCIO
  users: { userId: number; name: string; firstSurname: string }[]; //Listado de usuarios para responsable
  clubs: Club[];
  history: any;
}

class TabAnnuitiesQuote extends React.Component<Props> {
  state = {
    selectedResponsible: "",
    selectedPartners: [],
    partnersCheckedAll: false,

    selectedMaintenanceId: null,
    annuities: [],
    selectedAnnuityId: null,
    quotes: [],
    currentMonth: false,
    initialQuotationDate: null,

    isBiclub: false,
    clubAlternoId: null
  };

  loadAnnuities = (maintenanceId, clubId) => {
    if (!maintenanceId || !clubId) {
      return;
    }

    getAnnuitiesByMaintenanceAndClubRequest(maintenanceId, clubId)
      .then(({ data }) => {
        this.setState({ annuities: [...data] });
      })
      .catch(handleRequestError);
  };

  loadQuotes = () => {
    const params = {
      anualidadId: this.state.selectedAnnuityId,
      mantenimientoId: this.state.selectedMaintenanceId,
      clubId: this.props.currentMembershipConfiguration.idClubBase,
      clubAlternoId: this.state.clubAlternoId || null,
      membresiaSocioId: this.props.currentMembershipConfiguration
        .idMembresiaSocio,
      personasIds: this.state.selectedPartners.join(","),
      currentMonth: this.state.currentMonth,
      fechaInicialCotizacion: this.state.initialQuotationDate
    };

    if (!!this.state.selectedResponsible) {
      params["responsableId"] = this.state.selectedResponsible;
    }

    infoNotification("Realizando cotización...");

    getAnnuityQuoteRequest(params)
      .then(({ data }) => {
        this.setState({ quotes: [...data] });
      })
      .catch(handleRequestError);
  };

  handleBuyAnnuity = item => {
    item["clubAlternoId"] = this.state.clubAlternoId;

    saveAnnuityQuoteRequest(item)
      .then(({ data }) => {
        const { cajaId } = data;
        if (!!cajaId) {
          this.props.history.push(`/productos/caja/${cajaId}`);
        }
      })
      .catch(handleRequestError);
  };

  componentWillUnmount() {
    let selectedResponsible = "";
    this.setState({
      selectedResponsible,
      selectedPartners: [],
      total: 0,
      optionsMonthsWithoutInterest: [],
      selectedMaintenanceId: null
    });
  }

  onChangeResponsible = selectedResponsible => {
    this.setState({ selectedResponsible });
  };

  changeSelectedMaintenanceId = e => {
    const maintenanceId = e.target.value;

    const maintenanceConfig = this.props.annuitiesAvailableMaintenances.find(
      x => x.datosBasicosMantenimientoId == maintenanceId
    );

    let biclub = false;
    if (!!maintenanceConfig) {
      biclub = !!maintenanceConfig.biclub;
    }

    this.setState({
      selectedMaintenanceId: maintenanceId,
      selectedAnnuityId: "",
      isBiclub: biclub,
      clubAlternoId: null
    });

    //Get annuities
    this.loadAnnuities(
      maintenanceId,
      this.props.currentMembershipConfiguration.idClubBase
    );
  };

  onPartnercheckedChanged = event => {
    const target = event.target;
    const value = target.value;
    const selectedPartners =
      target.checked || false
        ? [...this.state.selectedPartners, parseInt(value)]
        : this.state.selectedPartners.filter(x => x != value);
    this.setState({ selectedPartners });
  };

  isPartnerChecked = (partnerId): boolean => {
    return this.state.selectedPartners.includes(partnerId);
  };

  checkAllPartners = e => {
    this.setState({
      selectedPartners: e.target.checked
        ? (this.props.currentMembershipPartners || []).map(x => x.idPersona)
        : [],
      partnersCheckedAll: e.target.checked
    });
  };

  onChangeDateFactory = fieldName => {
    return dateTime => {
      const value = formatTime(dateTime);
      this.setState({
        ...this.state,
        fields: { ...this.state.fields, [fieldName]: value }
      });
      this.re.validateField(fieldName, value);
    };
  };

  render() {
    return (
      <TabPane className="my-2" tabId="14">
        <Container className="w-100 p-0 my-3">
          <p className="primaryTitle w-100 mt-4">
            Paso 1: Seleccione los integrantes
          </p>
          <ReactTable
            manual
            data={this.props.currentMembershipPartners || []}
            columns={[
              {
                Header: () => (
                  <div className="text-center">
                    <Input
                      id="checkAllPartners"
                      type="checkbox"
                      checked={this.state.partnersCheckedAll}
                      onChange={this.checkAllPartners}
                    />
                  </div>
                ),
                id: 0,
                width: 80,
                Cell: row => {
                  return (
                    <div className="text-center">
                      <Input
                        type="checkbox"
                        value={row.original.idPersona}
                        checked={this.isPartnerChecked(row.original.idPersona)}
                        onChange={this.onPartnercheckedChanged}
                      />
                    </div>
                  );
                }
              },
              {
                Header: "Nombre",
                accessor: "nombrePersona"
              },
              { Header: "Tipo Integrante", accessor: "tipoSocio" },
              { Header: "Estatus", accessor: "estatus" }
            ]}
            className="w-100"
            sortable={false}
            showPagination={false}
            defaultPageSize={
              (this.props.currentMembershipPartners || []).length
            }
          />

          <p className="primaryTitle w-100 mt-4">
            Paso 2: Selecciones el tipo de mantenimiento
          </p>
          <Row>
            {(this.props.annuitiesAvailableMaintenances || []).map(m => {
              return (
                <Col xs="4" key={m.datosBasicosMantenimientoId}>
                  <label className="px-1">
                    <input
                      type="radio"
                      id={`optionMaintenance${m.datosBasicosMantenimientoId}`}
                      value={m.datosBasicosMantenimientoId}
                      onChange={this.changeSelectedMaintenanceId}
                      checked={
                        this.state.selectedMaintenanceId ==
                        m.datosBasicosMantenimientoId
                      }
                    />
                    &nbsp;&nbsp;&nbsp;
                    <b>{m.nombre}</b>
                  </label>
                </Col>
              );
            })}
          </Row>

          {!!this.state.isBiclub && (
            <div>
              <p className="primaryTitle w-100 mt-4">
                Seleccione un club alterno
              </p>

              <div>
                <BaseInput
                  type="select"
                  id={"alternateClubId"}
                  name={"alternateClubId"}
                  label={"Club alterno"}
                  value={this.state.clubAlternoId}
                  size="sm"
                  options={[{ value: "", label: "Seleccione el Club" }].concat(
                    this.props.clubs.map(club => ({
                      value: club.clubId,
                      label: club.nombre
                    }))
                  )}
                  onChange={e => {
                    const value = e.target.value;
                    this.setState({ clubAlternoId: value });
                  }}
                />
              </div>
            </div>
          )}

          <p className="primaryTitle w-100 mt-4">
            Paso 3: Seleccione la promocion de anualidad y el responsable
          </p>
          <Row className="w-100 p-0 m-0">
            <Col>
              <BaseInput
                type="select"
                name="anualidadPromocion"
                id="anualidadPromocion"
                label="Promoción"
                value={this.state.selectedAnnuityId}
                onChange={e => {
                  const annuityId = e.target.value;
                  this.setState({ selectedAnnuityId: annuityId });
                }}
                options={[{ value: "", label: "Seleccionar anualidad" }].concat(
                  (this.state.annuities || []).map(option => ({
                    value: option.id,
                    label: `${option.nombre} (${option.tipo})`
                  }))
                )}
              />
            </Col>
            <Col>
              <EmployeeSelector
                onChange={(_, value) => this.onChangeResponsible(value)}
                label="Responsable de venta"
              />
            </Col>
            <Col className="mt-4 ml-3">
              <Label check>
                <Input
                  type="checkbox"
                  checked={this.state.currentMonth}
                  onChange={e => {
                    const currentMonth = e.target.checked;
                    this.setState({ currentMonth });
                  }}
                />
                Considerar més corriente
              </Label>
            </Col>
            {userHasPermission(
              permissionCodes.PARTNER_MEMBERSHIP_TAB_ANNUITIES_PAST_MONTHS_QUOTATIONS
            ) && (
              <Col className="mt-4 ml-3">
                <DatePicker
                  label="Cotizar con fecha inicial:"
                  name="fechaInicialAnualidad"
                  id="fechaInicialAnualidad"
                  selected={this.state.initialQuotationDate}
                  onChange={dateTime => {
                    const value = formatTime(dateTime);
                    this.setState({ initialQuotationDate: value });
                  }}
                />
              </Col>
            )}
          </Row>

          <div className="text-right mt-4">
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton}`}
              onClick={() => {
                this.loadQuotes();
              }}
              size="sm"
              disabled={
                !(this.state.selectedPartners || []).length ||
                !this.state.selectedMaintenanceId ||
                !this.state.selectedAnnuityId ||
                (!!this.state.isBiclub && !this.state.clubAlternoId) ||
                !this.state.selectedResponsible
              }
            >
              {"Cotizar"}
            </Button>
          </div>
          {!!(this.state.quotes || []).length && (
            <React.Fragment>
              <p className="primaryTitle w-100 mt-4">
                Paso 4: Seleccione la cotización y proceda al pago
              </p>
              <br />
              <Row style={{ fontSize: "12px" }}>
                <Col xs="3">
                  <Container>
                    <p className="text-center">&nbsp;&nbsp;&nbsp;</p>
                    <p className={`${s.primaryTitleNoBg} w-100 text-right`}>
                      Meses a pagar:
                    </p>
                    <p className={`${s.primaryTitleNoBg} w-100 text-right`}>
                      Meses de acceso:
                    </p>
                    <p className={`${s.primaryTitleNoBg} w-100 text-right`}>
                      Mes inicial pago:
                    </p>
                    <p className={`${s.primaryTitleNoBg} w-100 text-right`}>
                      Meses final pago:
                    </p>

                    <p className="w-100 text-right">
                      Cuota mensual cargo automático:
                    </p>
                    <p className="w-100 text-right">Cuota anualidad:</p>
                    <p className="w-100 text-right">Subtotal:</p>
                    <p className="w-100 text-right">Descuento renovación:</p>
                    <p className="w-100 text-right">Total:</p>
                    <p className="text-center">&nbsp;&nbsp;&nbsp;</p>
                  </Container>
                </Col>
                <Col>
                  <Row>
                    {(this.state.quotes || []).map(x => {
                      return (
                        <AnnuityQuoteItem
                          item={x}
                          buy={this.handleBuyAnnuity}
                        />
                      );
                    })}
                  </Row>
                </Col>
              </Row>
              {!!(this.state.quotes || []).length &&
              !!this.state.quotes[0].cargoMantenimientoDowngrade ? (
                <div className="m-5">
                  <span>
                    Nota: Se generará un cargo por cambio de mantenimiento por
                    el monto de{" "}
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    <MexicanCurrency
                      quantity={
                        this.state.quotes[0].cargoMantenimientoDowngrade.monto
                      }
                    />
                  </span>
                </div>
              ) : null}
            </React.Fragment>
          )}
          <br />
          <br />
          <br />
          <br />
        </Container>
      </TabPane>
    );
  }
}
export default withStyles(s)(TabAnnuitiesQuote);
