import React, { useState } from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Input } from "reactstrap";
import s from "./styles.scss";
import { getDefaultMembers } from "../../../Quotations/components/QuotationTable";
import {
  stringify as objectToQueryParams,
  parse as parseQueryParams
} from "query-string";
import PersonSelector from "../../../People/containers/PersonSelector";
import { getEmptyFilters } from "../../../Quotations/utils";
import { Filters, QuotationInterface } from "../../../Quotations/types";
import {
  FIRST_MONTH,
  LAST_MONTH,
  TODAY_NUMBER_MONTH
} from "../../../Quotations/components/QuotationTable";
import { Quotation } from "../../../Quotations/components/Quotation";
import debounce from "lodash.debounce";
import constants from "../../../utils/constants";
import {
  errorNotification,
  infoNotification,
  REFERRAL_CODE_VALIDATION_PENDING
} from "../../../utils/notifications";
import {
  getEdad,
  validatePartnerMembershipReferredCode
} from "../../actions/partners";
import { handleRequestError } from "../../../utils/helpers";

interface Person {
  personaId: number;
  nombre: string;
  primerApellido: string;
  tipo: string;
}

interface MEMBERSHIP_CLIENT {
  idPersona: string;
  idPropietario: string;
  idMembresia: string;
  idClubBase: string;
  idMantenimiento: string;
  idConvenio: string;
  idEmpresa: string;
  idPromocion: string;
  fechaAdquisicion: string;
  idClubAlterno: string;
  precioBaseMembresia: number;
  precioVentaMembresia: number;
  certificado: boolean;
  ventaEnLinea: boolean;
  fechaInicioMantenimiento: string;
  estatusMembresia: string;
  intransferible: boolean;
  nueva: boolean;
  limiteInicioMantenimiento: number;
  idMembresiaPrecio: number;
  formaPagoDescuento: string;
  mesesCotizacion: any[];
  codigoReferido: string;
  codigoReferidoValidacion: any;
}

enum REFERRAL_CODE_STATE {
  VALID,
  INVALID,
  DEFAULT
}

interface Props {
  getQuotations: (personaId: any, args: any, integrantes: any) => void;
  membershipPurchase: (membershipClient: MEMBERSHIP_CLIENT) => any;
  quotations: QuotationInterface;
  clubs: Club[];
  promotions: Promotion[];
  match: any;
  history: any;
  getMembershipById: (id: string) => any;
  membershipById: { integrantes?: number };
  getEmpresasConvenio: () => void;
  empresasConvenio: any[];
  location: any;
  membershipsMaintenance: {
    membresiaId: number;
    bebesExtras: number;
    personasExtras: number;
  }[];
  successfullPurchase: {
    idMembresiaSocio: string;
    idPublico: number;
    idClubBase: number;
  };
  validateReferralCode: (codeObject: object) => any;
  quotationUse: string;
  checkMode: boolean;
  userContext: object;
}

interface Promotion {
  nombre: string;
  id: string;
  isConvenio: boolean;
  finVigencia: string;
}

interface Club {
  nombre: string;
  clubId: string;
}

const stateToQueryParams = (filters, integrantes = []) =>
  objectToQueryParams({
    ...filters,
    numeros: integrantes.map(({ numero }) => numero).join(","),
    tipos: integrantes.map(({ tipo }) => tipo).join(",")
  });

interface PureState {
  filters: Filters;
  integrantes: {
    numero: number;
    tipo: string;
  }[];
}

const getState = (qs: any): PureState => {
  if (!qs) {
    return {
      filters: getEmptyFilters(),
      integrantes: getDefaultMembers()
    };
  }

  const { tipos, numeros, ...filters } = parseQueryParams(qs);

  const integrantes = tipos.split(",").map((tipo: any, index: any) => {
    return { tipo, numero: index + 1 };
  });

  return {
    filters,
    integrantes
  };
};

const search = (f: any, id: any, filters: any, integrantes: any) => {
  f(
    id,
    {
      ...filters,
      mesInicio: FIRST_MONTH,
      mesFin: LAST_MONTH,
      diaInicio: TODAY_NUMBER_MONTH
    },
    integrantes
  );
};

let initialLoad = false;

interface State {
  precioVentaMembresia: number;
  selectedPerson: Person;
  codigoReferido: string;
  codigoReferidoValidacion: any;
  stateReferral: REFERRAL_CODE_STATE;
  selectedMonths: any[];
  edad: number;
}

const Quotations = (props: any) => {
  const setQueryParams = (search: any) => props.history.push({ search });
  let { filters } = props.state;

  if (filters.club && filters.membresia && !props.quotations && !initialLoad) {
    search(
      props.getQuotations,
      props.match.params.personaId,
      { tipoPrecio: 1, clubAlternoId: filters.clubAlterno, ...filters },
      []
    );
  }

  const onChangeFilters = newFilters => {
    if (newFilters.membresia && newFilters.club)
      search(
        props.getQuotations,
        props.match.params.personaId,
        { tipoPrecio: 1, clubAlternoId: newFilters.clubAlterno, ...newFilters },
        []
      );

    setQueryParams(stateToQueryParams(newFilters));
  };

  initialLoad = true;
  return (
    <Quotation
      enableMemberEdition
      onChangeFilters={onChangeFilters}
      quotation={props.quotations}
      disablePromotions={props.disablePromotions}
      disableReferredCode={true}
      quotationUse={props.quotationUse}
      checkMode={props.checkMode}
      updateSelectedMonths={months => {
        if (!!props.updateSelectedMonths) {
          props.updateSelectedMonths([...months]);
        }
      }}
    />
  );
};

class QuotationsContainer extends React.PureComponent<Props, State> {
  state = {
    precioVentaMembresia: 0,
    codigoReferido: "",
    codigoReferidoValidacion: null,
    selectedPerson: null,
    stateReferral: REFERRAL_CODE_STATE.DEFAULT,
    selectedMonths: [],
    edad: 0
  };

  invalidValue = () => {
    let valRet = false;
    if (this.props.quotations && this.state.precioVentaMembresia) {
      let value =
        this.props.quotations.precioMembresia *
        (1 - this.props.quotations.maximoDescuento / 100);
      if (
        this.state.precioVentaMembresia < Math.trunc(value) ||
        this.state.precioVentaMembresia > this.props.quotations.precioMembresia
      )
        valRet = true;
    }
    return valRet;
  };

  componentDidMount(): void {
    getEdad(this.props.match.params.personaId).then((res: any) => {
      if (res.data && res.data.body) {
        this.setState({ edad: res.data.body });
      }
    });
  }

  membershipPurchase = (state: PureState) => {
    let idPropietario = this.props.match.params.personaId;

    if (this.state.selectedPerson !== null) {
      idPropietario = this.state.selectedPerson.personaId;
    }

    const { filters } = state;
    if (!!filters.biclub && filters.biclub == "true" && !filters.clubAlterno) {
      errorNotification("Debe seleccionar un club alterno");
      return;
    }

    const body: MEMBERSHIP_CLIENT = {
      idPersona: this.props.match.params.personaId,
      idPropietario: idPropietario,
      idMembresia: filters.membresia,
      idClubBase: filters.club,
      idMantenimiento: filters.mantenimiento,
      idConvenio: filters.convenio,
      formaPagoDescuento: filters.descuento || "EFECTIVO",
      idEmpresa: filters.empresa,
      idPromocion:
        this.state.stateReferral === REFERRAL_CODE_STATE.VALID
          ? ""
          : filters.promocion,
      fechaAdquisicion: null,
      idClubAlterno: filters.clubAlterno || null,
      precioBaseMembresia: this.props.quotations.precioMembresia,
      precioVentaMembresia: this.state.precioVentaMembresia,
      certificado: false,
      ventaEnLinea: false,
      fechaInicioMantenimiento: null,
      estatusMembresia: "ACTIVA",
      intransferible: Boolean(filters.convenio),
      nueva: true,
      limiteInicioMantenimiento: 1,
      idMembresiaPrecio: this.props.quotations.precioMembresiaId,
      mesesCotizacion: [
        ...this.state.selectedMonths.map(x => {
          x.integrantes.bebes = null;
          x.integrantes.extras = null;
          return x;
        })
      ],
      codigoReferido: this.state.codigoReferido,
      codigoReferidoValidacion: this.state.codigoReferidoValidacion
    };

    if (this.state.stateReferral !== REFERRAL_CODE_STATE.INVALID) {
      this.props.membershipPurchase(body);
    }
  };

  viewProspectDetail = () => {
    const idPersona = this.props.match.params.personaId;
    window.open(`/prospecto/${idPersona}/detalle`, "_blank");
  };

  async componentDidUpdate() {
    if (this.props.successfullPurchase) {
      const {
        idMembresiaSocio,
        idPublico,
        idClubBase
      } = this.props.successfullPurchase;

      this.props.history.push(
        `/socio/venta-exitosa/${idMembresiaSocio}?idPublico=${idPublico}&idClub=${idClubBase}`
      );
    }
  }

  setPerson = async (selectedPerson: Person) => {
    await this.setState({
      selectedPerson
    });
  };

  validateReferredCode = async (codigo: string) => {
    infoNotification(REFERRAL_CODE_VALIDATION_PENDING);
    if (!!codigo) {
      validatePartnerMembershipReferredCode(codigo)
        .then(({ data }) => {
          const isValid = !!(data || {}).valido;
          this.setState({
            codigoReferidoValidacion: isValid ? data : null,
            stateReferral: isValid
              ? REFERRAL_CODE_STATE.VALID
              : REFERRAL_CODE_STATE.INVALID
          });

          if (!isValid) {
            errorNotification(
              (data.errores || ["El código de referido no es válido"]).join(
                "\n"
              )
            );
          }
        })
        .catch(err => {
          this.setState({ stateReferral: REFERRAL_CODE_STATE.INVALID });
          handleRequestError(err);
        });
    } else {
      this.setState({ stateReferral: REFERRAL_CODE_STATE.DEFAULT });
    }
  };

  validateReferredCodeDebounced = debounce(
    this.validateReferredCode,
    constants.DEBOUNCE_INPUT_TIME
  );

  render() {
    const state = getState(this.props.location.search);
    return (
      <Container className="w-100 p-0 my-3">
        <Container className="w-100 p-0 m-0">
          <Row className="w-100 p-0 m-0">
            <Col xs={8} className="float-right pr-0">
              <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                  <span className="input-group-text">Código de Referido</span>
                </div>
                <Input
                  id="codigoReferido"
                  label="Código de Referido"
                  name="codigoReferido"
                  type="text"
                  size="sm"
                  value={this.state.codigoReferido}
                  placeholder="Código de Referido"
                  className={`mr-2 ${
                    this.state.stateReferral == REFERRAL_CODE_STATE.DEFAULT
                      ? ""
                      : this.state.stateReferral == REFERRAL_CODE_STATE.VALID
                      ? "is-valid"
                      : "is-invalid"
                  }`}
                  onChange={e => {
                    const codigoReferido = e.target.value;
                    this.setState({ codigoReferido });
                    this.validateReferredCodeDebounced(codigoReferido);
                  }}
                />
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    Precio Venta Membresia
                  </span>
                </div>
                <Input
                  label={"Precio Venta Membresía"}
                  name={"precioVentaMembresia"}
                  type="number"
                  id={"precioVentaMembresia"}
                  placeholder="Precio de Venta de la Membresía"
                  value={this.state.precioVentaMembresia}
                  className={this.invalidValue() ? "is-invalid" : ""}
                  onChange={e =>
                    this.setState({ precioVentaMembresia: e.target.value })
                  }
                  size="sm"
                  disabled={!this.props.quotations}
                />
                <div className="input-group-append">
                  <span className="input-group-text">$</span>
                </div>
                {this.invalidValue() ? (
                  <span style={{ fontSize: "12px", color: "#d9534f" }}>
                    {
                      "El precio de venta de la membresia no coincide con el maximo de descuento."
                    }
                    <br />
                  </span>
                ) : null}
              </div>
            </Col>
            <Col xs={2} className="float-right pr-0">
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton} float-right`}
                onClick={() => this.membershipPurchase(state)}
                size="sm"
                block
                disabled={
                  this.invalidValue() ||
                  !this.state.precioVentaMembresia ||
                  !this.props.quotations ||
                  !state.filters.mantenimiento ||
                  this.state.stateReferral == REFERRAL_CODE_STATE.INVALID
                }
              >
                {"Vender"}
              </Button>
            </Col>
            <Col xs={2}>
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton} float-right btn-secondary`}
                onClick={this.viewProspectDetail}
                size="sm"
                block
              >
                {"Ver detalle prospecto"}
              </Button>
            </Col>
          </Row>
          {this.state.edad <= 18 ? (
            <Row className={s.customMessage}>
              <Col xs={4}>
                <PersonSelector
                  setPerson={this.setPerson}
                  label="Seleccionar padre o tutor"
                  cKey={"T04HW53JTC"}
                />
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Quotations
            {...this.props}
            state={state}
            disablePromotions={false}
            quotationUse={this.props.quotationUse}
            checkMode={this.props.checkMode}
            updateSelectedMonths={months => {
              this.setState({ selectedMonths: [...months] });
            }}
          />
        </Container>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(QuotationsContainer));
