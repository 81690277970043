import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row
} from "reactstrap";
import debounce from "lodash.debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

import ClubCatalogSelect from "./ClubCatalogSelect";
import PersonSelector from "../../../People/containers/PersonSelector";
import ProductsList from "./ProductsList";
import SearchSportProduct from "./searchSportProduct";
import CouponValidator, { CODE_VALIDITY } from "../couponValidator";
import BaseInput from "../../../toolkit/baseInput";
import MexicanCurrency from "../common/currency";
import SelectFilter from "../common/SelectFilter";
import ModalFrame from "../../../toolkit/modalFrame";
import ConfirmModal from "../common/confirmModal/confirmModal";

import {
  getClubsSportsProduct,
  getCashboxByIdRequest,
  deleteCashboxByIdRequest,
  updateProductAmount,
  productPaymentException,
  updateProductDescription,
  productDisableForceCat,
  updateProductDatePeriod,
  getProductAvailableDatesForProductPeriod,
  descuentoTotal,
  deleteCashboxPaymentsByIdRequest
} from "../../actions/sportsProducts";
import { PEOPLE_TYPES, PARTNER_TYPES } from "../../../types/Prospects";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import CONSTANTS, {
  ID_TIPO_PRODUCTO_TIENDA,
  TIPO_CODIGO_PRODUCTO,
  TIPO_ITEM_PRODUCTO
} from "../../../utils/constants";
import s from "./styles.scss";
import moment from "moment";
import isNil from "lodash.isnil";
import find from "lodash.find";
import { handleRequestError } from "../../../utils/helpers";
import { userHasPermission } from "../../../utils/accessTree";
import permissionCodes from "../../../utils/permissionCodes";
import { Props, State, Person, PRODUCT_STATE } from "./interfaces_const";
import { isNaN, reduce } from "lodash";

// TODO: Patron Redux en revisión de cargos en proceso
import axios from "axios";
import constants from "../../../utils/constants";
import {
  getPartnerMembershipByPartnerId,
  getVentaPermitida
} from "../../actions/partners";
import { getHasLineasPagoPendientes } from "../../actions/paymentLines";
import { infoNotification } from "../../../utils/notifications";
import LineaPagoModal from "./LineaPagoModal";
import { UncontrolledTooltip } from "reactstrap";

const tipoItemRequiereCat = ["MANTENIMIENTO", "MEMBRESIA"];
const tokenizationProductId = 10005069;

const getLinkCaja = cajaId => `/productos/caja/${cajaId}`;

const getEmptyProduct = () => ({
  sportsProductName: null,
  quantity: 1,
  responsible: null,
  discountCode: null,
  subTotal: null,
  club: null,
  clubId: null,
  sportsProductId: null,
  mesesSinIntereses: null,
  priceObject: null,
  itemId: null,
  discountObject: null,
  tipoItem: TIPO_ITEM_PRODUCTO,
  total: 0,
  montoIva: 0,
  montoDescuento: 0
});

export const itemToProduct = ({
  objetoPrecio,
  mesesSinIntereses,
  producto: { id: productoId, nombre },
  unidades,
  responsable,
  codigoDescuento,
  club: { clubId, nombre: nombreClub },
  itemId,
  tipoItem,
  objetoDescuento,
  total,
  subtotal,
  estatus,
  iva,
  montoDescuento,
  montoIva,
  porPagar,
  eliminable,
  formaPagoParaDescuento,
  forzarTokenizacion,
  eventos,
  esClienteExtranjero,
  tieneFormatoMigracion,
  tieneMedioPagoRegistrado,
  requiereCatForzoso,
  inhabilitadoParaVenta,
  tienePrecioVenta,
  esCuotaVacacional,
  permiteExcepcionPago
}) => {
  return {
    itemId,
    subtotal,
    sportsProductName: nombre,
    quantity: unidades,
    responsible: responsable
      ? userToOption(responsable)
      : { label: "", value: "" },
    discountCode: codigoDescuento || "",
    club: nombreClub,
    sportsProductId: productoId,
    priceObject: objetoPrecio ? objetoPrecio : null,
    mesesSinIntereses: mesesSinIntereses || 0,
    clubId,
    tipoItem,
    total,
    discountObject: objetoDescuento ? objetoDescuento : null,
    estatus,
    iva,
    montoDescuento,
    montoIva,
    porPagar,
    eliminable,
    formaPagoParaDescuento,
    forzarTokenizacion,
    eventos: eventos || 0,
    esClienteExtranjero,
    tieneFormatoMigracion,
    tieneMedioPagoRegistrado,
    requiereCatForzoso,
    inhabilitadoParaVenta,
    tienePrecioVenta,
    esCuotaVacacional,
    permiteExcepcionPago
  };
};

const userToOption = ({ userId, name, firstSurname, secondSurname }) => ({
  label: `${name} ${firstSurname} ${secondSurname}`,
  value: userId
});

class SportsProductsNewQuote extends React.Component<Props, State> {
  state = {
    selectedPerson: null,
    sportProduct: {
      filters: {
        clasificacionId: "",
        nombre: "",
        estatus: "ACTIVO",
        carrito: true
      },
      selectedOnCombo: null,
      data: null
    },
    clubSelected: null,
    productList: [],
    mesesSinIntereses: 0,

    // TODO: Revisar compatibilidad
    product: getEmptyProduct() as any,
    responsible: null,
    clubs: null,
    objetoDescuento: null,
    idTipoProducto: -1,
    canAddProduct: false,
    checkboxes: null,
    personByID: null,
    lastSelectedClassificationId: -1,
    lastSearchedClassificationId: -1,
    resetCodeValidator: false,
    indexDeleteProduct: null,
    freezeObject: {},
    tipoPrecio: "1",
    cashboxToDeleteData: null,
    modalDeleteCashboxOpen: false,
    addingProduct: false,

    indexItemToDelete: null,
    deleteItemConfirmModalIsOpen: false,

    indexItemToUpdateAmount: null,
    updateAmountItemConfirmModalIsOpen: false,

    indexItemToPaymentException: null,
    paymentExceptionItemConfirmModalIsOpen: false,

    indexItemToUpdateDescription: null,
    currentValueItemToUpdateDescription: null,
    updateDescriptionItemConfirmModalIsOpen: false,

    indexItemToUpdateDatePeriod: null,
    currentAvailableDatesForDatePeriodUpdate: [],
    updateDatePeriodItemConfirmModalIsOpen: false,

    indexItemToDisableForceCat: null,
    disableForceCatItemConfirmModalIsOpen: false,

    generateCashboxWithPaymentLineConfirmModalIsOpen: false,
    generateCashboxWithPaymentLineAmount: 0,
    generateCashboxWithPaymentLineHasMembOrTokenization: false,
    generateCashboxWithPaymentLineHasMaintenances: false,

    hasProcessing: false,

    membresiaSocioUrl: "",
    membresiaSocioClubId: "",
    membresiaSocioPublicoId: "",
    membresiaSocioEstatus: "",
    membresiaSocioExiste: false,

    esClienteExtranjero: false,
    tieneFormatoMigracion: false,
    membresiaSocioId: null,
    tieneMedioPagoRegistrado: false,
    tieneCargosConCatForzoso: false,
    clientType: null,
    esCuotaVacacional: false,
    permiteExcepcionPago: false,

    // Para códigos de descuento totales
    aplicaTotal: false,
    total: null,

    loading: false,

    ventaPermitida: true,
    ventaPermitidaMensajes: []
  };

  person: Person = null;

  loadPersonByID = () => {
    const { personaId } = this.props.match.params;
    if (!isNil(personaId)) {
      this.props.getpersonByID(personaId).then(({ value: { data } }) => {
        this.setPerson(data);
      });

      getVentaPermitida(personaId)
        .then(({ data }) => {
          this.setState({
            ventaPermitida: data.ventaPermitida,
            ventaPermitidaMensajes: data.mensajes || []
          });
        })
        .catch(() => {
          this.setState({
            ventaPermitida: false,
            ventaPermitidaMensajes: [
              "No se pudo comprobar si la venta está permitida"
            ]
          });
        });

      getPartnerMembershipByPartnerId(personaId)
        .then(({ data }) => {
          this.setState({
            membresiaSocioClubId: data.idClubBase,
            membresiaSocioPublicoId: data.idPublico,
            membresiaSocioEstatus: data.estatusMembresia,
            membresiaSocioExiste: true,
            membresiaSocioUrl:
              data && data.idPublico
                ? `/membresias-socios?idClub=${data.idClubBase}&idPublico=${data.idPublico}`
                : ""
          });
        })
        .catch(err => {
          this.setState({
            membresiaSocioUrl: "",
            membresiaSocioClubId: "",
            membresiaSocioPublicoId: "",
            membresiaSocioEstatus: "",
            membresiaSocioExiste: false
          });
        });
    }
  };

  productIsValid = () => {
    return (
      this.state &&
      this.state.sportProduct &&
      this.state.sportProduct.data &&
      this.state.sportProduct.data.id &&
      !!(this.props.prices || []).length
    );
  };

  setFreezeObject = data => {
    this.setState({ freezeObject: data });
  };

  componentDidMount() {
    this.loadPersonByID();
  }

  componentWillUnmount() {
    this.setState({ tipoPrecio: "1" });
  }

  componentDidUpdate({
    match: {
      params: { personaId: prevPersonaId }
    }
  }) {
    if (!isNil(this.state.indexDeleteProduct)) {
      if (
        this.props.deletingProduct == false &&
        isNil(this.props.deletingProductError)
      ) {
        let valDelete = this.state.productList[this.state.indexDeleteProduct]
          .itemId;
        let copyFreezeObject = Object.keys(this.state.freezeObject).reduce(
          (object, key) => {
            if (key != valDelete) {
              object[key] = this.state.freezeObject[key];
            }
            return object;
          },
          {}
        );
        this.state.productList.splice(this.state.indexDeleteProduct, 1);
        const productList = [...this.state.productList];
        this.setState({
          productList,
          indexDeleteProduct: null,
          freezeObject: copyFreezeObject
        });
      }
    }
    const {
      match: {
        params: { personaId }
      }
    } = this.props;
    if (personaId != prevPersonaId) {
      this.loadPersonByID();
    }
  }

  setChecked = (productId, checkedValue) => {
    const product = find(this.state.productList, p => p.itemId == productId);
    product.porPagar = checkedValue;
  };

  loadProductList = () => {
    const { personaId } = this.state.selectedPerson;

    this.setState({
      loading: true
    });

    this.props
      .getQuoteByPersonId(personaId, {})
      .then(({ value: { data } }) => {
        const {
          productos,
          esClienteExtranjero,
          membresiaSocioId,
          tieneFormatoMigracion,
          tieneMedioPagoRegistrado,
          cliente
        } = data;
        let productList = [...(productos || [])];

        productList.forEach(item => {
          item["esClienteExtranjero"] = esClienteExtranjero;
          item["tieneFormatoMigracion"] = tieneFormatoMigracion;
          item["tieneMedioPagoRegistrado"] = tieneMedioPagoRegistrado;
        });
        productList = productList.map(itemToProduct);

        const tieneCargosConCatForzoso = (productList || []).some(
          x => !!x.requiereCatForzoso
        );

        this.setState({
          productList,
          esClienteExtranjero: !!esClienteExtranjero,
          membresiaSocioId: membresiaSocioId || null,
          tieneFormatoMigracion: !!tieneFormatoMigracion,
          tieneMedioPagoRegistrado: !!tieneMedioPagoRegistrado,
          tieneCargosConCatForzoso,
          clientType: cliente.tipo,
          loading: false
        });
      })
      .catch(() => this.setState({ loading: false }));

    this.props
      .getCashboxPersonId(personaId)
      .then(({ value: { data: checkboxes } }) => {
        this.setState({ checkboxes });
      });
  };

  onSelectClub = clubSelected => {
    this.setState({ clubSelected });
    if (clubSelected.value > 0 && !this.state.canAddProduct)
      this.setState({ canAddProduct: true });
  };

  getClubsSportsProduct = id => {
    return getClubsSportsProduct(id).then(({ data }) =>
      this.setState({ clubs: data })
    );
  };

  setPerson = (selectedPerson: Person) => {
    if (!isNil(selectedPerson)) {
      this.props.setSelectedPerson(selectedPerson);
      this.setState(
        {
          selectedPerson
        },
        () => this.loadProductList()
      );

      this.showProcessingAlert(selectedPerson.personaId).then();
      getHasLineasPagoPendientes(selectedPerson.personaId)
        .then(resp =>
          this.setState({ hasLineasPagoPendientes: resp.data.body })
        )
        .catch(_ => this.setState({ hasLineasPagoPendientes: false }));
    }
  };

  onChangeSportProduct = selectedOnCombo => {
    const { value: id } = selectedOnCombo;

    const data = this.props.sportsProducts.find(
      sportProduct => sportProduct.id === id
    );

    const sportProduct = {
      ...this.state.sportProduct,
      selectedOnCombo,
      data
    };

    this.setState({
      sportProduct,
      tipoPrecio: "1",
      clubSelected: { value: 0, label: "Seleccione el Club" }
    });

    this.props.getPricesToSportsProducts(id, "1");
    this.getClubsSportsProduct(id);
  };

  onChangePriceType = event => {
    this.setState({
      tipoPrecio: event.target.value,
      clubSelected: { value: 0, label: "Seleccione el Club" }
    });

    this.props.getPricesToSportsProducts(
      this.state.sportProduct.data.id,
      event.target.value
    );
  };

  onValidateDiscountCode = (objetoDescuento: CODE_VALIDITY) => {
    this.setState({ objetoDescuento });
  };

  onChangeProductClassification = (classificationId, parents, level) => {
    const idTipoProducto = parents[parents.length - 1];
    const lastSelectedClassificationId = classificationId;
    const filters = { ...this.state.sportProduct.filters };
    filters["clasificacionId"] = classificationId || null;
    this.setState(state => ({
      ...state,
      idTipoProducto,
      sportProduct: {
        ...this.state.sportProduct,
        filters
      },
      lastSelectedClassificationId
    }));
  };

  handleInputChange = nombre => {
    this.debouncedSportsProducts({
      ...this.state.sportProduct.filters,
      nombre
    });
  };

  debouncedSportsProducts = debounce(
    this.props.searchSportsProducts,
    CONSTANTS.DEBOUNCE_INPUT_TIME
  );

  onChangeFactory = key => {
    return event => {
      event.preventDefault();
      const value = event.target.value;
      if (key === "quantity" && parseInt(value) < 1) {
        return;
      }
      const product = { ...this.state.product };
      product[key] = value;
      this.setState({ product });
    };
  };

  onChangeResponsible = responsible => {
    this.setState({ responsible });
  };

  getPriceObject = () => {
    if (
      this.props.prices &&
      this.state.clubSelected &&
      this.state.clubSelected.label
    ) {
      const precioObject = this.props.prices.find(price =>
        price.clubes.includes(this.state.clubSelected.label)
      );
      return precioObject;
    } else {
      return null;
    }
  };

  findPrice = () => {
    const priceObject = this.getPriceObject();
    const { selectedPerson, clientType } = this.state;
    if (selectedPerson && clientType && priceObject) {
      if (clientType == PEOPLE_TYPES.EMPLEADO) {
        return priceObject.precioEmpleado;
      } else if (
        clientType == PEOPLE_TYPES.CLIENTE ||
        clientType == PEOPLE_TYPES.CLIENTE_TITULAR ||
        clientType == PEOPLE_TYPES.CLIENTE_INTEGRANTE
      ) {
        return priceObject.precioSocio;
      } else {
        return priceObject.precioPublico;
      }
    }

    if (
      priceObject &&
      !selectedPerson &&
      this.state.idTipoProducto == ID_TIPO_PRODUCTO_TIENDA
    ) {
      return priceObject.precioPublico;
    }

    return 0;
  };

  deleteProduct = index => {
    this.setState({
      indexItemToDelete: index,
      deleteItemConfirmModalIsOpen: true
    });
  };
  updateProductAmout = index => {
    this.setState({
      indexItemToUpdateAmount: index,
      updateAmountItemConfirmModalIsOpen: true
    });
  };

  productPaymentException = index => {
    this.setState({
      indexItemToPaymentException: index,
      paymentExceptionItemConfirmModalIsOpen: true
    });
  };

  updateProductDescription = (index, current) => {
    this.setState({
      indexItemToUpdateDescription: index,
      currentValueItemToUpdateDescription: current,
      updateDescriptionItemConfirmModalIsOpen: true
    });
  };

  updateProductDatePeriod = index => {
    const itemId = this.state.productList[index].itemId;

    getProductAvailableDatesForProductPeriod(itemId)
      .then(({ data }) => {
        this.setState({
          indexItemToUpdateDatePeriod: index,
          currentAvailableDatesForDatePeriodUpdate: [...data],
          updateDatePeriodItemConfirmModalIsOpen: true
        });
      })
      .catch(handleRequestError);
  };

  productDisableForceCat = index => {
    this.setState({
      indexItemToDisableForceCat: index,
      disableForceCatItemConfirmModalIsOpen: true
    });
  };

  productStateToProduct = (p: PRODUCT_STATE) => ({
    itemId: p.itemId ? p.itemId : null,
    productoId: p.sportsProductId,
    responsableId: p.responsible && p.responsible.value,
    codigoDescuento: p.discountObject ? p.discountObject.codigoDescuento : null,
    unidades: p.quantity,
    precioId: p.priceObject ? p.priceObject.id : null,
    clubId: p.clubId,
    mesesSinIntereses: p.mesesSinIntereses,
    porPagar: p.porPagar
  });

  saveProducts = (
    productos,
    f,
    withPaymentLine: boolean = false,
    paymentLineConfig: any = false
  ) => {
    let clienteId = -1;
    let tipoCliente = PEOPLE_TYPES.EXTERNO;

    if (this.state.selectedPerson) {
      const tipo = this.state.clientType;
      clienteId = this.state.selectedPerson.personaId;
      tipoCliente =
        tipo == PEOPLE_TYPES.CLIENTE ||
        tipo == PEOPLE_TYPES.CLIENTE_TITULAR ||
        tipo == PEOPLE_TYPES.CLIENTE_INTEGRANTE
          ? PARTNER_TYPES.SOCIO
          : tipo == PEOPLE_TYPES.EXTERNO || tipo == PEOPLE_TYPES.EMPLEADO
          ? tipo
          : PEOPLE_TYPES.EXTERNO;
    }

    this.setState({
      loading: true
    });

    this.props
      .saveSPQuote({
        productos,
        clienteId,
        tipoCliente,
        lineaDePago: withPaymentLine,
        lineaPagoTokenizar:
          withPaymentLine && !!paymentLineConfig
            ? paymentLineConfig.lineaPagoTokenizar
            : false,
        lineaDePagoTotal:
          withPaymentLine && !!paymentLineConfig
            ? paymentLineConfig.lineaDePagoTotal
            : null,
        lineaPagoMesesSinIntereses:
          withPaymentLine && !!paymentLineConfig
            ? paymentLineConfig.lineaPagoMesesSinIntereses
            : 0
      })
      .then(({ value: { data: { cajaId, error, message } } }) => {
        this.setState({
          loading: false
        });

        if (error) {
          errorNotification(message);
        } else {
          f(cajaId);
        }
      });
  };

  addProductToList = async () => {
    const { productList } = this.state;
    const {
      sportProduct: { data },
      mesesSinIntereses,
      responsible,
      clubSelected,
      objetoDescuento,
      product: { quantity: unidades }
    } = this.state;
    const priceObject = this.getPriceObject();
    const salePrice = this.findPrice();
    const productoId = data && data.id;
    const product = {
      unidades,
      productoId,
      mesesSinIntereses,
      porPagar: false,
      tipoItem: TIPO_ITEM_PRODUCTO,
      itemId: null,
      responsableId: responsible && responsible.value,
      precioId: priceObject ? priceObject.id : null,
      precioVenta: salePrice,
      clubId: clubSelected && clubSelected.value,
      codigoDescuento: objetoDescuento ? objetoDescuento.codigoDescuento : null
    };

    if (!!this.state.addingProduct) {
      return;
    }

    if (
      objetoDescuento &&
      objetoDescuento.tipoDescuento == "PORCENTAJE" &&
      objetoDescuento.vigente == true &&
      (objetoDescuento.descuento == 100 || objetoDescuento.descuento == 0)
    ) {
      this.setState({
        aplicaTotal: true,
        total: {
          idClub: product.clubId,
          idProducto: product.productoId,
          idCodigo: objetoDescuento.id,
          codigoDescuento: objetoDescuento.codigoDescuento,
          idPersona: this.state.selectedPerson.personaId
        }
      });

      return;
    }

    await this.setState({ addingProduct: true });

    const productos = productList.map(this.productStateToProduct);
    productos.push(product);
    productos.forEach(p => {
      p.porPagar = false;
    });

    this.saveProducts(productos, () => {
      this.loadProductList();
      this.setState({
        objetoDescuento: null,
        addingProduct: false
      });
    });
  };

  onClickSave = () => {
    /* const porPagar = this.state.productList.filter(x => !!x.porPagar);

    if (!porPagar.length) {
      errorNotification("No ha seleccionado ningún producto para pago en caja");
      return;
    }

    //Se valida que no se hayan seleccionado items con candado de cat forzoso y no tenga un medio de pago
    const requirenCat = porPagar.filter(x => {
      return tipoItemRequiereCat.includes(x.tipoItem) && !!x.requiereCatForzoso;
    });

    if (!!requirenCat.length && !this.state.tieneMedioPagoRegistrado) {
      errorNotification(
        "Ha seleccionado cargos que requieren cat forzoso pero el cliente no tiene un medio de pago registrado"
      );
      return;
    } */

    const tieneCargosMembresia =
      this.state.productList.filter(x => x.tipoItem == "MEMBRESIA").length > 0;
    if (tieneCargosMembresia) {
      const cargoMembresiaPorPagar = this.state.productList.filter(
        x => x.tipoItem == "MEMBRESIA" && !!x.porPagar
      );
      if (cargoMembresiaPorPagar.length <= 0) {
        errorNotification(
          "Debe seleccionar el cargo de venta/reactivación de membresía socio"
        );
        return;
      }
    }

    const cargosSinAnualidad = this.state.productList.filter(
      x => x.tipoItem != "ANUALIDAD"
    );
    const porPagar = this.state.productList.filter(x => !!x.porPagar);

    if (!!tieneCargosMembresia && porPagar.length < cargosSinAnualidad.length) {
      errorNotification(
        "Está en un proceso de venta/reactivacion.  Debe seleccionar todos los productos del carrito. (Los cargos de anualidad no son obligatorios)"
      );
      return;
    }

    const productos = porPagar.map(this.productStateToProduct);

    this.saveProducts(productos, cajaId => {
      if (!isNil(cajaId)) {
        this.props.history.push(getLinkCaja(cajaId));
      }
    });
  };

  onClickSaveWithPaymentLine = () => {
    const productlist = [...this.state.productList];

    const porPagar = productlist.filter(
      x => !x.inhabilitadoParaVenta && !!x.porPagar
    );

    if (porPagar.length <= 0) {
      errorNotification(
        "No hay productos seleccionables para generar la línea de pago "
      );
      return;
    }

    const aPagar = reduce(porPagar, (acc, { total }) => acc + total, 0).toFixed(
      2
    );

    if (aPagar <= 0) {
      errorNotification(
        "El monto para generar la línea de pago no puede ser 0"
      );
      return;
    }

    const hasMnts = porPagar.some(x => x.tipoItem == "MANTENIMIENTO");
    const hasMembOrTokenization = porPagar.some(
      x =>
        x.tipoItem == "MEMBRESIA" ||
        (x.tipoItem == "PRODUCTO" && x.sportsProductId == tokenizationProductId)
    );

    this.setState({
      generateCashboxWithPaymentLineConfirmModalIsOpen: true,
      generateCashboxWithPaymentLineAmount: aPagar,
      generateCashboxWithPaymentLineHasMembOrTokenization: hasMembOrTokenization,
      generateCashboxWithPaymentLineHasMaintenances: hasMnts
    });
  };

  saveWithPaymentLine = (tokenizar: boolean, msi: number) => {
    const productlist = [...this.state.productList];

    const porPagar = productlist.filter(
      x => !x.inhabilitadoParaVenta && !!x.porPagar
    );

    if (porPagar.length <= 0) {
      errorNotification(
        "No hay productos seleccionables para generar la línea de pago "
      );
      return;
    }

    const aPagar = reduce(porPagar, (acc, { total }) => acc + total, 0).toFixed(
      2
    );

    infoNotification("Generando línea de pago");

    const productos = porPagar.map(this.productStateToProduct);

    this.saveProducts(
      productos,
      cajaId => {
        successNotification("Línea de pago generada correctamente.");
        this.loadProductList();
      },
      true,
      {
        lineaDePagoTotal: aPagar,
        lineaPagoTokenizar: tokenizar,
        lineaPagoMesesSinIntereses: msi
      }
    );
  };

  canAddProduct = () => {
    return (
      !this.state.addingProduct &&
      this.state.sportProduct.data &&
      this.state.sportProduct.data.id &&
      ((this.state.selectedPerson && this.state.selectedPerson.personaId) ||
        this.state.idTipoProducto == ID_TIPO_PRODUCTO_TIENDA) &&
      this.state.clubSelected &&
      this.state.clubSelected.value &&
      !this.state.loading
    );
  };

  handleDropdownOpened = () => {
    if (
      this.state.lastSearchedClassificationId !=
      this.state.lastSelectedClassificationId
    ) {
      const filters = { ...this.state.sportProduct.filters };
      this.debouncedSportsProducts(filters);

      this.setState({
        lastSearchedClassificationId: this.state.lastSelectedClassificationId
      });
    }
  };

  loadCashboxToDelete = id => {
    getCashboxByIdRequest(id)
      .then(({ data }) => {
        this.setState({
          modalDeleteCashboxOpen: true,
          cashboxToDeleteData: data
        });
      })
      .catch(handleRequestError);
  };

  deleteCashbox = () => {
    infoNotification("Eliminando caja...");
    deleteCashboxByIdRequest(this.state.cashboxToDeleteData.id)
      .then(() => {
        this.loadProductList();
        successNotification("Caja eliminada correctamente.");
        this.setState({
          modalDeleteCashboxOpen: false,
          cashboxToDeleteData: null
        });
      })
      .catch(handleRequestError);
  };

  deleteCashboxPayments = (deleteCashbox: boolean) => {
    infoNotification(`Eliminando ${deleteCashbox ? "caja" : "pagos"}...`);
    deleteCashboxPaymentsByIdRequest(
      this.state.cashboxToDeleteData.id,
      deleteCashbox
    )
      .then(() => {
        this.loadProductList();
        successNotification(
          `${
            deleteCashbox ? "Caja eliminada" : "Pagos eliminados"
          } correctamente.`
        );
        this.setState({
          modalDeleteCashboxOpen: false,
          cashboxToDeleteData: null
        });
      })
      .catch(handleRequestError);
  };

  toggleDeleteCashModal = () => {
    this.setState({
      modalDeleteCashboxOpen: !this.state.modalDeleteCashboxOpen
    });
  };

  async showProcessingAlert(idCliente) {
    try {
      await axios.get(constants.BASE_URL + "/api/partners/has-processing", {
        withCredentials: true,
        params: {
          idCliente
        }
      });

      this.setState({
        hasProcessing: true
      });
    } catch (error) {
      this.setState({
        hasProcessing: false
      });
    }
  }

  render() {
    const {
      esClienteExtranjero,
      tieneFormatoMigracion,
      membresiaSocioId,
      tieneMedioPagoRegistrado,
      tieneCargosConCatForzoso
    } = this.state;

    const abierta =
      (this.state.cashboxToDeleteData &&
        this.state.cashboxToDeleteData.abierta) ||
      {};

    return (
      <Container className="w-100 p-0 my-3">
        <ModalFrame
          title={`Eliminar caja`}
          isOpen={this.state.modalDeleteCashboxOpen}
          toggle={this.toggleDeleteCashModal}
        >
          <div
            className="mt-2 mb-4 alert alert-warning"
            hidden={!abierta.tienePagos || abierta.tieneFactura}
          >
            <p>
              Esta caja tiene pagos registrados que no se pudieron facturar.
            </p>
            <p hidden={!abierta.tienePago}>
              Monto pagado: {abierta.monto} <br />
              Referencia: {abierta.referencia} <br />
              Autorización: {abierta.autorizacion}
            </p>
          </div>
          <div
            className="mt-2 mb-4 alert alert-danger"
            hidden={!abierta.tienePagos || !abierta.tieneFactura}
          >
            <p>
              Esta caja ya fue facturada ({abierta.folioFactura}), contacte a
              Mesa de Ayuda.
            </p>
          </div>
          <div className="mt-2 mb-4">
            <p>
              Se eliminará la caja seleccionada con los siguientes productos:
            </p>
          </div>
          <div>
            {!!this.state.cashboxToDeleteData &&
              this.state.cashboxToDeleteData.productos.map((pd: any) => {
                const { producto, total } = pd;
                return (
                  <div>
                    Nombre: <b>{producto.nombre}</b> / Precio: <b>{total}</b>
                  </div>
                );
              })}
          </div>
          <div className="mt-4 mb-2">
            <p>¿Desea continuar?</p>
          </div>

          <div className={`text-right mt-4`}>
            <Button
              color="primary"
              onClick={this.deleteCashbox}
              style={{ marginRight: "10px" }}
              hidden={abierta.tienePagos}
            >
              Eliminar
            </Button>
            <Button
              color="primary"
              onClick={() => this.deleteCashboxPayments(false)}
              style={{ marginRight: "10px" }}
              hidden={!abierta.tienePagos || abierta.tieneFactura}
            >
              Eliminar solo pago
            </Button>
            <Button
              color="primary"
              onClick={() => this.deleteCashboxPayments(true)}
              style={{ marginRight: "10px" }}
              hidden={!abierta.tienePagos || abierta.tieneFactura}
            >
              Eliminar pago y caja
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                this.setState({
                  modalDeleteCashboxOpen: false,
                  cashboxToDeleteData: null
                });
              }}
            >
              Cerrar
            </Button>
          </div>
        </ModalFrame>

        <ConfirmModal
          title="Eliminar item de carrito"
          message="¿Desea eiminar el item seleccionado?"
          isOpen={this.state.deleteItemConfirmModalIsOpen}
          ok={() => {
            const { indexItemToDelete } = this.state;

            this.setState({
              indexDeleteProduct: indexItemToDelete,
              indexItemToDelete: null,
              deleteItemConfirmModalIsOpen: false
            });
            this.props.deleteProducts(
              this.state.productList[indexItemToDelete].itemId
            );
          }}
          cancel={() => {
            this.setState({
              indexItemToDelete: null,
              deleteItemConfirmModalIsOpen: false
            });
          }}
        />

        <ConfirmModal
          title="Actualizar monto de item de carrito"
          message="Se actualizará el monto del item seleccionado. 
          En caso de presentar códigos de descuento o descuentos aplicados, estos se removeran. 
          ¿Desea continuar?"
          isOpen={this.state.updateAmountItemConfirmModalIsOpen}
          requireInput={true}
          inputType="numeric"
          ok={monto => {
            if (!monto || isNaN(monto)) {
              errorNotification("Debe ingresar un monto válido");
              return;
            }

            const { indexItemToUpdateAmount } = this.state;
            const itemId = this.state.productList[indexItemToUpdateAmount]
              .itemId;

            updateProductAmount(itemId, monto)
              .then(() => {
                this.setState({
                  indexItemToUpdateAmount: null,
                  updateAmountItemConfirmModalIsOpen: false
                });
                successNotification(
                  "Se actualizó el monto correctamente. Cargando datos de carrito."
                );
                this.loadProductList();
              })
              .catch(handleRequestError);
          }}
          cancel={() => {
            this.setState({
              indexItemToUpdateAmount: null,
              updateAmountItemConfirmModalIsOpen: false
            });
          }}
        />

        <ConfirmModal
          title="Excepción de pago para item de carrito"
          message="Se cambiará el estatus del item seleccionado y se aplicarán las modificaciones requeridas para el item 
          ¿Desea continuar?"
          isOpen={this.state.paymentExceptionItemConfirmModalIsOpen}
          ok={() => {
            const { indexItemToPaymentException } = this.state;
            const itemId = this.state.productList[indexItemToPaymentException]
              .itemId;

            productPaymentException(itemId)
              .then(() => {
                this.setState({
                  indexItemToPaymentException: null,
                  paymentExceptionItemConfirmModalIsOpen: false
                });
                successNotification(
                  "Se actualizó el estatus correctamente. Cargando datos de carrito."
                );
                this.loadProductList();
              })
              .catch(handleRequestError);
          }}
          cancel={() => {
            this.setState({
              indexItemToPaymentException: null,
              paymentExceptionItemConfirmModalIsOpen: false
            });
          }}
        />

        <ConfirmModal
          title="Actualizar descripción de item de carrito"
          message="Se actualizará la descripción del item seleccionado. ¿Desea continuar?"
          isOpen={this.state.updateDescriptionItemConfirmModalIsOpen}
          currentValue={this.state.currentValueItemToUpdateDescription}
          requireInput={true}
          inputType="alphanumeric"
          size="lg"
          ok={description => {
            if (!description) {
              errorNotification("Debe ingresar una descripción válida");
              return;
            }

            const { indexItemToUpdateDescription } = this.state;
            const itemId = this.state.productList[indexItemToUpdateDescription]
              .itemId;

            updateProductDescription(itemId, description)
              .then(() => {
                this.setState({
                  indexItemToUpdateDescription: null,
                  currentValueItemToUpdateDescription: null,
                  updateDescriptionItemConfirmModalIsOpen: false
                });
                successNotification(
                  "Se actualizó la descripción correctamente. Cargando datos de carrito."
                );
                this.loadProductList();
              })
              .catch(handleRequestError);
          }}
          cancel={() => {
            this.setState({
              indexItemToUpdateDescription: null,
              currentValueItemToUpdateDescription: null,
              updateDescriptionItemConfirmModalIsOpen: false
            });
          }}
        />

        <ConfirmModal
          title="Recorrer vigencia del item de carrito"
          message="Se recorrerán las vigencias del item de carrito de acuerdo a la fecha seleccionada ¿Desea continuar?"
          isOpen={this.state.updateDatePeriodItemConfirmModalIsOpen}
          requireInput={true}
          inputType="select"
          options={(
            this.state.currentAvailableDatesForDatePeriodUpdate || []
          ).map(x => {
            return { label: x, value: x };
          })}
          ok={selectedDate => {
            if (!selectedDate) {
              errorNotification("Debe seleccionar una fecha");
              return;
            }

            const { indexItemToUpdateDatePeriod } = this.state;
            const itemId = this.state.productList[indexItemToUpdateDatePeriod]
              .itemId;

            updateProductDatePeriod(itemId, selectedDate)
              .then(() => {
                this.setState({
                  indexItemToUpdateDatePeriod: null,
                  currentAvailableDatesForDatePeriodUpdate: [],
                  updateDatePeriodItemConfirmModalIsOpen: false
                });
                successNotification(
                  "Se actualizó la vigencia correctamente. Cargando datos de carrito."
                );
                this.loadProductList();
              })
              .catch(handleRequestError);
          }}
          cancel={() => {
            this.setState({
              indexItemToUpdateDatePeriod: null,
              currentAvailableDatesForDatePeriodUpdate: [],
              updateDatePeriodItemConfirmModalIsOpen: false
            });
          }}
        />

        <ConfirmModal
          title="Deshabilitar requerimiento de CAT forzoso para item de carrito"
          message="Se desactivará el requerimiento de CAT forzoso item seleccionado, ¿Desea continuar?"
          isOpen={this.state.disableForceCatItemConfirmModalIsOpen}
          size="md"
          requireInput={true}
          inputType="select"
          options={[
            { label: "Seleccione una opción", value: "" },
            {
              label: "No funciona la terminal fija",
              value: "No funciona la terminal fija"
            },
            {
              label: "El cliente no trae la TDC que va a domiciliar",
              value: "El cliente no trae la TDC que va a domiciliar"
            },
            { label: "Pago anualidad", value: "Pago anualidad" },
            {
              label: "Se cobró pendiente por facturar (en activación)",
              value: "Se cobró pendiente por facturar (en activación)"
            },
            {
              label: "Se cobró pendiente por facturar (no hay internet)",
              value: "Se cobró pendiente por facturar (no hay internet)"
            },
            {
              label:
                "Se cobró pendiente por facturar (promoción no cargada en crm)",
              value:
                "Se cobró pendiente por facturar (promoción no cargada en crm)"
            },
            {
              label: "Solo tenemos una terminal fija y el cliente tiene prisa",
              value: "Solo tenemos una terminal fija y el cliente tiene prisa"
            }
          ]}
          ok={motivo => {
            const { indexItemToDisableForceCat } = this.state;
            const itemId = this.state.productList[indexItemToDisableForceCat]
              .itemId;

            productDisableForceCat(itemId, motivo)
              .then(() => {
                this.setState({
                  indexItemToDisableForceCat: null,
                  disableForceCatItemConfirmModalIsOpen: false
                });
                successNotification(
                  "Se deshabilitó el requerimiento de CAT forzoso correctamente. Cargando datos de carrito."
                );
                this.loadProductList();
              })
              .catch(handleRequestError);
          }}
          cancel={() => {
            this.setState({
              indexItemToDisableForceCat: null,
              disableForceCatItemConfirmModalIsOpen: false
            });
          }}
        />

        <LineaPagoModal
          isOpen={this.state.generateCashboxWithPaymentLineConfirmModalIsOpen}
          amount={this.state.generateCashboxWithPaymentLineAmount}
          showTokenizationControl={true}
          hasMembershipsOrTokenizationProducts={
            this.state.generateCashboxWithPaymentLineHasMembOrTokenization
          }
          hasMaintenancesProducts={
            this.state.generateCashboxWithPaymentLineHasMaintenances
          }
          generarLineaPago={(tokenizar, msi) => {
            this.setState({
              generateCashboxWithPaymentLineConfirmModalIsOpen: false,
              generateCashboxWithPaymentLineAmount: 0,
              generateCashboxWithPaymentLineHasMembOrTokenization: false,
              generateCashboxWithPaymentLineHasMaintenances: false
            });
            this.saveWithPaymentLine(tokenizar, msi);
          }}
          cancelar={() => {
            this.setState({
              generateCashboxWithPaymentLineConfirmModalIsOpen: false,
              generateCashboxWithPaymentLineAmount: 0,
              generateCashboxWithPaymentLineHasMembOrTokenization: false,
              generateCashboxWithPaymentLineHasMaintenances: false
            });
          }}
        />

        <ConfirmModal
          title="Aplicar descuento total"
          message="El código ingresado aplica al total del producto por lo que se confirmará, ¿Desea continuar?"
          isOpen={this.state.aplicaTotal}
          ok={() => {
            infoNotification("Aplicando descuento total...");
            descuentoTotal(this.state.total)
              .then(() => {
                successNotification(
                  "El código de descuento se aplicó correctamente"
                );
                this.setState({
                  aplicaTotal: false,
                  total: null
                });
              })
              .catch(() => {
                errorNotification("El código de descuento no se pudo aplicar");
                this.setState({
                  aplicaTotal: false,
                  total: null
                });
              });
          }}
          cancel={() => {
            this.setState({
              aplicaTotal: false,
              total: null
            });
          }}
        />

        <p className={`${s.grayTitle} w-100 my-1`}>Persona</p>
        <Row>
          <Col xs={12} md={4} lg={4}>
            <PersonSelector
              showEditButton={true}
              initPerson={
                isNil(this.props.match.params.personaId)
                  ? null
                  : this.state.selectedPerson || null
              }
              setPerson={person => {
                if (!isNil(person)) {
                  this.props.history.push(
                    `/productos/carrito/${person.personaId}`
                  );
                }
              }}
              cKey={"O5G5VN94PU"}
            />
          </Col>

          <Col xs={12} md={2} lg={2} className="mt-4">
            <div style={{ marginTop: "6px" }}>
              <a
                hidden={
                  isNil(this.props.match.params.personaId) ||
                  !this.state.membresiaSocioUrl
                }
                href={this.state.membresiaSocioUrl}
              >
                Ver membresía
              </a>
            </div>
          </Col>
          <Col xs={12} md={3} lg={3} className="mt-4">
            <div style={{ marginTop: "6px" }}>
              <a
                hidden={
                  isNil(this.props.match.params.personaId) ||
                  !this.state.ventaPermitida
                }
                href={`/cotizaciones/prospecto/${this.props.match.params.personaId}`}
                target="_blank"
              >
                Cotizar membresía
              </a>
              <div
                hidden={
                  isNil(this.props.match.params.personaId) ||
                  this.state.ventaPermitida
                }
                style={{ color: "#700", cursor: "pointer" }}
                id="venta-msg-error"
              >
                No puede cotizar membresías
              </div>
              <UncontrolledTooltip placement="left" target={`venta-msg-error`}>
                {(this.state.ventaPermitidaMensajes || []).map(r => (
                  <>
                    {r}
                    <br />
                  </>
                ))}
              </UncontrolledTooltip>
            </div>
          </Col>
          <Col xs={12} md={3} lg={3} className="mt-4">
            <div style={{ marginTop: "6px" }}>
              <a
                hidden={
                  !userHasPermission(permissionCodes.SHOPPING_CART_MULTIPLE)
                }
                href={
                  "/productos/cajas?clubId=" +
                  this.state.membresiaSocioClubId +
                  "&idPublico=" +
                  this.state.membresiaSocioPublicoId
                }
              >
                Ir a carrito para cargos multiples
              </a>
            </div>
          </Col>
        </Row>
        <p className={`${s.grayTitle} w-100 my-1`}>Producto</p>
        <Row>
          <SearchSportProduct
            sportsProductsTypes={this.props.sportsProductsTypes}
            sportsProductsLoading={this.props.sportsProductsLoading}
            sportsProducts={this.props.sportsProducts}
            sportProduct={this.state.sportProduct}
            handleInputChange={this.handleInputChange}
            onChangeFilter={this.onChangeProductClassification}
            onChangeSportProduct={this.onChangeSportProduct}
            onDropdownOpened={this.handleDropdownOpened}
            fitKidz={this.props.fitKidz}
            groupUser={this.props.groupUser}
          />
        </Row>
        <p className={`${s.grayTitle} w-100 my-1`}>Club</p>
        <Row>
          <Col xs={4} md={4} lg={4}>
            <BaseInput
              label="Tipo de Precio"
              name="tipoPrecioMembresiaPrecioFiltro"
              type="select"
              id="tipoPrecioMembresiaPrecioFiltro"
              placeholder="Tipo de Precio"
              value={this.state.tipoPrecio}
              disabled={!this.productIsValid()}
              size="sm"
              onChange={e => this.onChangePriceType(e)}
              options={this.props.tiposPrecio.map(option => ({
                value: option.esquemaPagoId,
                label: option.nombre
              }))}
            />
          </Col>
          <Col xs={8} md={8} lg={8}>
            <ClubCatalogSelect
              club={this.state.clubSelected}
              onSelectClub={this.onSelectClub}
              clubs={this.state.clubs}
              disabled={!this.productIsValid()}
            />
          </Col>
        </Row>
        <p className={`${s.grayTitle} w-100 my-1`}>
          Cantidad, Responsable y Precio
        </p>
        <form>
          <div className="form-row">
            <div className="col-9">
              <div className="form-row">
                <div className="col">
                  <CouponValidator
                    disabled={
                      !this.state.sportProduct.data || !this.state.clubSelected
                    }
                    onValidateDiscountCode={this.onValidateDiscountCode}
                    tipoCodigo={TIPO_CODIGO_PRODUCTO}
                    productoId={
                      !!this.state.sportProduct.data
                        ? this.state.sportProduct.data.id
                        : 0
                    }
                    clubId={
                      !!this.state.clubSelected
                        ? this.state.clubSelected.value
                        : null
                    }
                    reset={this.state.resetCodeValidator}
                  />
                </div>
                <div className="col">
                  <SelectFilter
                    label="Responsable de Venta"
                    name="sellingResponsible"
                    type="select"
                    id="sellingResponsible"
                    placeholder="Responsable de Venta"
                    value={this.state.responsible}
                    isMulti={false}
                    options={
                      this.props.users && userToOption
                        ? this.props.users.map(userToOption)
                        : []
                    }
                    onChange={this.onChangeResponsible}
                    loading={false}
                    isAsync={false}
                    disabled={!this.productIsValid()}
                  />
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-row">
                <div className="col">
                  <BaseInput
                    label="Cantidad"
                    name="cantidadProductoCompra"
                    type="number"
                    id="cantidadProductoCompra"
                    placeholder="Cantidad"
                    value={this.state.product.quantity}
                    onChange={this.onChangeFactory("quantity")}
                    disabled={!this.productIsValid()}
                  />
                </div>
                <div className="col">
                  <label>Precio:</label>
                  <div
                    className="px-2 py-1"
                    style={{
                      borderRadius: "4px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderColor: "hsl(0,0%,80%)",
                      height: "2rem"
                    }}
                  >
                    <label>
                      <MexicanCurrency quantity={this.findPrice()} />
                    </label>
                  </div>
                </div>
                <div className="col-1 p-1">
                  <span
                    className="addProductButton"
                    onClick={() =>
                      this.productIsValid() && this.canAddProduct()
                        ? this.addProductToList()
                        : null
                    }
                    style={
                      this.canAddProduct()
                        ? {
                            color: "black"
                          }
                        : {
                            color: "grey",
                            cursor: "not-allowed"
                          }
                    }
                  >
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
        <p className={`${s.grayTitle} w-100 my-1`}>
          Lista de Productos Seleccionados
        </p>
        {tieneCargosConCatForzoso &&
          (esClienteExtranjero &&
            !tieneFormatoMigracion &&
            !tieneMedioPagoRegistrado) && (
            <div className="alert alert-warning m-3 m-3">
              El cliente es extranjero pero no tiene cargado su documento de
              formato migratorio FM2, FM3 o FMT expedida por el Instituto
              Nacional de Migración de la Secretaría de Gobernación o un medio
              de pago nacional registrado válido
            </div>
          )}
        {tieneCargosConCatForzoso &&
          !esClienteExtranjero &&
          !tieneMedioPagoRegistrado && (
            <div className="alert alert-warning m-3 m-3">
              No cuenta con un medio de pago registrado. Para poder realizar el
              pago de los mantenimientos deberá tokenizar un tarjeta válida.
            </div>
          )}
        <div
          hidden={!this.state.hasProcessing}
          className="alert alert-warning mt-3"
          style={{ padding: "6px 8px" }}
          role="alert"
        >
          <p className="m-0">
            Algunos de los movimientos pendientes se estan procesando como
            cargos automáticos; por favor, espere la resolución para
            manipularlos manualmente en el carrito de compras.
          </p>
        </div>
        {!!this.state.membresiaSocioExiste && (
          <div className="alert alert-warning m-3 m-3">
            La venta de productos solo puede realizarse para membresias activas
          </div>
        )}
        {!!this.state.hasLineasPagoPendientes && (
          <div className="alert alert-warning m-3 m-3">
            El cliente tiene líneas de pago pendientes, no podrá ver los cargos
            de las mismas.
          </div>
        )}

        <div className="m-3">
          <div
            style={{
              display: "inline",
              padding: "0px 20px",
              marginRight: "8px",
              background: "#b3cbcb",
              border: "solid 1px #000"
            }}
          ></div>
          <span>Requiere cat forzoso</span>
        </div>

        <ProductsList
          products={this.state.productList}
          deleteProduct={this.deleteProduct}
          updateProductAmout={this.updateProductAmout}
          productPaymentException={this.productPaymentException}
          updateProductDescription={this.updateProductDescription}
          updateProductDatePeriod={this.updateProductDatePeriod}
          disableForceCat={this.productDisableForceCat}
          minRows={6}
          loading={false}
          showChangeDescription={true}
          showChangeDatePeriod={true}
          setChecked={this.setChecked}
          freezeObject={this.state.freezeObject}
          setFreezeObject={this.setFreezeObject}
          membresiaSocioEstatus={this.state.membresiaSocioEstatus || ""}
          membresiaSocioExiste={!!this.state.membresiaSocioExiste}
        />
        <Row className="m-0">
          <Col />
          <Col className="p-0 pl-1 pr-1" xs="2">
            <Button
              className={`${s.primaryButton} my-1`}
              onClick={e => {
                e.preventDefault();
                this.onClickSave();
              }}
              size="sm"
              block
            >
              Guardar y Pagar
            </Button>
          </Col>
          <Col className="p-0 pl-1 pr-1" xs="2">
            <Button
              className={`${s.secondaryButton} my-1`}
              onClick={e => {
                e.preventDefault();
                this.onClickSaveWithPaymentLine();
              }}
              size="sm"
              block
            >
              Guardar y Generar línea de pago
            </Button>
          </Col>
        </Row>
        <p className={`${s.grayTitle} w-100 my-1 mt-3`}>Cajas Abiertas</p>
        <Row className="mt-3 mb-5">
          <Col>
            <ListGroup className="my-1">
              {this.state.checkboxes &&
                this.state.checkboxes.map((cb: any) => {
                  const { fechaCreacion, items, id, numeroVentas } = cb;
                  return (
                    <ListGroupItem
                      key={id}
                      className="p-1"
                      tag="button"
                      action
                      onClick={() => this.props.history.push(getLinkCaja(id))}
                      style={{ fontSize: "0.75rem" }}
                    >
                      {userHasPermission(
                        permissionCodes.CASHBOX_DELETE_PERMISSION
                      ) && (
                        <span>
                          <Button
                            onClick={e => {
                              e.stopPropagation();
                              this.loadCashboxToDelete(id);
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />{" "}
                          </Button>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      )}
                      {`${moment(fechaCreacion).format(
                        "DD-MM-YYYY HH:mm"
                      )}, ${items} producto(s)`}
                    </ListGroupItem>
                  );
                })}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(SportsProductsNewQuote));
