import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { Button, UncontrolledTooltip } from "reactstrap";
import s from "../../styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faCameraRetro,
  faEye,
  faFileExport,
  faFingerprint,
  faInfoCircle,
  faPencilAlt,
  faPlus,
  faTrash,
  faUserCheck,
  faUserLock
} from "@fortawesome/free-solid-svg-icons";
import permissionCodes from "../../../../../utils/permissionCodes";
import { userHasPermission } from "../../../../../utils/accessTree";
import DeportivaEventoAsignado from "../../../../../types/DeportivaEventoAsignado";

const {
  COMISIONES_V2_IMPARTICION_EVENTO: IMPARTICION_EVENTO,
  COMISIONES_V2_IMPARTICION_EVENTO_ACTUALIZACION: IMPARTICION_ACTUALIZACION
} = permissionCodes;

export interface TableProps {
  page: Page<DeportivaEventoAsignado>;
  loading: boolean;
  onFetchData: ({ page, pageSize }) => void;
  onExport: () => void;
  onMarcarImpartido: (row: DeportivaEventoAsignado) => void;
  onMarcarFaltaInstructor: (row: DeportivaEventoAsignado) => void;
  onMarcarFaltaCliente: (row: DeportivaEventoAsignado) => void;
  onActualizar: (row: DeportivaEventoAsignado) => void;
}

export const MemoTable = React.memo(
  ({
    page,
    loading,
    onFetchData,
    onExport,
    onMarcarImpartido,
    onMarcarFaltaInstructor,
    onMarcarFaltaCliente,
    onActualizar
  }: TableProps) => {
    // State de las columnas de la tabla
    const [columns, setColumns] = useState(
      columnsFormatter(
        onMarcarImpartido,
        onMarcarFaltaInstructor,
        onMarcarFaltaCliente,
        onActualizar
      )
    );

    const [pageNumber, setPageNumber] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);

    // Actualización de estilos
    useEffect(() => {
      setColumns(
        columnsFormatter(
          onMarcarImpartido,
          onMarcarFaltaInstructor,
          onMarcarFaltaCliente,
          onActualizar
        )
      );
    }, []);

    // Actualización de datos de paginación
    useEffect(() => {
      if (page) {
        setPageNumber(page.number);
        setTotalPages(page.totalPages);
      }
    }, [page]);

    const mostrados = page && page.numberOfElements;
    const totales = page && page.totalElements;
    return (
      <>
        {/* Botón para exportar */}
        <div className="row">
          {!!page && !!page.content && !!page.content.length && (
            <>
              <div className="col-6 p-0" style={{ lineHeight: "40px" }}>
                <span>
                  Invitados mostrados: {mostrados} de {totales}
                </span>
              </div>
              <div
                className="col-6 p-0 text-right"
                style={{ lineHeight: "40px" }}
              >
                <Button
                  hidden
                  color="primary"
                  onClick={onExport}
                  disabled={loading}
                  className="mr-2"
                >
                  <FontAwesomeIcon icon={faFileExport} />
                  {!loading ? " Exportar" : " Espere..."}
                </Button>
              </div>
            </>
          )}
        </div>

        {/* Tabla */}
        <div className="row mt-2">
          <div style={{ width: "100%" }}>
            <ReactTable
              manual
              data={(page && page.content) || []}
              columns={columns}
              loading={loading}
              defaultPageSize={50}
              className="-striped -highlight"
              onFetchData={onFetchData}
              page={pageNumber}
              pages={totalPages}
              showPagination={true}
              showPageSizeOptions={false}
              getTrProps={() => ({ style: { height: "30px" } })}
              sortable={false}
            />
          </div>
        </div>
      </>
    );
  }
);

// TODO: Definir permisos para los botones
function columnsFormatter(
  onMarcarImpartido: (row: DeportivaEventoAsignado) => void,
  onMarcarFaltaInstructor: (row: DeportivaEventoAsignado) => void,
  onMarcarFaltaCliente: (row: DeportivaEventoAsignado) => void,
  onActualizar: (row: DeportivaEventoAsignado) => void
) {
  return [
    {
      Header: "Factura",
      id: "folioFactura",
      className: s.tdClear,
      accessor: simpleAccessor("folioFactura")
    },
    {
      Header: "Producto",
      id: "producto",
      className: s.tdClear,
      accessor: simpleAccessor("producto")
    },
    {
      Header: "Instructor",
      id: "instructor",
      className: s.tdClear,
      accessor: simpleAccessor("instructor")
    },
    {
      Header: "Fecha o periodo",
      id: "coordinador",
      className: s.tdClear,
      accessor: (row: DeportivaEventoAsignado) => {
        return (
          <div className={`${fieldClassName(row)} text-center`}>
            {row.periodo || row.fechaClase + " " + row.horaClase}
          </div>
        );
      }
    },
    {
      Header: "Cliente",
      id: "persona",
      className: s.tdClear,
      accessor: simpleAccessor("persona")
    },
    {
      Header: "",
      id: "acciones",
      className: s.tdClear,
      width: 250,
      accessor: (row: DeportivaEventoAsignado) => {
        return (
          <div className={`${fieldClassName(row)} text-center`}>
            {renderBotonImparticion(
              row,
              onMarcarImpartido,
              "Confirmar la " +
                (row.idClase
                  ? "impartición de la clase"
                  : "participación en el programa"),
              "Este evento no pertenece a su club",
              "-impartido"
            )}
            {renderBotonImparticion(
              row,
              onMarcarFaltaInstructor,
              "Inasistencia del instructor",
              "Este evento no pertenece a su club",
              "-falta-instructor"
            )}
            {renderBotonImparticion(
              row,
              onMarcarFaltaCliente,
              "Inasistencia del cliente",
              "Este evento no pertenece a su club",
              "-falla-cliente"
            )}
            {renderBotonActualizacion(
              row,
              onActualizar,
              "Reprogramar la clase",
              "Este evento no pertenece a su club",
              "-reprogramar"
            )}
          </div>
        );
      }
    }
  ];
}

function simpleAccessor(key: keyof DeportivaEventoAsignado) {
  return (row: DeportivaEventoAsignado) => {
    return <div className={fieldClassName(row)}>{row[key]}&nbsp;</div>;
  };
}

function fieldClassName(row: DeportivaEventoAsignado) {
  return row.permisoClub ? `${s.field}` : `${s.field} ${s.noPermitido}`;
}

function renderBotonImparticion(
  row: DeportivaEventoAsignado,
  action,
  message,
  clubError,
  idPostfix
) {
  if (!userHasPermission(IMPARTICION_EVENTO)) {
    return false;
  }

  const id = "id-" + (row.idClase || row.idParticipacion) + "-btn-" + idPostfix;

  if (row.permisoClub) {
    return renderBoton(row, id, true, action, message, "#008080", faInfoCircle);
  } else {
    return renderBoton(row, id, false, action, clubError, "#600", faInfoCircle);
  }
}

function renderBotonActualizacion(
  row: DeportivaEventoAsignado,
  action,
  message,
  clubError,
  idPostfix
) {
  if (!userHasPermission(IMPARTICION_ACTUALIZACION)) {
    return false;
  }

  const id = "id-" + (row.idClase || row.idParticipacion) + "-btn-" + idPostfix;

  if (row.permisoClub) {
    return renderBoton(row, id, true, action, message, "#008080", faInfoCircle);
  } else {
    return renderBoton(row, id, false, action, clubError, "#600", faInfoCircle);
  }
}

function renderBoton(
  row: DeportivaEventoAsignado,
  id,
  habilitado,
  action,
  tooltip,
  color,
  icon
) {
  if (habilitado) {
    return (
      <span className={s.info} id={id}>
        <FontAwesomeIcon icon={icon} color={color} />
        <UncontrolledTooltip placement="left" target={id}>
          {tooltip}
        </UncontrolledTooltip>
      </span>
    );
  } else {
    return (
      <span id={id} onClick={() => action(row)} className={s.btn}>
        <FontAwesomeIcon icon={icon} color={color} />
        <UncontrolledTooltip placement="left" target={id}>
          {tooltip}
        </UncontrolledTooltip>
      </span>
    );
  }
}
