import React, { Component } from "react";
import { ReduxConnect, Router } from "../../../../toolkit/decorators/Artifact";
import BAsignacionComponent from "../../../components/comisionesDeportivaV2Carrusel/steps/BAsignacion/BAsignacion";
import { BAsignacionStore, bAsignacionStore } from "./BAsignacion.store";
import {
  BAsignacionDispatcher,
  bAsignacionDispatcher
} from "./BAsignacion.dispatcher";

interface Props extends BAsignacionStore, BAsignacionDispatcher {}
interface State {}

@Router()
@ReduxConnect(bAsignacionStore, bAsignacionDispatcher)
export default class BAsignacionContainer extends Component<Props, State> {
  render() {
    return <BAsignacionComponent {...this.props} />;
  }
}
