import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Switch, BrowserRouter, StaticRouter } from "react-router-dom";
import axios from "axios";

import AgreementsCarousel from "./Home/containers/agreementsCarousel";
import AgreementGroupsCarousel from "./Home/containers/agreementGroups/agreementsCarousel";
import Annuities from "./Home/containers/annuities";
import AnnuitiesConfig from "./Home/containers/annuitiesConfig";
import AnnuitiesAuth from "./Home/containers/annuitiesAuthorize";
import AnnuitiesQuote from "./Home/containers/annuitiesQuote";
import AnnuitiesQuoteById from "./Home/containers/annuitiesQuoteById";
import Banks from "./Home/containers/banks";
import BanksMonthsWithOutInterest from "./Home/containers/banksMonthsWithOutInterest";
import Billing from "./Home/containers/billing";
import Clubs from "./Home/containers/clubs";
import ComissionsReport from "./Home/containers/comissionsReport";
import ComissionsSaleReport from "./Home/containers/comissionsSaleReport";
import ComissionsReactivationReport from "./Home/containers/comissionsReactivationReport";
import ComissionsProduct from "./Home/containers/comissionsProduct";
import ComissionsSchemas from "./Home/containers/comissionsSchemas";
import ComissionsSchemasList from "./Home/containers/comissionsSchemaList";
import ConfigDocuments from "./Home/containers/configDocuments";
import Demand from "./Home/containers/demands";
import DemandsList from "./Home/containers/demandsList";
import DiscountCodeCreator from "./Home/containers/discountCodeCreator";
import DiscountCodeList from "./Home/containers/discountCodeList";
import DiscCodeListByMembership from "./Home/containers/discCodeListByMembership";
import DocumentsCarousel from "./Home/containers/documentsCarousel";
import FacturacionAdm from "./Home/containers/facturacionAdm";
import Goals from "./Home/containers/goals";
import Home from "./Home/containers/home";
import LandingPage from "./Home/components/landingPage";
import TestPage from "./Home/components/testPage";
import LockerAssigned from "./Home/containers/lockerAssigned";
import LockerConfiguration from "./Home/containers/lockerConfiguration";
import LockersAssignments from "./Home/containers/lockersAssignments";
import LockersSummary from "./Home/containers/lockersSummary";
import MaintenancesCarousel from "./Home/containers/maintenancesCarousel";
import MaintenancesPricesMassiveLoad from "./Home/containers/maintenancesPricesMassiveLoad";
import MembershipMembers from "./Home/containers/membershipMembers";
import MembershipMembersRules from "./Home/containers/membershipMembersRules";
import MembershipsCarousel from "./Home/containers/membershipsCarousel";
import MembershipSoldReport from "./Home/containers/membershipSoldReport";
import MovementsPayments from "./Home/containers/movementsPayments";
import OneDayPassActivator from "./Home/containers/oneDayPassActivator";
import Partners from "./Home/containers/partners";
import PartnerSuccessfullPurchase from "./Home/containers/partnerSuccessfullPurchase";
import PeriodsConfiguration from "./Home/containers/periodsConfiguration";
import Products from "./Home/containers/products";
import ProductsAuthorization from "./Home/containers/productsAuthorization";
import ProductsActivation from "./Home/containers/productsActivation";
import Promotions from "./Home/containers/promotions";
import PromotionsCarousel from "./Home/containers/promotionsCarousel";
import Prospects from "./Home/containers/prospects";
import ProspectDetail from "./Home/containers/prospectDetail";
import prospectDetailView from "./Home/containers/prospectDetailView";
import Quotations from "./Home/containers/quotations";
import RoleCreation from "./Home/containers/createRole";
import Roles from "./Home/containers/roles";
import SellsLte from "./Home/containers/sellsLte";
import SportsProducts from "./Home/containers/sportsProducts";
import SportsProductsCarousel from "./Home/containers/sportsProductsCarousel";
import SportsProductsNewQuote from "./Home/containers/sportsProductsNewQuote";
import SportsProductsQuote from "./Home/containers/sportsProductsQuote";
import SportsProductsQuoteById from "./Home/containers/sportsProductsQuoteById";
import SportsProgram from "./Home/containers/sportsProgram";
import SportsScheduledSessions from "./Home/containers/sportsScheduledSessions";
import SportsSells from "./Home/containers/sportsSells";
import SportsSession from "./Home/containers/sportsSession";
import SportsSoldProducts from "./Home/containers/sportsSoldProducts";
import SurveyFollowUp from "./Home/containers/surveyFollowUp";
import Users from "./Home/containers/users";
import UsersCarousel from "./Home/containers/usersCarousel";
import SchedulePatterns from "./SchedulePatterns/containers/SchedulePatterns";
import SportsProgramsComissions from "./Home/containers/sportsProgramsComissions";
import AdminProductsClassificationsContainer from "./Home/containers/adminProductsClassifications";
import Bills from "./Home/containers/bills";
import Sales from "./Home/containers/sales";
import PersonClubIngress from "./Home/containers/personClubIngress";
import IngressByClubReport from "./Home/containers/ingressByClubReport";
import SpecialGuests from "./Home/containers/specialGuestsList";
import SpecialGuest from "./Home/containers/specialGuest";
import PasswordPage from "./Home/containers/password";
import RecoveryRequestPage from "./Home/containers/recoveryRequest";
import RecoveryPage from "./Home/containers/recovery";
import sportsProductsQuotesSelectorPage from "./Home/containers/sportsProductsQuotesSelector";
import ReferredCodesConfiguration from "./ReferredCodes/containers/ReferredCodesConfiguration";
import {
  RouteMobileError,
  RouteNotFoundError
} from "./Home/components/errorMessages";
import CONSTANTS from "./utils/constants";
import DISPLAYS_CODES from "./utils/displayCodes";
import ContextType from "./types/contextType";
import PublicRoute from "./utils/publicRoute";
import PrivateRoute from "./utils/privateRoute";
import {
  MAINTENANCES_STEPS,
  MEMBERSHIPS_STEPS,
  PROMOTIONS_STEPS,
  USERS_STEPS,
  AGREEMENTS_STEPS,
  DOCUMENTS_STEPS,
  SPORTS_PRODUCTS_STEPS,
  AGREEMENT_GROUPS_STEPS,
  COMPANIES_STEPS,
  EMPRESAS_CONVENIO_STEPS
} from "./steps";
import SalesTracking from "./Tracking/Sales/containers/MonthlySalesTracking";
import errorPage from "./Home/containers/errorPage";
import Quizzes from "./Home/containers/quizzes";
import Fitkidz from "./Home/containers/fitkidz";
import Accrual from "./Home/containers/accrual";
import UnbilledSales from "./Home/containers/unbilledSales";
import CashCut from "./Home/containers/cashCut";
import ShiftDelivery from "./Home/containers/shiftDelivery";
import PaymentPluginValidation from "./Home/containers/paymentPluginValidation";
import CreditNotes from "./Home/containers/creditNotes";
import CustomizableReceipts from "./Home/containers/customizableReceipts";
import ReferencedDeposit from "./Home/containers/referencedDeposit";
import GenerationDealerCharges from "./Home/containers/generationDealerCharges";
import Dealers from "./Home/containers/dealers";
import Corporatives from "./Home/containers/corporatives";
import VariablesChargeDealer from "./Home/containers/variablesChargeDealer";
import MovementStatus from "./Home/containers/movementStatus";
import DealerStatements from "./Home/containers/dealerStatements";
import SantanderReference from "./Home/containers/santanderReference";
import Refounds from "./Home/containers/refounds";
import ReferenceCalculation from "./Home/containers/referenceCalculation";
import SantanderRefDosntApply from "./Home/containers/santanderRefDosntApply";
import Employees from "./Home/containers/employees";
import TycProductComissionConfigToAuth from "./Home/containers/tyc/productsWithCommisionConfigToAuth";
import ProspectsReportPage from "./Home/containers/prospectsReport";
import PromotionsReportPage from "./Home/containers/promotionsReport";
import AgreementsReportPage from "./Home/containers/agreementsReport";
import AdminDemandsClassificationsContainer from "./Home/containers/adminDemandsClassifications";
import AdminQuizzCategories from "./Home/containers/adminQuizzCategories";
import QuizzDevicesResume from "./Home/containers/quizzDevicesResume";
import QuizzSurveyAppliedResume from "./Home/containers/quizzSurveyAppliedResume";
import AdminQuizzAndDevicesPerYearPage from "./Home/containers/adminQuizzAndDevicesPerYear";
import ComissionsSummaryReportPage from "./Home/containers/comissionsSummaryReport";
import AccrualMonthlyReport from "./Home/containers/accrualMonthlyReport";
import MaintenancesPricesGridReport from "./Home/containers/maintenacesPricesGrid";
import PaymentLinesPage from "./Home/containers/paymentLinesReport";
import { InboxCalendarSelector } from "./Home/containers/inboxCalendarSelector/index";
import MailContact from "./Home/containers/mailContact";
import ConveniosContainer from "./Home/containers/convenios/index";
import { getDecoratedRoutes } from "./toolkit/decorators/Artifact";
import ConvenioCarousel from "./Home/containers/conveniosCarousel/index";
import companies from "./Home/containers/companies";
import EmpresaConvenio from "./Home/containers/empresaConvenio";
import CompaniesCarousel from "./Home/containers/companiesCarousel";
import personalDocumentsHistory from "./Home/containers/personalDocumentsHistory";
import CatPreliminarReport from "./Home/containers/catPreliminarReport";
import CatPreliminarDebtRestructuring from "./Home/containers/catPreliminarDebtRestructuring";
import ReportCatProcessesDirectionSummary from "./Home/containers/catProcessesDirectionSummaryReport";
import CatClubConfig from "./Home/containers/catClubConfig";
import Binnacles from "./Home/containers/binnacles";
import SworldConfigPage from "./Home/containers/sworldConfig/index";
import ExpedienteValidatorPage from "./Home/containers/expedientV2validation/index";
import EmpresaConvenioCarousel from "./Home/containers/empresaConvenioCarousel";
import MediosPagoEliminados from "./Home/containers/mediosPagoEliminados";
import DescuentoDirecto from "./Home/containers/descuentoDirecto";
import Precalculo from "./Home/containers/precalculo";
import LotesCargosAutomaticos from "./Home/containers/lotesCargosAutomaticos";
import Blackpass from "./Home/containers/blackpass";
import AdeudosPage from "./Home/containers/adeudos";
import ReactivacionesReporte from "./Home/containers/reactivacionesReporte";
import VentasPortalReporte from "./Home/containers/ventasPortalReporte";
import PartnerMembreshipDirectoryReport from "./Home/containers/partnerMembreshipDirectoryReport";
import LineaPagoPublicoPage from "./Home/containers/lineaPagoPublico";
import PartnerMembreshipTransfersReport from "./Home/containers/partnerMembreshipTransferReport";
import ComisionesDeportivaPendientesPage from "./Home/containers/comisionesDeportivaPendientes/index";
import ClaseSinComisionPage from "./Home/containers/claseSinComision";
import MembresiasExternasPage from "./Home/containers/membresiasExternas";
import LockerExtension from "./Home/containers/lockerExtension/index";
import ReferidosLicenciasReporteContainer from "./Home/containers/referidosLicenciasReporte";
import ReportPartnerMembershipsWithoutChargePage from "./Home/containers/partnerMembershipsWithoutMonthChargeReport";
import ComisionesTycPendientes from "./Home/containers/comisionesTycPendientes";
import ComisionesTycPagadas from "./Home/containers/comisionesTycPagadas";
import crm1PartnerMembershipReactivatePage from "./Home/containers/crm1PartnerMembershipReactivatePage";
import NotaCreditoBloqueoContainer from "./Home/containers/notaCreditoBloqueo/index";
import InvitadosListContainer from "./Home/containers/invitadosList";
import InvitadosContainer from "./Home/containers/invitados";
import InvitadosDetalleContainer from "./Home/containers/invitadosDetalle";
import SatCancelacionesContainer from "./Home/containers/satCancelaciones";
import MiscReportsPage from "./Home/containers/miscReports";
import ComisionesDeportivaCarrusel from "./Home/containers/comisionesDeportivaV2Carrusel";

const restClient = axios.create({
  baseURL: CONSTANTS.BASE_URL
});

const Routes = () => (
  <div>
    <Helmet
      titleTemplate="SPORTSWORLD"
      defaultTitle="SPORTSWORLD"
      link={[
        { rel: "canonical", href: "https://www.mahisoft.com" },
        { rel: "icon", href: "tile-sw.png" }
      ]}
    />
    <Switch>
      <PublicRoute exact path="/" component={Home} />
      <PublicRoute exact path="/mit/payment" component={LineaPagoPublicoPage} />

      <PublicRoute exact path="/revoke" component={PasswordPage} />
      <PublicRoute
        exact
        path="/recovery-request"
        component={RecoveryRequestPage}
      />
      <PublicRoute exact path="/recovery" component={RecoveryPage} />

      <PublicRoute
        exact
        path="/membresias-externas"
        component={MembresiasExternasPage}
      />

      <PrivateRoute
        path="/membresias-crm1-reactivacion"
        component={crm1PartnerMembershipReactivatePage}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/test"
        component={TestPage}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/inicio"
        component={LandingPage}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/socio/venta-exitosa/:idMembresiaSocio"
        component={PartnerSuccessfullPurchase}
        display={DISPLAYS_CODES.PASS}
      />
      {DOCUMENTS_STEPS.map(({ path }, index) => (
        <PrivateRoute
          path={path}
          component={props => <DocumentsCarousel {...props} index={index} />}
          key={index}
          display={DISPLAYS_CODES.PASS}
        />
      ))}
      <PrivateRoute
        path="/documentos"
        component={ConfigDocuments}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/membresias-socios/:idMembresiaSocio?"
        component={Partners}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/prospecto/:personaId/detalle"
        component={ProspectDetail}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/prospecto/:personaId/ver"
        component={prospectDetailView}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/socio/:personaId/detalle"
        component={ProspectDetail}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/socio/:personaId/ver"
        component={prospectDetailView}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/invitado/:personaId/detalle"
        component={ProspectDetail}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/invitado/:personaId/ver"
        component={prospectDetailView}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/concesionarios/:personaId/detalle"
        component={ProspectDetail}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/concesionarios/:personaId/ver"
        component={prospectDetailView}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/corporativos/:personaId/detalle"
        component={ProspectDetail}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/corporativos/:personaId/ver"
        component={prospectDetailView}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/externo/:personaId/detalle"
        component={ProspectDetail}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/externo/:personaId/ver"
        component={prospectDetailView}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/empleado/:personaId/detalle"
        component={ProspectDetail}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/empleado/:personaId/ver"
        component={prospectDetailView}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/prospectos"
        component={Prospects}
        display={DISPLAYS_CODES.PROSPECTS}
      />
      <PrivateRoute
        path="/membresias/:membresiaId/reglas-integrantes"
        component={MembershipMembersRules}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/membresias/:membresiaId/integrantes"
        component={MembershipMembers}
        display={DISPLAYS_CODES.PASS}
      />
      {MAINTENANCES_STEPS.map(({ path }, index) => (
        <PrivateRoute
          path={path}
          component={MaintenancesCarousel}
          key={index}
          extraParams={{
            index
          }}
          display={DISPLAYS_CODES.MAINTENANCES}
        />
      ))}
      {MEMBERSHIPS_STEPS.map(({ path }, index) => (
        <PrivateRoute
          path={path}
          component={MembershipsCarousel}
          extraParams={{
            index
          }}
          key={index}
          display={DISPLAYS_CODES.MEMBERSHIPS}
        />
      ))}
      {PROMOTIONS_STEPS.map(({ path }, index) => (
        <PrivateRoute
          path={path}
          component={props => <PromotionsCarousel {...props} index={index} />}
          key={index}
          display={DISPLAYS_CODES.PASS}
        />
      ))}
      <PrivateRoute
        path="/promociones"
        component={Promotions}
        display={DISPLAYS_CODES.PASS}
      />
      {USERS_STEPS.map(({ path }, index) => (
        <PrivateRoute
          path={path}
          component={props => <UsersCarousel {...props} index={index} />}
          key={index}
          display={DISPLAYS_CODES.PASS}
        />
      ))}
      <PrivateRoute
        path="/usuarios"
        component={Users}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/roles/nuevo-rol/:roleId?"
        component={RoleCreation}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/roles"
        component={Roles}
        display={DISPLAYS_CODES.PASS}
      />
      {AGREEMENTS_STEPS.map(({ path }, index) => (
        <PrivateRoute
          path={path}
          component={props => <AgreementsCarousel {...props} index={index} />}
          key={index}
          display={DISPLAYS_CODES.PASS}
        />
      ))}
      {AGREEMENT_GROUPS_STEPS.map(({ path }, index) => (
        <PrivateRoute
          path={path}
          component={props => (
            <AgreementGroupsCarousel {...props} index={index} />
          )}
          key={index}
          display={DISPLAYS_CODES.PASS}
        />
      ))}
      <PrivateRoute
        path="/clubes/configuracion-cat"
        component={CatClubConfig}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/clubes"
        component={Clubs}
        display={DISPLAYS_CODES.CLUBS}
      />
      <PrivateRoute
        path="/:tipoProducto(membresias)"
        component={Products}
        display={DISPLAYS_CODES.MEMBERSHIPS}
      />
      <PrivateRoute
        path="/mantenimientos/precios/carga-masiva"
        component={MaintenancesPricesMassiveLoad}
        display={DISPLAYS_CODES.MASSIVE_LOAD}
      />
      <PrivateRoute
        path="/:tipoProducto(mantenimientos)"
        component={Products}
        display={DISPLAYS_CODES.MAINTENANCES}
      />
      <PrivateRoute
        path="/cotizaciones/prospecto/:personaId"
        component={Quotations}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/productos/cajas"
        component={sportsProductsQuotesSelectorPage}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/productos/caja/:id?"
        component={SportsProductsQuoteById}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/productos/cotizaciones"
        component={SportsProductsQuote}
        display={DISPLAYS_CODES.PASS}
      />
      {SPORTS_PRODUCTS_STEPS.map(({ path }, index) => (
        <PrivateRoute
          path={path}
          component={props => (
            <SportsProductsCarousel {...props} index={index} />
          )}
          key={index}
          display={DISPLAYS_CODES.PASS}
        />
      ))}
      <PrivateRoute
        path="/productos/carrito/:personaId?"
        component={SportsProductsNewQuote}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/productos"
        component={SportsProducts}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/bancos"
        component={Banks}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/bancos-meses-sin-intereses"
        component={BanksMonthsWithOutInterest}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/autorizacion-precios"
        component={ProductsAuthorization}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/activacion-productos"
        component={ProductsActivation}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/anualidades/cotizacion/:id"
        component={AnnuitiesQuoteById}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/anualidades/cotizacion"
        component={AnnuitiesQuote}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/anualidades/autorizar"
        component={AnnuitiesAuth}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/anualidades/editar/:id?"
        component={AnnuitiesConfig}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/anualidades"
        component={Annuities}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/metas"
        component={Goals}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/expedientes-documentos"
        component={ExpedienteValidatorPage}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/esquemas-comisiones/:id?"
        component={ComissionsSchemas}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/configuracion-periodos/:periodId?"
        component={PeriodsConfiguration}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/comisiones-membresia"
        component={ComissionsReport}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/comisiones-de-venta"
        component={ComissionsSaleReport}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/comisiones-reactivacion"
        component={ComissionsReactivationReport}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/comisiones-producto"
        component={ComissionsProduct}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/talento-y-cultura/comisiones-pendientes"
        component={ComisionesTycPendientes}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/talento-y-cultura/comisiones-pagadas"
        component={ComisionesTycPagadas}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/listado-esquemas"
        component={ComissionsSchemasList}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/ventas-membresias"
        component={MembershipSoldReport}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/calendario-buzones"
        component={InboxCalendarSelector}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/ventas-lote"
        component={SellsLte}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/nueva-queja/:quejaId?"
        component={Demand}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/quejas"
        component={DemandsList}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/lockers/configuracion"
        component={LockerConfiguration}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/facturacion"
        component={Billing}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/facturacion-adm/:personaId?"
        component={FacturacionAdm}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/creacion-codigos"
        component={DiscountCodeCreator}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/listado-codigos"
        component={DiscountCodeList}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/pase-dia"
        component={OneDayPassActivator}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/comunicado"
        component={MailContact}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/codigos-por-membresia"
        component={DiscCodeListByMembership}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/lockers/asignados"
        component={LockerAssigned}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/lockers/extension/:idProductoLocker"
        component={LockerExtension}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/lockers/asignacion"
        component={LockersAssignments}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/lockers/resumen"
        component={LockersSummary}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/movimientos"
        component={MovementsPayments}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/deportiva/productos"
        component={SportsSoldProducts}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/deportiva/ventas/:eventId/club/:clubId"
        component={SportsSells}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/deportiva/clase/:productId/:personaId"
        component={SportsSession}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/deportiva/clases-programadas/"
        component={SportsScheduledSessions}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/programas-deportivos/registro"
        component={SportsProgram}
        display={DISPLAYS_CODES.PASS}
      />
      <PublicRoute
        exact
        path="/quejas/encuesta-seguimiento"
        component={SurveyFollowUp}
      />
      <PrivateRoute
        path="/reportes/comisiones-programas-deportivos"
        component={SportsProgramsComissions}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/facturas"
        component={Bills}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/ventas"
        component={Sales}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/configuracion/clasificacion-productos"
        component={AdminProductsClassificationsContainer}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/configuracion/patrones-horario"
        component={SchedulePatterns}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/tracking-ventas"
        component={SalesTracking}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/personas/ingresos"
        component={PersonClubIngress}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/ingresos-por-club"
        component={IngressByClubReport}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/invitados-especiales"
        component={InvitadosListContainer}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/invitado-especial/:id"
        component={InvitadosDetalleContainer}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/invitado-especial"
        component={InvitadosContainer}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/encuestas"
        component={Quizzes}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/configuracion/codigos-referidos"
        component={ReferredCodesConfiguration}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/fitkidz"
        component={Fitkidz}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/devengamiento"
        component={Accrual}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/ventas-no-facturadas"
        component={UnbilledSales}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/corte-de-caja"
        component={CashCut}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/entrega-turno"
        component={ShiftDelivery}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/nota-de-credito"
        component={CreditNotes}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/deposito-referenciado"
        component={ReferencedDeposit}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/validacion-complementos-pago"
        component={PaymentPluginValidation}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/recibos-provisionales"
        component={CustomizableReceipts}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/concesionarios/configuracion-cargos-concesionario"
        component={GenerationDealerCharges}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/concesionarios/estatus-movimientos"
        component={MovementStatus}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/concesionarios/estados-de-cuenta"
        component={DealerStatements}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/concesionarios/cargos-variables"
        component={VariablesChargeDealer}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/finanzas/calcula-referencia"
        component={ReferenceCalculation}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/concesionarios"
        component={Dealers}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/clientes-corporativos"
        component={Corporatives}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/referencias/reporte"
        component={SantanderReference}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/referencias/no-aplicadas"
        component={SantanderRefDosntApply}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/devoluciones/reporte"
        component={Refounds}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/empleados/reporte"
        component={Employees}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/tyc/productos/configuracion-comisiones"
        component={TycProductComissionConfigToAuth}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/prospectos"
        component={ProspectsReportPage}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/promociones"
        component={PromotionsReportPage}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/convenios"
        component={AgreementsReportPage}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/configuracion/clasificacion-quejas"
        component={AdminDemandsClassificationsContainer}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/configuracion/encuestas-categorias"
        component={AdminQuizzCategories}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/dispositivos/encuestas/resumen"
        component={QuizzDevicesResume}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reporte/encuestas-aplicadas"
        component={QuizzSurveyAppliedResume}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/configuracion/encuestas-dispositivos-anual"
        component={AdminQuizzAndDevicesPerYearPage}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/reportes/comisiones-por-vendedor"
        component={ComissionsSummaryReportPage}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/reportes/devengamiento-mensual"
        component={AccrualMonthlyReport}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/parrilla-precios"
        component={MaintenancesPricesGridReport}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/lineas-pago"
        component={PaymentLinesPage}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/reportes/membresias-socio-sin-cargo"
        component={ReportPartnerMembershipsWithoutChargePage}
        display={DISPLAYS_CODES.PASS}
      />
      <PrivateRoute
        path="/empresas"
        component={companies}
        display={DISPLAYS_CODES.PASS}
      />
      {COMPANIES_STEPS.map(({ path }, index) => (
        <PrivateRoute
          path={path}
          component={props => <CompaniesCarousel {...props} index={index} />}
          key={index}
          display={DISPLAYS_CODES.PASS}
        />
      ))}

      {getDecoratedRoutes(EmpresaConvenioCarousel)}
      <PrivateRoute
        path="/empresas-convenios"
        component={EmpresaConvenio}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/historial-documento-personal"
        component={personalDocumentsHistory}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/reportes/cat-preliminar/restructuracion-deuda"
        component={CatPreliminarDebtRestructuring}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/reportes/cat-preliminar"
        component={CatPreliminarReport}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/reportes/cat-procesos/reporte-direccion"
        component={ReportCatProcessesDirectionSummary}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/password"
        component={PasswordPage}
        display={DISPLAYS_CODES.PASS}
      />

      {getDecoratedRoutes(ConvenioCarousel)}
      <PrivateRoute
        path="/convenios"
        component={ConveniosContainer}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/bitacora"
        component={Binnacles}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/sworld-config"
        component={SworldConfigPage}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/medios-pago-eliminados"
        component={MediosPagoEliminados}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/descuento-directo-a-membresias"
        component={DescuentoDirecto}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/sat-cancelaciones"
        component={SatCancelacionesContainer}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/reportes/precalculo"
        component={Precalculo}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/envio-a-cobro"
        component={LotesCargosAutomaticos}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/reportes/blackpass"
        component={Blackpass}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/reportes/adeudos"
        component={AdeudosPage}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/reportes/reactivaciones"
        component={ReactivacionesReporte}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/reportes/ventas-portal"
        component={VentasPortalReporte}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/reportes/directorio-membresias"
        component={PartnerMembreshipDirectoryReport}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/reportes/traspasos-membresias"
        component={PartnerMembreshipTransfersReport}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/reportes/comisiones-deportiva-pendientes"
        component={ComisionesDeportivaPendientesPage}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/reportes/clases-sin-comision"
        component={ClaseSinComisionPage}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/reportes/membresias-referidas-extranjero"
        component={ReferidosLicenciasReporteContainer}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/reportes/bloqueo-de-nota-de-credito"
        component={NotaCreditoBloqueoContainer}
        display={DISPLAYS_CODES.PASS}
      />

      <PrivateRoute
        path="/reportes/reportes-utilitarios"
        component={MiscReportsPage}
        display={DISPLAYS_CODES.PASS}
      />

      {getDecoratedRoutes(ComisionesDeportivaCarrusel)}

      <PublicRoute path="/error-page" component={errorPage} />
      <PublicRoute path="*" component={() => <RouteNotFoundError />} />
    </Switch>
  </div>
);

const MobileRoutes = () => (
  <div>
    <Helmet
      titleTemplate="SPORTSWORLD"
      defaultTitle="SPORTSWORLD"
      link={[
        { rel: "canonical", href: "https://www.mahisoft.com" },
        { rel: "icon", href: "tile-sw.png" }
      ]}
    />
    <Switch>
      <PublicRoute exact path="/mit/payment" component={LineaPagoPublicoPage} />
      <PublicRoute
        exact
        path="/quejas/encuesta-seguimiento"
        component={SurveyFollowUp}
      />
      <PublicRoute path="*" component={() => <RouteMobileError />} />
    </Switch>
  </div>
);

const isMobile = () => {
  const agent = navigator.userAgent || navigator.vendor || window["opera"];
  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
      agent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      agent.substr(0, 4)
    )
  );
};

const ClientRoutes = () => {
  if (isMobile()) {
    return (
      <BrowserRouter>
        <MobileRoutes />
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

const ServerRoutes = ({ context, location }) => (
  <StaticRouter context={context} location={location}>
    <Routes />
  </StaticRouter>
);

(ServerRoutes as any).propTypes = {
  location: PropTypes.string.isRequired,
  context: PropTypes.shape(ContextType).isRequired
};

export default ClientRoutes;
export { ServerRoutes, restClient };
