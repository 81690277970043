import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Button, Row, Col, Container, Label, Input } from "reactstrap";
import ModalFrame from "../../../toolkit/modalFrame";
import BaseInput from "../../../toolkit/baseInput";
import { getMonthsWithoutInterestsByBankId } from "../../actions/banks";
import { handleRequestError } from "../../../utils/helpers";

interface Props {
  isOpen: boolean;
  amount: number;
  hasMembershipsOrTokenizationProducts: boolean;
  hasMaintenancesProducts: boolean;
  showTokenizationControl: boolean;
  generarLineaPago: (tokenizar: boolean, msi: number) => any;
  cancelar: () => any;
}

interface State {
  check: boolean;
  msi: any;
  msiCatalog: any;
}

class LineaPagoModal extends React.Component<Props, State> {
  state = {
    check: false,
    msi: 0,
    msiCatalog: []
  };

  componentDidMount() {
    getMonthsWithoutInterestsByBankId(342)
      .then(({ data }) => {
        this.setState({ msiCatalog: [...data] });
      })
      .catch(handleRequestError);
  }

  componentWillUnmount() {
    this.setState({ check: false, msi: 0 });
  }

  getMsiCatalog() {
    const total = this.props.amount || 0;

    const _defOptions = [{ value: null, label: "Contado" }];

    let options = [];
    if (!this.props.hasMaintenancesProducts) {
      options = (this.state.msiCatalog || [])
        .filter(x => (x.monto || 0) <= total)
        .map(x => {
          return { value: x.meses, label: x.meses };
        });
    }

    return [..._defOptions, ...options];
  }

  render() {
    return (
      <Container className="p-0">
        <ModalFrame
          title={"Generar línea de pago"}
          isOpen={this.props.isOpen}
          size="md"
        >
          <div>
            Se creará una Línea de pago con todos los items seleccionados.
            ¿Desea continuar?
          </div>
          <br />
          <Row className={`${s.customDistancia}`}>
            {!!this.props.showTokenizationControl && (
              <Col>
                <div>
                  <Label className={s.label} for="horaInicial">
                    Tokenizar tarjeta para envío a CAT
                  </Label>
                </div>
                <div className="pl-4 mt-2">
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={
                        !!this.props.hasMembershipsOrTokenizationProducts
                          ? true
                          : this.state.check
                      }
                      onChange={e => {
                        this.setState({ check: e.target.checked });
                      }}
                      disabled={
                        !!this.props.hasMembershipsOrTokenizationProducts
                      }
                    />
                    Registrar medio de pago principal al pagar la línea de pago
                  </Label>
                </div>
              </Col>
            )}

            <Col>
              <BaseInput
                type="select"
                label="Meses sin intereses"
                name="msiValue"
                id="msiValue"
                options={this.getMsiCatalog()}
                value={this.state.msi}
                onChange={e => {
                  const value = e.target.value;
                  this.setState({ msi: value });
                }}
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs="6">
              <BaseInput
                label={"Monto"}
                name="lpAmoutn"
                id="lpAmoutn"
                type="text"
                placeholder="Monto"
                value={this.props.amount || 0}
                disabled={true}
              />
            </Col>
          </Row>
          <hr />
          <Row className={`${s.customDistancia}`}>
            <Col xs={6} md={6} lg={6}>
              <Button
                block
                color="secondary"
                onClick={() => {
                  if (this.props.generarLineaPago) {
                    const tokenizar = !!this.props
                      .hasMembershipsOrTokenizationProducts
                      ? true
                      : this.state.check;
                    const msi = this.state.msi || 0;
                    this.props.generarLineaPago(tokenizar, msi);
                    this.setState({ check: false });
                  }
                }}
                disabled={
                  (this.props.amount || 0) <= 0 ||
                  (this.state.msiCatalog || []).length <= 0
                }
              >
                GENERAR LÍNEA
              </Button>
            </Col>

            <Col xs={6} md={6} lg={6}>
              <Button
                block
                color="primary"
                onClick={() => {
                  if (this.props.cancelar) {
                    this.setState({ check: false, msi: 0 });
                    this.props.cancelar();
                  }
                }}
              >
                CANCELAR
              </Button>
            </Col>
          </Row>
        </ModalFrame>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(LineaPagoModal));
