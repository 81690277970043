import {
  GetCmsClasesAsignables,
  GetCmsProductosOperacion,
  PostCmsAsignacion
} from "../../../reducers/comisionesV2";

export interface BAsignacionStore
  extends GetCmsClasesAsignables,
    GetCmsProductosOperacion,
    PostCmsAsignacion {}

export const bAsignacionStore: (state) => BAsignacionStore = state => {
  const { comisionesV2 } = state;
  return {
    cmsClasesAsignables: comisionesV2.cmsClasesAsignables,
    gettingCmsClasesAsignables: comisionesV2.gettingCmsClasesAsignables,
    gettingCmsClasesAsignablesError:
      comisionesV2.gettingCmsClasesAsignablesError,

    cmsProductosOperacion: comisionesV2.cmsProductosOperacion,
    gettingCmsProductosOperacion: comisionesV2.gettingCmsProductosOperacion,
    gettingCmsProductosOperacionError:
      comisionesV2.gettingCmsProductosOperacionError,

    postingCmsAsignacion: comisionesV2.postingCmsAsignacion,
    postingCmsAsignacionError: comisionesV2.postingCmsAsignacionError
  };
};
