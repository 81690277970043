import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Button, Input, Label, Row, Col, Container } from "reactstrap";
import ModalFrame from "../../../../toolkit/modalFrame";
import BaseInput from "../../../../toolkit/baseInput";
import DatePicker from "../../../components/common/DatePicker";
import moment from "moment";
import { OPTIONS_FORMA_PAGO } from "../../../../utils/constants";
import MexicanCurrency from "../../common/currency";

const formatCurrency = amount => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  }).format(amount || 0);
};

interface Props {
  selPostOpen: boolean;
  toggle: () => any;
  savePaidComplement: (data: object, uuid: string) => any;
  data: object;
  billing: boolean;
  savingReceipt: boolean;
}

interface State {
  searchFields: {
    fechaPago: string;
    horaPago: string;
    formaDePago: string;
    banEmPago: string;
    cuentaRetiro: string;
    banRecPago: string;
    cuentaDeposito: string;
    usoCfdiReceptor: string;
    domicilioFiscalReceptor: string;
    regimenFiscalReceptor: string;
  };
  setValue: boolean;
  setCorrectTime: boolean;
  arrayTime: string[];
  permission99: string[];
  cantidadTotal: string;
  saveData: boolean;
}

const formatTime = (dateTime?) =>
  moment(dateTime)
    .utc()
    .format("YYYY-MM-DD");

class PayComplementGenerationModal extends React.Component<Props, State> {
  state = {
    searchFields: {
      fechaPago: "",
      horaPago: "",
      formaDePago: "",
      banEmPago: "",
      cuentaRetiro: "",
      banRecPago: "",
      cuentaDeposito: "",
      usoCfdiReceptor: "",
      domicilioFiscalReceptor: "",
      regimenFiscalReceptor: ""
    },
    setValue: true,
    setCorrectTime: false,
    arrayTime: [],
    permission99: [],
    cantidadTotal: 0,
    saveData: false
  };

  componentDidMount() {
    let data99 = OPTIONS_FORMA_PAGO.filter(value => value != "POR_DEFINIR_99");
    this.setState({ permission99: data99 });
  }

  componentDidUpdate() {
    const {
      importeFactura,
      usoCfdiReceptor,
      domicilioFiscalReceptor,
      regimenFiscalReceptor
    } = this.props.data || {};

    if (
      this.state.setValue &&
      !!importeFactura &&
      !!usoCfdiReceptor &&
      !!domicilioFiscalReceptor &&
      !!regimenFiscalReceptor
    ) {
      const { searchFields } = this.state;
      searchFields.usoCfdiReceptor = usoCfdiReceptor;
      searchFields.domicilioFiscalReceptor = domicilioFiscalReceptor;
      searchFields.regimenFiscalReceptor = regimenFiscalReceptor;

      this.setState({
        cantidadTotal: importeFactura,
        searchFields,
        setValue: false
      });
    }

    if (this.props.billing && this.state.saveData) {
      this.setState({ saveData: false });
      this.clearData();
    }
  }

  clearData = () => {
    let data = {
      fechaPago: "",
      horaPago: "",
      formaDePago: "",
      banEmPago: "",
      cuentaRetiro: "",
      banRecPago: "",
      cuentaDeposito: "",
      usoCfdiReceptor: "",
      domicilioFiscalReceptor: "",
      regimenFiscalReceptor: ""
    };
    this.setState({ searchFields: data, setValue: true });
    this.props.toggle();
  };

  handleInitialDateChanged = () => {
    return dateTime => {
      let valSearchFields = this.state.searchFields;
      valSearchFields.fechaPago = formatTime(dateTime);
      this.setState({
        searchFields: valSearchFields
      });
    };
  };

  disabledButton = () => {
    if (!!this.props.savingReceipt) {
      return true;
    }

    let valReturn = true;
    let valRet = { ...this.state.searchFields };
    const { referencia, saldoInsoluto } = this.props.data || {};
    if (
      this.state.setCorrectTime &&
      valRet.fechaPago.length > 0 &&
      valRet.horaPago.length > 0 &&
      (referencia || "").length > 0 &&
      parseInt(saldoInsoluto || "0") > 0 &&
      valRet.formaDePago.length > 0 &&
      valRet.cuentaRetiro.length >= 11 &&
      valRet.cuentaDeposito.length >= 11 &&
      valRet.cuentaDeposito.length >= 11 &&
      !!valRet.usoCfdiReceptor &&
      !!valRet.domicilioFiscalReceptor &&
      !!valRet.regimenFiscalReceptor
    ) {
      valReturn = false;
    }
    return valReturn;
  };

  handleTimeChanged = () => {
    return event => {
      const regex = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/gm;
      const value = event.target.value;
      let m;
      if ((m = regex.exec(value)) !== null && m[0] != null) {
        this.setState(state => {
          const searchFields = {
            ...state.searchFields,
            ["horaPago"]: value
          };
          return { ...state, searchFields, setCorrectTime: true, arrayTime: m };
        });
      } else {
        this.setState(state => {
          const searchFields = {
            ...state.searchFields,
            ["horaPago"]: value
          };
          return {
            ...state,
            searchFields,
            setCorrectTime: false,
            arrayTime: []
          };
        });
      }
    };
  };

  onChangeSearchFieldsFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const searchFields = {
          ...state.searchFields,
          [fieldName]: value
        };
        return { ...state, searchFields };
      });
    };
  };

  handleSave = () => {
    return event => {
      let hora =
        this.state.arrayTime[1].toString().length == 1
          ? "0" + this.state.arrayTime[1]
          : this.state.arrayTime[1];
      let min =
        this.state.arrayTime[2].length == 1
          ? "0" + this.state.arrayTime[2]
          : this.state.arrayTime[2];
      let seg = "00";
      let timeCompleted =
        this.state.searchFields.fechaPago + "T" + hora + ":" + min + ":" + seg;

      const { referencia, saldoInsoluto, numeroParcialidad, facturaUUID } =
        this.props.data || {};

      let dataToSend = {
        fechaPago: timeCompleted,
        referencia: referencia || "",
        importe: saldoInsoluto || 0,
        formaDePago: this.state.searchFields.formaDePago,
        numeroParcialidad: parseInt(numeroParcialidad || 1),
        bancoEmisorPago: this.state.searchFields.banEmPago,
        cuentaRetiro: this.state.searchFields.cuentaRetiro,
        bancoReceptorPago: this.state.searchFields.banRecPago,
        cuentaDeposito: this.state.searchFields.cuentaDeposito,
        usoCfdiReceptor: this.state.searchFields.usoCfdiReceptor,
        domicilioFiscalReceptor: this.state.searchFields
          .domicilioFiscalReceptor,
        regimenFiscalReceptor: this.state.searchFields.regimenFiscalReceptor
      };
      this.props.savePaidComplement(dataToSend, facturaUUID || "");
      this.setState({ saveData: true });
    };
  };

  render() {
    return (
      <Container className="p-0">
        <ModalFrame
          title={`Formulario Recibo de Pago`}
          isOpen={this.props.selPostOpen}
          toggle={this.clearData}
          size="lg"
        >
          <Row className={s.customDistanciaCheck}>
            <Col xs={4} md={4} lg={4}>
              <DatePicker
                label="Fecha en la que se realizó el pago"
                name="fechaPago"
                id="fechaPago"
                selected={this.state.searchFields.fechaPago}
                onChange={this.handleInitialDateChanged()}
              />
            </Col>
            <Col xs={4} md={4} lg={4}>
              <BaseInput
                label={"Hora de pago"}
                name="horaPago"
                type="text"
                id="horaPago"
                placeholder="00:00"
                value={this.state.searchFields.horaPago}
                // disabled={}
                onChange={this.handleTimeChanged()}
              />
            </Col>
            <Col xs={4} md={4} lg={4}>
              <BaseInput
                label="Forma de Pago"
                name="formaDePagoPagoProductos"
                type="select"
                id="formaDePagoPagoProductos"
                placeholder="Forma de Pago"
                options={[
                  { value: "", label: "Seleccionar forma de pago" },
                  { value: "EFECTIVO", label: "EFECTIVO" }
                ].concat(
                  this.state.permission99.map(option => ({
                    value: option,
                    label: option
                  }))
                )}
                value={this.state.searchFields.formaDePago}
                onChange={this.onChangeSearchFieldsFactory("formaDePago")}
              />
            </Col>
          </Row>
          <Row className={s.customDistanciaCheck}>
            <Col xs={4} md={4} lg={4}>
              <BaseInput
                label={"Banco emisor de pago"}
                name="banEmPago"
                type="text"
                id="banEmPago"
                placeholder="Banco emisor de pago"
                value={this.state.searchFields.banEmPago}
                // disabled={}
                onChange={this.onChangeSearchFieldsFactory("banEmPago")}
              />
            </Col>
            <Col xs={4} md={4} lg={4}>
              <BaseInput
                label={"Cuenta de retiro"}
                name="cuentaRetiro"
                type="text"
                id="cuentaRetiro"
                placeholder="Cuenta de retiro"
                value={this.state.searchFields.cuentaRetiro}
                // disabled={}
                onChange={this.onChangeSearchFieldsFactory("cuentaRetiro")}
              />
            </Col>
            <Col xs={4} md={4} lg={4}>
              <BaseInput
                label={"Banco receptor de pago"}
                name="banRecPago"
                type="text"
                id="banRecPago"
                placeholder="Banco receptor de pago"
                value={this.state.searchFields.banRecPago}
                // disabled={}
                onChange={this.onChangeSearchFieldsFactory("banRecPago")}
              />
            </Col>
          </Row>
          <Row className={s.customDistanciaCheck}>
            <Col xs={4} md={4} lg={4}>
              <BaseInput
                label={"Cuenta de depósito"}
                name="cuentaDeposito"
                type="text"
                id="cuentaDeposito"
                placeholder="Cuenta de depósito"
                value={this.state.searchFields.cuentaDeposito}
                // disabled={}
                onChange={this.onChangeSearchFieldsFactory("cuentaDeposito")}
              />
            </Col>

            <Col xs={4} md={4} lg={4}>
              <BaseInput
                label={"* Uso de CFDI Receptor"}
                id="usoCfdiReceptor"
                name="usoCfdiReceptor"
                type="text"
                placeholder="Uso de CFDI Receptor"
                value={this.state.searchFields.usoCfdiReceptor}
                onChange={this.onChangeSearchFieldsFactory("usoCfdiReceptor")}
              />
            </Col>
            <Col xs={4} md={4} lg={4}>
              <BaseInput
                label={"* Domicilio fiscal Receptor"}
                id="domicilioFiscalReceptor"
                name="domicilioFiscalReceptor"
                type="text"
                placeholder="Domicilio fiscal Receptor"
                value={this.state.searchFields.domicilioFiscalReceptor}
                onChange={this.onChangeSearchFieldsFactory(
                  "domicilioFiscalReceptor"
                )}
              />
            </Col>
            <Col xs={4} md={4} lg={4}>
              <BaseInput
                label={"* Régimen fiscal Receptor"}
                id="regimenFiscalReceptor"
                name="regimenFiscalReceptor"
                type="text"
                placeholder="Régimen fiscal Receptor"
                value={this.state.searchFields.regimenFiscalReceptor}
                onChange={this.onChangeSearchFieldsFactory(
                  "regimenFiscalReceptor"
                )}
              />
            </Col>

            <Col xs={4} md={4} lg={4}>
              <BaseInput
                label={"Num de operación/referencia"}
                name="referencia"
                type="text"
                id="referencia"
                placeholder="Num de operación/referencia"
                value={(this.props.data || {}).referencia || ""}
                disabled={true}
                onChange={this.onChangeSearchFieldsFactory("referencia")}
              />
            </Col>
          </Row>
          <Row className={s.customDistanciaCheck}>
            <Col xs={4} md={4} lg={4}>
              <BaseInput
                label={"Importe del saldo anterior"}
                name="importeSaldoAnterior"
                type="text"
                id="importeSaldoAnterior"
                placeholder="Importe del saldo anterior"
                value={formatCurrency(
                  (this.props.data || {}).importeFactura || 0
                )}
                disabled={true}
                onChange={this.onChangeSearchFieldsFactory("importeFactura")}
              />
            </Col>
            <Col xs={4} md={4} lg={4}>
              <BaseInput
                label={"Importe pagado"}
                name="importePagado"
                type="text"
                id="importePagado"
                placeholder="Importe pagado"
                value={formatCurrency((this.props.data || {}).importePago || 0)}
                disabled={true}
                onChange={this.onChangeSearchFieldsFactory("importePago")}
              />
            </Col>
            <Col xs={4} md={4} lg={4}>
              <BaseInput
                label={"Importe insoluto"}
                name="importeInsoluto"
                type="text"
                id="importeInsoluto"
                placeholder="Importe insoluto"
                value={formatCurrency(
                  (this.props.data || {}).saldoInsoluto || 0
                )}
                disabled={true}
                onChange={this.onChangeSearchFieldsFactory("saldoInsoluto")}
              />
            </Col>
          </Row>
          <Row className={s.customDistanciaCheck}>
            <Col xs={4} md={4} lg={4}>
              <BaseInput
                label={"Número de parcialidad"}
                name="numeroParcialidad"
                type="text"
                id="numeroParcialidad"
                placeholder="Número de parcialidad"
                value={(this.props.data || {}).numeroParcialidad || 1}
                disabled={true}
                onChange={this.onChangeSearchFieldsFactory("numeroParcialidad")}
              />
            </Col>
            <Col xs={4} md={4} lg={4}>
              <BaseInput
                label={"Serie y folio de factura pagada"}
                name="folioFactura"
                type="text"
                id="folioFactura"
                placeholder="Serie y folio de factura pagada"
                value={(this.props.data || {}).folioReferencia || ""}
                disabled={true}
                // onChange={this.onChangeSearchFieldsFactory("folioFactura")}
              />
            </Col>
            <Col xs={4} md={4} lg={4}>
              <BaseInput
                label={"Folio fiscal factura Pagada (UUID)"}
                name="UUID"
                type="text"
                id="UUID"
                placeholder="Folio fiscal factura Pagada (UUID)"
                value={(this.props.data || {}).facturaUUID || ""}
                disabled={true}
                // onChange={this.onChangeSearchFieldsFactory("facturaUUID")}
              />
            </Col>
          </Row>
          <Row className={`${s.customDistancia} mt-3 mb-3`}>
            <Col xs={4} md={4} lg={4}>
              <Label className={s.label} for="horaInicial">
                {`Monto total de factura origen:    `}
                <MexicanCurrency quantity={this.state.cantidadTotal} />
              </Label>
            </Col>
          </Row>
          <Row className={`${s.customDistancia}`}>
            <Col xs={6} md={6} lg={6}>
              <Button
                block
                color="secondary"
                onClick={this.handleSave()}
                disabled={this.disabledButton()}
              >
                GENERAR RECIBO DE PAGO
              </Button>
            </Col>
            <Col xs={6} md={6} lg={6}>
              <Button
                block
                color="secondary"
                onClick={this.clearData}
                disabled={!!this.props.savingReceipt}
              >
                CANCELAR
              </Button>
            </Col>
          </Row>
        </ModalFrame>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(PayComplementGenerationModal));
