import {
  GetCmsClasesAsignadas,
  PutCmsClasesImparticion
} from "../../../reducers/comisionesV2";

export interface CImparticionStore
  extends GetCmsClasesAsignadas,
    PutCmsClasesImparticion {}

export const cImparticionStore: (state) => CImparticionStore = state => {
  const { comisionesV2 } = state;
  return {
    cmsClasesAsignadas: comisionesV2.cmsClasesAsignadas,
    gettingCmsClasesAsignadas: comisionesV2.gettingCmsClasesAsignadas,
    gettingCmsClasesAsignadasError: comisionesV2.gettingCmsClasesAsignadasError,

    puttingCmsClasesImparticion: comisionesV2.puttingCmsClasesImparticion,
    puttingCmsClasesImparticionError:
      comisionesV2.puttingCmsClasesImparticionError
  };
};
