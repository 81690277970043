import React, { Component } from "react";
import { ReduxConnect, Router } from "../../../../toolkit/decorators/Artifact";
import { dAutorizacionStore, DAutorizacionStore } from "./DAutorizacion.store";
import {
  dAutorizacionDispatcher,
  DAutorizacionDispatcher
} from "./DAutorizacion.dispatcher";
import DAutorizacionComponent from "../../../components/comisionesDeportivaV2Carrusel/steps/DAutorizacion/DAutorizacion";

interface Props extends DAutorizacionStore, DAutorizacionDispatcher {}
interface State {}

@Router()
@ReduxConnect(dAutorizacionStore, dAutorizacionDispatcher)
export default class DAutorizacionContainer extends Component<Props, State> {
  render() {
    return <DAutorizacionComponent {...this.props} />;
  }
}
