import React from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import equal from "lodash.isequal";
import TimePicker from "react-times";
import moment from "moment";
import {
  errorNotification,
  ERROR_TOTAL_DATA
} from "../../../utils/notifications";
import SelectFilter from "../common/SelectFilter";
import IAction from "../../../types/IAction";
import { Store } from "redux";
import { getEmployeesBasicCatalog } from "../../actions/catalogs";

interface Props {
  editEvent: any;
  isOpen: boolean;
  toggleModal: () => IAction;
  newSessionId: number;
  postSession: (session: object) => IAction;
  editSession: (session: object) => IAction;
}

interface State {
  dataHandler: any;
  instructor: object;
  hora: object;
  sellers: any[];
}
export enum ESTATUS_CLASE {
  ASIGNADO = "ASIGNADO",
  CANCELADO = "CANCELADO",
  FALTO_INSTRUCTOR = "FALTO_INSTRUCTOR",
  FALTO_USUARIO = "FALTO_USUARIO",
  IMPARTIDO = "IMPARTIDO",
  PAGADA = "PAGADA"
}

const getEmptyTimeObject = () => {
  const now = moment().add(1, "minute");
  return {
    hour: now.format("HH"),
    minute: now.format("mm")
  };
};

const estatusClase = [
  { values: null, label: "Selecciona estatus" },
  { values: ESTATUS_CLASE.CANCELADO, label: "Cancelado" },
  { values: ESTATUS_CLASE.FALTO_INSTRUCTOR, label: "Falto instructor" },
  { values: ESTATUS_CLASE.FALTO_USUARIO, label: "Falto usuario" },
  { values: ESTATUS_CLASE.IMPARTIDO, label: "impartido" }
];
class EditSessionModal extends React.Component<Props, State> {
  state = {
    instructor: null,
    hora: getEmptyTimeObject(),
    dataHandler: {
      fechaClase: null,
      horaClase: null,
      instructorId: null,
      estatus: null
    },
    sellers: []
  };

  async componentDidMount() {
    const sellers = await getEmployeesBasicCatalog(false, true);
    this.setState({ sellers });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !!this.props.editEvent &&
      !equal(this.props.editEvent, prevProps.editEvent)
    ) {
      this.updateModal();
    }

    if (this.state.sellers && !prevState.sellers) {
      this.updateModal();
    }
  }

  clearBeforeClose = () => {
    let dataHandler = {
      fechaClase: null,
      horaClase: null,
      instructorId: null,
      estatus: null
    };
    this.setState({
      instructor: null,
      hora: getEmptyTimeObject(),
      dataHandler
    });
    this.props.toggleModal();
  };

  findInstructor = val => {
    if (!this.state.sellers || !this.state.sellers.find) {
      return {
        label: `Cargando instructores...`,
        value: val
      };
    }

    let item = this.state.sellers.find(element => element.userId == val);
    return {
      label: item
        ? `${item.name} ${item.firstSurname} ${item.secondSurname}`
        : `Instructor ${val} inactivo`,
      value: val
    };
  };

  updateModal() {
    const { editEvent } = this.props;
    this.setState({
      dataHandler:
        !!editEvent && !!editEvent.informacionClase
          ? editEvent.informacionClase
          : editEvent,
      instructor:
        !!editEvent &&
        !!editEvent.informacionClase &&
        !!editEvent.informacionClase.instructorId
          ? this.findInstructor(editEvent.informacionClase.instructorId)
          : null
    });
  }

  handleInput = e => {
    let newDataHandler = { ...this.state.dataHandler };
    newDataHandler[e.target.name] = e.target.value;
    this.setState({ dataHandler: { ...newDataHandler } });
  };

  handleSelect = e =>
    this.setState({
      dataHandler: {
        ...this.state.dataHandler,
        instructorId: e.value
      },
      instructor: e
    });

  handleSubmit = () => {
    const { editEvent, postSession, editSession, newSessionId } = this.props;
    const { dataHandler } = this.state;
    if (
      dataHandler &&
      dataHandler.fechaClase != null &&
      dataHandler.horaClase != null &&
      dataHandler.instructorId != null
    ) {
      if (!!editEvent) {
        const store = window["_GLOBAL_REDUX_STORE"] as Store<any>;
        let editingSession = false;

        // Listener ejecutado al actualizar la sesión
        const unsuscriber = store.subscribe(() => {
          const sportsProductsState = (store.getState() as any).sportsProducts;

          if (sportsProductsState.editSession) {
            editingSession = true;
          } else if (editingSession) {
            editingSession = false;
            editEvent.handleChangeModal && editEvent.handleChangeModal();
            this.clearBeforeClose();
            unsuscriber();
          }
        });

        editSession(dataHandler);
      } else {
        postSession({
          ...dataHandler,
          productoInstanciaId: newSessionId,
          estatus: ESTATUS_CLASE.ASIGNADO
        });

        this.clearBeforeClose();
      }
    } else {
      errorNotification(ERROR_TOTAL_DATA);
    }
  };

  setTime = time => {
    let newDataHandler = this.state.dataHandler;
    newDataHandler.horaClase = `${time.hour}:${time.minute}`;
    this.setState({ dataHandler: newDataHandler });
  };

  render() {
    const { editEvent, isOpen } = this.props;
    const { sellers } = this.state;
    const { dataHandler, instructor } = this.state;
    const shouldDisable = !!editEvent && !!editEvent.informacionClase;

    if (!dataHandler) {
      return <></>;
    }

    return (
      <Modal isOpen={isOpen} toggle={this.clearBeforeClose}>
        <ModalHeader toggle={this.clearBeforeClose}>
          {!!editEvent ? "Editar Clase" : "Nueva Clase"}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="fechaClase">Fecha</Label>
            <Input
              type="date"
              name="fechaClase"
              id="fechaClase"
              disabled={shouldDisable}
              placeholder="Fecha"
              value={dataHandler && dataHandler.fechaClase}
              onChange={this.handleInput}
            />
          </FormGroup>
          <FormGroup>
            <Label for="horaClase">Hora</Label>
            <TimePicker
              timeMode="24"
              onTimeChange={time => this.setTime(time)}
              time={
                dataHandler && dataHandler.horaClase
                  ? dataHandler.horaClase
                  : "0:00"
              }
              theme="classic"
              timeConfig={{
                from: 6,
                to: 23,
                step: 15,
                unit: "minutes"
              }}
              withoutIcon
            />
          </FormGroup>
          <FormGroup>
            <SelectFilter
              label="Selecciona instructor"
              name="instructorId"
              type="select"
              id="instructorId"
              placeholder="Selecciona instructor"
              value={instructor}
              isMulti={false}
              options={
                sellers
                  ? sellers.map(item => ({
                      label: `${item.label}`,
                      value: item.userId
                    }))
                  : null
              }
              onChange={this.handleSelect}
              loading={false}
              isAsync={false}
              disabled={!sellers}
            />
          </FormGroup>
          <FormGroup>
            <Label for="estatus">Estatus</Label>
            {(!!editEvent &&
              !!editEvent.informacionClase &&
              editEvent.informacionClase.estatus === ESTATUS_CLASE.ASIGNADO) ||
            (!!editEvent && editEvent.estatus === ESTATUS_CLASE.ASIGNADO) ? (
              <Input
                type="select"
                name="estatus"
                id="estatus"
                placeholder="Selecciona opción"
                value={dataHandler.estatus}
                onChange={this.handleInput}
              >
                {estatusClase.map(opt => (
                  <option key={opt.values} value={opt.values}>
                    {opt.label}
                  </option>
                ))}
              </Input>
            ) : (
              <Input
                type="text"
                name="estatus"
                id="estatus"
                disabled
                value={
                  !!editEvent ? dataHandler.estatus : ESTATUS_CLASE.ASIGNADO
                }
              />
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleSubmit}>
            Guardar
          </Button>{" "}
          <Button color="secondary" onClick={this.clearBeforeClose}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default EditSessionModal;
