import React, { Component } from "react";
import {
  RouterProps,
  Styled
} from "../../../../../toolkit/decorators/Artifact";
import { AVentaDispatcher } from "../../../../containers/comisionesDeportivaV2Carrusel/steps/AVenta.dispatcher";
import { AVentaStore } from "../../../../containers/comisionesDeportivaV2Carrusel/steps/AVenta.store";
import { Container } from "reactstrap";
import FormBuilder from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder";
import { Inputs, Buttons, formConfig } from "./AVenta.form";
import { MemoTable } from "./AVenta.table";
import DeportivaVenta from "../../../../../types/DeportivaVenta";
import { deleteEmptyProps } from "../../../../../toolkit/utils/filter-utils";
import { errorNotification } from "../../../../../utils/notifications";
import s from "../../styles.scss";
import ITypeSelectBuilder from "../../../../../toolkit/baseForms2/iTypeSelectBuilder/ITypeSelectBuilder";
import FormBuilderModal from "../../../common/formBuilderModal/formBuilderModal";
import { formConfigEmpleado } from "./AVentaEmpleado.form";

interface Props extends AVentaDispatcher, AVentaStore, RouterProps {}

interface State {
  downloading: boolean;
  waiting: boolean;
  filters: Inputs & {
    page: number;
    size: number;
  };

  // Actualización de empleado
  rowPopup: DeportivaVenta;
  empleadoPopupOpen: boolean;
  empleadoPopupTitle: string;
  empleadoPopupText: string;
  tipoEmpleado: "VENDEDOR" | "COORDINADOR" | "INSTRUCTOR";
}

@Styled(s)
export default class AVentaComponent extends Component<Props, State> {
  state = {
    downloading: false,
    waiting: false,
    filters: {
      page: 0,
      size: 50
    } as any,

    // Actualización de empleado
    rowPopup: null as DeportivaVenta,
    empleadoPopupOpen: false,
    empleadoPopupTitle: "",
    empleadoPopupText: "",
    tipoEmpleado: null as ("VENDEDOR" | "COORDINADOR" | "INSTRUCTOR")
  };

  componentDidMount(): void {
    this.props.getCmsProductosVenta();
    this.fetchData({} as any);
  }

  submit = (values: any) => {
    // Búsqueda de datos en página 1
    this.fetchData({ page: 0 } as any, null, values);
  };

  // Carga de datos para poblar la tabla
  fetchData = async (
    { pageSize: tablePageSize, page: tablePage },
    _?: any,
    formFilters?
  ) => {
    const filters = formFilters || this.state.filters;
    const page = tablePage >= 0 ? tablePage : this.state.filters.page;
    const size = tablePageSize >= 0 ? tablePageSize : this.state.filters.size;
    const idClub = this.props.userContext.clubSessionId;
    const pageableFilters = deleteEmptyProps({
      ...{ idClub, ...filters },
      page,
      size
    });

    this.setState({ filters: pageableFilters });

    const preview = await this.props.getCmsVentas(pageableFilters);
    if (!preview.fulfilled) {
      errorNotification(
        preview.gettingCmsVentasError || "Error al obtener las ventas"
      );
    }
  };

  onExport = () => {
    // TODO: Verificar si se necesita
    // this.setState({ downloading: true });
  };

  onChangeVendedor = (rowPopup: DeportivaVenta) => {
    this.setState({
      rowPopup,
      empleadoPopupOpen: true,
      empleadoPopupTitle: "Actualizar vendedor",
      empleadoPopupText: "Actualizar vendedor",
      tipoEmpleado: "VENDEDOR"
    });
  };

  onChangeCoordinador = (rowPopup: DeportivaVenta) => {
    this.setState({
      rowPopup,
      empleadoPopupOpen: true,
      empleadoPopupTitle: "Actualizar coordinador",
      empleadoPopupText: "Actualizar coordinador",
      tipoEmpleado: "COORDINADOR"
    });
  };

  onChangeInstructor = (rowPopup: DeportivaVenta) => {
    this.setState({
      rowPopup,
      empleadoPopupOpen: true,
      empleadoPopupTitle: "Actualizar instructor",
      empleadoPopupText: "Actualizar instructor",
      tipoEmpleado: "INSTRUCTOR"
    });
  };

  changeEmpleado = async (values: any) => {
    this.setState({ waiting: true });
    const tipoEmpleado = this.state.tipoEmpleado;
    const idInstancia = this.state.rowPopup.idProductoInstancia;

    const body = {
      idEmpleado: values.idEmpleado,
      ignorarPuesto: values.ignorarPuesto === "true",
      razonIgnorarPuesto: values.razonIgnorarPuesto
    };

    let preview;
    let error = "No se pudo acutalizar al empleado, intente de nuevo por favor";
    switch (tipoEmpleado) {
      case "VENDEDOR":
        preview = await this.props.putCmsVendedor(idInstancia, body);
        error = preview.puttingCmsVendedorError || error;
        break;
      case "COORDINADOR":
        preview = await this.props.putCmsCoordinador(idInstancia, body);
        error = preview.puttingCmsCoordinadorError || error;
        break;
      case "INSTRUCTOR":
        preview = await this.props.putCmsInstructorPrincipal(idInstancia, body);
        error = preview.puttingCmsInstructorPrincipalError || error;
        break;
      default:
        return;
    }

    if (!preview.fulfilled) {
      this.setState({ waiting: false });
      errorNotification(error);
    } else {
      this.setState({ waiting: false, empleadoPopupOpen: false });
      this.fetchData({} as any);
    }
  };

  render() {
    return (
      <Container>
        <FormBuilderModal
          isOpen={this.state.empleadoPopupOpen}
          title={this.state.empleadoPopupTitle}
          message={this.state.empleadoPopupText}
          ok={this.changeEmpleado}
          cancel={() => this.setState({ empleadoPopupOpen: false })}
          formConfig={formConfigEmpleado}
          size={"md"}
        />

        <FormBuilder<Inputs, Buttons>
          config={formConfig}
          submit={this.submit}
          processing={this.props.gettingCmsVentas}
        >
          {/* Selector de productos */}
          <ITypeSelectBuilder<Inputs>
            name="idProducto"
            data={this.props.cmsProductosVenta || []}
            mapOption={option => ({
              value: option.idProducto,
              label: option.producto
            })}
            isLoading={this.props.gettingCmsProductosVenta}
          />
        </FormBuilder>

        <hr className="row" />

        <MemoTable
          page={this.props.cmsVentas || ({} as Page<DeportivaVenta>)}
          loading={this.state.downloading || this.props.gettingCmsVentas}
          onFetchData={this.fetchData}
          onExport={this.onExport}
          onChangeVendedor={this.onChangeVendedor}
          onChangeCoordinador={this.onChangeCoordinador}
          onChangeInstructor={this.onChangeInstructor}
        />
      </Container>
    );
  }
}
