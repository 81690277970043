import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  Container,
  Col,
  Button,
  Row,
  UncontrolledTooltip,
  Form
} from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import DatePicker from "../common/DatePicker";
import s from "./styles.scss";
import {
  faFilePdf,
  faEnvelope,
  faCashRegister,
  faFile,
  faFileAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MexicanCurrency from "../common/currency";
import ModalFrame from "../../../toolkit/modalFrame";
import ReactTable from "react-table";
import EmailBill from "./EmailBill";
import PayComplementGenerationModal from "./payComplGenerationModal/index";
import {
  openPaymentComplementXml,
  savePaidComplementRequest
} from "../../actions/billing";
import { handleRequestError } from "../../../utils/helpers";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { getSalesPayableRequest } from "../../actions/reports";
import BaseButtonWithIconAndTooltip from "../../../toolkit/baseButtonWithIconAndTooltip";

interface Props {
  sendMovementEmail: (id: number, email: string) => any;
  toggleBill: (storageId: number) => any;
  /* getSalesPayable: (
    page: string,
    size: string,
    fechaDesde: string,
    fechaHasta: string,
    folio: string
  ) => IAction; */
  //reportSalesPayable: any;
  timbradoUUID: string;
  savePaidComplement: (data: object, uuid: string) => any;
  billing: boolean;
}

interface State {
  filters: any;
  idModalEmail: number;
  modalEmail: boolean;
  selCreditNoteGeneration: boolean;
  modalComplemento: boolean;
  dataModalComplemento: any;
  accionModalComplemento: string;
  data: object;
  savingReceipt: boolean;
  successfullSavedReceipt: boolean;
}

const MODAL_COMPLEMENTO_ACTION = {
  TIMBRAR_COMPLEMENTO: "TIMBRAR_COMPLEMENTO",
  GENERAR_RECIBO_PAGO: "GENERAR_RECIBO_PAGO"
};

const START_DATE_FIELDNAME = "fechaInicio";
const END_DATE_FIELDNAME = "fechaFin";

class ListPaymentPluginValidation extends React.Component<Props, State> {
  state = {
    filters: {
      fechaInicio: "",
      fechaFin: "",
      identificador: "",
      page: 0,
      size: 20
    },
    idModalEmail: null,
    modalEmail: false,
    selCreditNoteGeneration: false,
    timbradoUUID: null,
    modalComplemento: false,
    dataModalComplemento: null,
    accionModalComplemento: "",
    data: { content: [], totalPages: 0 },
    savingReceipt: false,
    successfullSavedReceipt: false
  };

  toggleModalComplemento = () => {
    this.setState({
      dataModalComplemento: null,
      modalComplemento: !this.state.modalComplemento
    });
  };

  toggleModalEmail = idModalEmail => {
    this.setState({
      idModalEmail,
      modalEmail: !this.state.modalEmail
    });
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  onChangeFilters = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: value
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">RFC</p>,
      id: 0,
      width: 160,
      className: "text-center",
      accessor: "rfcReceptor",
      Cell: row => {
        return <p className={s.initial}>{row.original.rfcReceptor}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Razón social</p>,
      id: 0,
      width: 160,
      accessor: "razonSocialReceptor",
      Cell: row => {
        return <p className={s.initial}>{row.original.razonSocialReceptor}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Referencia</p>,
      id: 0,
      width: 160,
      className: "text-center",
      accessor: "referencia",
      Cell: row => {
        return <p className={s.initial}>{row.original.referencia}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Folio referencia</p>,
      id: 0,
      width: 160,
      className: "text-center",
      accessor: "folioReferencia",
      Cell: row => {
        return <p className={s.initial}>{row.original.folioReferencia}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Importe factura</p>,
      id: 0,
      width: 160,
      className: "text-center",
      accessor: "importeFactura",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.importeFactura} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Importe pagado</p>,
      id: 0,
      width: 160,
      className: "text-center",
      accessor: "importePago",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency
              quantity={row.original.importePago ? row.original.importePago : 0}
            />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Saldo insoluto</p>,
      id: 0,
      width: 160,
      className: "text-center",
      accessor: "saldoInsoluto",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.saldoInsoluto} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha factura</p>,
      id: 0,
      width: 160,
      accessor: "fechaFactura",
      className: "text-center",
      Cell: row => {
        return (
          <p className={s.initial}>
            {!!row.original.fechaFactura
              ? moment(row.original.fechaFactura).format("YYYY-MM-DD")
              : ""}
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha pago</p>,
      id: 0,
      width: 160,
      accessor: "fechaPago",
      className: "text-center",
      Cell: row => {
        return (
          <p className={s.initial}>
            {!!row.original.fechaPago
              ? moment(row.original.fechaPago).format("YYYY-MM-DD")
              : ""}
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha validación pago</p>,
      id: 0,
      width: 160,
      accessor: "fechaValidacionPago",
      className: "text-center",
      Cell: row => {
        return (
          <p className={s.initial}>
            {!!row.original.fechaValidacionPago
              ? moment(row.original.fechaValidacionPago).format("YYYY-MM-DD")
              : ""}
          </p>
        );
      }
    },
    {
      Header: () => {
        return <p className="font-weight-bold">Opciones</p>;
      },
      id: -300,
      width: 80,
      accessor: "actions",
      Cell: row => {
        return (
          <Container className="p-0">
            {/*Si hay un complemento de pago timbrado, se habilitan las opciones para enviar por correo y para ver pedf del complemento*/}
            <Row className="m-0">
              {!!row.original.complementoPagoId &&
              !!row.original.pagoTimbradoPdfStorageId ? (
                <Col className="px-1">
                  <BaseButtonWithIconAndTooltip
                    id={`email${row.original.facturaId}`}
                    icon={faEnvelope}
                    size="sm"
                    tooltipTitle="Enviar complemento por email"
                    onClick={() =>
                      this.toggleModalEmail(row.original.facturaId)
                    }
                  />
                  <BaseButtonWithIconAndTooltip
                    id={`pdf${row.original.pagoTimbradoPdfStorageId}`}
                    icon={faFilePdf}
                    size="sm"
                    tooltipTitle="Ver pdf complemento"
                    onClick={() =>
                      this.props.toggleBill(
                        row.original.pagoTimbradoPdfStorageId
                      )
                    }
                  />
                  <BaseButtonWithIconAndTooltip
                    id={`xml${row.original.pagoTimbradoPdfStorageId}`}
                    icon={faFileAlt}
                    size="sm"
                    tooltipTitle="Ver xml complemento"
                    onClick={() => {
                      openPaymentComplementXml(
                        row.original.complementoPagoUUID
                      );
                    }}
                  />
                </Col>
              ) : (
                ""
              )}

              {row.original.saldoInsoluto > 0 && !row.original.ventaPagada ? (
                <Col className="px-1">
                  <a
                    id={`reciboPago${row.original.facturaUUID}`}
                    onClick={() =>
                      this.setState({
                        modalComplemento: true,
                        dataModalComplemento: row.original,
                        accionModalComplemento:
                          MODAL_COMPLEMENTO_ACTION.GENERAR_RECIBO_PAGO
                      })
                    }
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faFile}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`reciboPago${row.original.facturaUUID}`}
                    >
                      Generar recibo de pago
                    </UncontrolledTooltip>
                  </a>
                </Col>
              ) : (
                ""
              )}
              {row.original.ventaPagada &&
              !row.original.pagoTimbradoPdfStorageId ? (
                <Col className="px-1">
                  <a
                    id={`complemento${row.original.facturaUUID}`}
                    onClick={() =>
                      this.savePaidComplement(row.original.facturaUUID)
                    }
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faCashRegister}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`complemento${row.original.facturaUUID}`}
                    >
                      Timbrar complemento de pago
                    </UncontrolledTooltip>
                  </a>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Container>
        );
      }
    }
  ];

  getSalesPayableData = async (page, size) => {
    await this.setState({ data: { content: [], totalPages: 0 } });

    const { fechaInicio, fechaFin, identificador } = this.state.filters;

    getSalesPayableRequest(
      page,
      size,
      fechaInicio,
      fechaFin,
      identificador
    ).then(({ data }) => {
      this.setState({ data });
    });
  };

  onFetchData = ({ pageSize: size, page }) => {
    if (!page) {
      return;
    }

    this.getSalesPayableData(page, size);

    const filters = { ...this.state.filters, page, size };
    this.setState({ filters });
  };

  onFetchDataFromButton = ({ pageSize: size, page }) => {
    infoNotification("Consultando datos...");

    this.getSalesPayableData(page, size);

    const filters = { ...this.state.filters, page, size };
    this.setState({ filters });
  };

  //Generar recibo de pago
  savePaidComplementReceipt = (data: object, uuid: string) => {
    this.setState({ savingReceipt: true, successfullSavedReceipt: false });
    infoNotification("Generando recibo de pago...");
    const _data = {
      ...data,
      accion: MODAL_COMPLEMENTO_ACTION.GENERAR_RECIBO_PAGO
    };
    savePaidComplementRequest(_data, uuid)
      .then(async () => {
        successNotification("Recibo de pago creado correctamente");
        await this.setState({
          accionModalComplemento: null,
          savingReceipt: false,
          successfullSavedReceipt: true
        });
        this.onFetchDataFromButton({
          pageSize: this.state.filters.size,
          page: this.state.filters.page
        });
      })
      .catch(err => {
        this.setState({ savingReceipt: false, successfullSavedReceipt: false });
        handleRequestError(err);
      });
  };

  //Timbrar complemento de pago
  savePaidComplement = (uuid: string) => {
    const data = { accion: MODAL_COMPLEMENTO_ACTION.TIMBRAR_COMPLEMENTO };
    savePaidComplementRequest(data, uuid)
      .then(async () => {
        successNotification("Complemento de pago timbrado correctamente");
        await this.setState({
          accionModalComplemento: null
        });
        this.onFetchDataFromButton({
          pageSize: this.state.filters.size,
          page: this.state.filters.page
        });
      })
      .catch(handleRequestError);
  };

  render() {
    return (
      <Container className="p-0 mt-4">
        <ModalFrame
          title={"Mandar Factura por Mail"}
          isOpen={this.state.modalEmail}
          toggle={this.toggleModalEmail}
          size="lg"
        >
          <EmailBill
            id={this.state.idModalEmail}
            sendEmail={this.props.sendMovementEmail}
          />
        </ModalFrame>
        <PayComplementGenerationModal
          savePaidComplement={this.savePaidComplementReceipt}
          data={this.state.dataModalComplemento}
          selPostOpen={this.state.modalComplemento}
          toggle={this.toggleModalComplemento}
          billing={this.state.successfullSavedReceipt}
          savingReceipt={this.state.savingReceipt}
        />
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.onFetchDataFromButton(e);
          }}
        >
          <Row>
            <Col xs={12} md={3} lg={3}>
              <DatePicker
                label="Fecha expedición factura (desde)"
                name={START_DATE_FIELDNAME}
                id={START_DATE_FIELDNAME}
                selected={this.state.filters[START_DATE_FIELDNAME]}
                onChange={this.onChangeDate(START_DATE_FIELDNAME)}
              />
            </Col>
            <Col xs={12} md={3} lg={3}>
              <DatePicker
                label="Fecha expedición factura (hasta)"
                name={END_DATE_FIELDNAME}
                id={END_DATE_FIELDNAME}
                selected={this.state.filters[END_DATE_FIELDNAME]}
                onChange={this.onChangeDate(END_DATE_FIELDNAME)}
              />
            </Col>
            <Col xs={12} md={3} lg={3}>
              <BaseInput
                label={"Folio referencia factura"}
                name="identificador"
                type="text"
                id="identificador"
                value={this.state.filters.identificador}
                onChange={this.onChangeFilters("identificador")}
              />
            </Col>
            <Col xs={12} md={3} lg={3} className="mt-4">
              <Button
                size="sm"
                className={`float-right btn-block`}
                type="submit"
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
        <Row className="mt-2">
          <Col>
            <ReactTable
              manual
              data={this.state.data ? this.state.data.content : []}
              pages={this.state.data ? this.state.data.totalPages || 0 : 0}
              columns={this.columns}
              className="w-100"
              onFetchData={this.onFetchData}
              defaultPageSize={20}
              sortable={false}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ListPaymentPluginValidation));
