import React from "react";
import { Button, TabPane } from "reactstrap";
import { ReduxConnect, Styled } from "../../../toolkit/decorators/Artifact";
import { Preview } from "../../../types/IAction";
import * as actions from "../../actions/expedientes";
import ColoresControl from "../expedientsV2controls/archivo/colores";
import ExpedienteTabla from "../expedientsV2table/index";
import ExpedienteVisor from "../expedientsV2visor/index";
import { Partner } from "./interfaces";
import s from "./tabMembershipDocuments.scss";
import { errorNotification } from "../../../utils/notifications";

// TODO: Mandar al parent de este componente
const mapDispatchToProps = dispatch => ({
  getExpedienteFromMembresia: (idExpediente): Promise<Preview<any>> => {
    return dispatch(actions.getExpedienteFromMembresia(idExpediente));
  },
  postCincelPregeneracion: (idMembresiaSocio): Promise<Preview<any>> => {
    return dispatch(actions.postCincelPregeneracion(idMembresiaSocio));
  }
});

const mapStateToProps = state => {
  const { expedientes } = state;

  return {
    expedienteMembresiaLoading: expedientes.expedienteMembresiaLoading,
    expedienteMembresia: expedientes.expedienteMembresia,
    cincelPregeneracionLoading: expedientes.cincelPregeneracionLoading
  };
};

interface Props {
  expedienteMembresiaLoading?: boolean;
  expedienteMembresia: any;
  getExpedienteFromMembresia?: (idExpediente) => Promise<Preview<any>>;
  postCincelPregeneracion?: (idMembresiaSocio) => Promise<Preview<any>>;
  refreshPartner: () => void;
  cincelPregeneracionLoading?: boolean;
  partner: Partner;
  activeTab: string;
  descripcionTraspaso: any;
  membershipBlocked: boolean;
}

interface State {
  visor: boolean;
  condicionales: boolean;
}

@ReduxConnect(mapStateToProps, mapDispatchToProps)
@Styled(s)
export default class TabDocuments extends React.Component<Props, State> {
  state = {
    visor: false,
    condicionales: false
  };

  isMembershipBlocked = () => {
    return this.props.membershipBlocked || false;
  };

  render() {
    if (this.props.activeTab != "2") {
      return <></>;
    }

    return (
      <TabPane className="my-2" tabId="2">
        {!this.isMembershipBlocked() && (
          <div className="mt-4">
            <ColoresControl />
            <div style={{ width: "400px" }}>
              <Button
                className="primaryButton"
                onClick={async e => {
                  this.setState({ visor: true });
                }}
                size="sm"
                block
              >
                Validar
              </Button>
              <Button
                className="primaryButton"
                onClick={async e => {
                  this.setState({ condicionales: true });

                  try {
                    await actions.putVerificarCondicinalesMembresia(
                      this.props.partner.idMembresiaSocio
                    );
                    this.props.getExpedienteFromMembresia(
                      this.props.partner.idMembresiaSocio
                    );
                    this.setState({ condicionales: false });
                  } catch (err) {
                    this.setState({ condicionales: false });
                    errorNotification(
                      "No se pudieron verificar los documentos"
                    );
                  }
                }}
                size="sm"
                block
                disabled={this.state.condicionales}
              >
                {this.state.condicionales
                  ? "Verificando..."
                  : "Verificar condicionales"}
              </Button>
              <Button
                className="primaryButton"
                onClick={async e => {
                  const preview = await this.props.postCincelPregeneracion(
                    this.props.partner.idMembresiaSocio
                  );

                  if (preview.fulfilled) {
                    this.props.getExpedienteFromMembresia(
                      this.props.partner.idMembresiaSocio
                    );
                    this.setState({ visor: false });
                  }
                }}
                size="sm"
                block
                hidden={
                  this.props.expedienteMembresia.cincel !== false ||
                  !this.props.expedienteMembresia.cincelDeployed
                }
              >
                {this.props.cincelPregeneracionLoading
                  ? "Pregenerando..."
                  : "Pregenerar Cincel"}
              </Button>
            </div>
          </div>
        )}

        <div>
          <ExpedienteTabla
            idMembresiaSocio={this.props.partner.idMembresiaSocio}
            descripcionTraspaso={this.props.partner.descripcionTraspaso}
            membershipBlocked={this.isMembershipBlocked()}
            refreshPartner={this.props.refreshPartner}
          />
        </div>

        {this.renderVisor()}
      </TabPane>
    );
  }

  renderVisor = () => {
    if (this.state.visor) {
      return (
        <ExpedienteVisor
          idMembresiaSocio={this.props.partner.idMembresiaSocio}
          onClose={() => {
            this.props.getExpedienteFromMembresia(
              this.props.partner.idMembresiaSocio
            );
            this.setState({ visor: false });
          }}
        />
      );
    } else {
      return <></>;
    }
  };
}
