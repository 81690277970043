import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import BaseInput from "../../../../../toolkit/baseInput";
import ModalFrame from "../../../../../toolkit/modalFrame";
import { Club } from "../../../../../types-business/Clubs";
import {
  handleRequestError,
  parseArrayToDropdownOptions
} from "../../../../../utils/helpers";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../../../utils/notifications";
import {
  createAndAssignRefferredPartnerMembershipUntimely,
  getPartnerRequest
} from "../../../../actions/partners";
import { Partner } from "../../interfaces";

interface Props {
  partner: Partner;
  idMembresiaSocio: string;
  isOpen: boolean;
  clubs: Club[];
  cancel: () => any;
  success: () => any;
}

interface State {
  clubId: number;
  publicoId: number;
  bankCards: any[];
}

class AssignReferredUntimelyModal extends React.Component<Props, State> {
  state = {
    clubId: null,
    publicoId: null,
    bankCards: []
  };

  componentDidMount() {}

  handleSubmit = membresiaSocioReferidaId => {
    const { idMembresiaSocio } = this.props;

    infoNotification("Asociando membresía socio referida");

    createAndAssignRefferredPartnerMembershipUntimely(
      idMembresiaSocio,
      membresiaSocioReferidaId
    )
      .then(() => {
        successNotification("Referido asociado correctamente");
        this.setState({ clubId: null, publicoId: null });
        this.props.success();
      })
      .catch(handleRequestError);
  };

  handleCancel = () => {
    this.setState({ clubId: null, publicoId: null });
    this.props.cancel();
  };

  search = () => {
    const { clubId, publicoId } = this.state;

    if (!clubId || !publicoId) {
      return;
    }

    const params = { idClub: clubId, idPublico: publicoId, basico: true };
    getPartnerRequest(params)
      .then(({ data }) => {
        const { idMembresiaSocio } = data;
        this.handleSubmit(idMembresiaSocio);
      })
      .catch(err => {
        handleRequestError(err);
      });
  };

  render() {
    return (
      <ModalFrame
        title={`Asignar referido (Extemporáneo)`}
        isOpen={this.props.isOpen}
        toggle={() => {}}
        size={"lg"}
      >
        <Container style={{ width: "90%" }} className="center-block p-0 mb-3">
          <div className="mt-2">
            <Row>
              <Col>
                <BaseInput
                  label="Club"
                  name="assignReferredUntimelyModalclub"
                  id="assignReferredUntimelyModalclub"
                  type="select"
                  value={this.state.clubId}
                  onChange={e => {
                    const value = e.target.value;
                    this.setState({ clubId: value });
                  }}
                  options={parseArrayToDropdownOptions(
                    (this.props.clubs || []).filter(c => !!c.migradoCrm2) || [],
                    "clubId",
                    "nombre"
                  )}
                />
              </Col>
              <Col>
                <BaseInput
                  label={"# Membresia"}
                  name={"assignReferredUntimelyModalidMembresiaSocio"}
                  id={"assignReferredUntimelyModalidMembresiaSocio"}
                  placeholder="Id Membresia Socio"
                  value={this.state.publicoId}
                  size="sm"
                  onChange={e => {
                    const value = e.target.value;
                    this.setState({ publicoId: value });
                  }}
                />
              </Col>
              <Col xs="2">
                <br />
                <Button
                  size="sm"
                  className={`float-right btn-block`}
                  disabled={!this.state.clubId || !this.state.publicoId}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.search();
                  }}
                >
                  Asignar
                </Button>
              </Col>
            </Row>
          </div>

          <Row className="m-4">
            <Col xs="12" md="9"></Col>
            <Col xs="12" md="3">
              <Button
                className={` mt-3`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.handleCancel();
                }}
                block
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFrame>
    );
  }
}

export default AssignReferredUntimelyModal;
