import React, { Component } from "react";
import {
  RouterProps,
  Styled
} from "../../../../../toolkit/decorators/Artifact";
import { DAutorizacionDispatcher } from "../../../../containers/comisionesDeportivaV2Carrusel/steps/DAutorizacion.dispatcher";
import { DAutorizacionStore } from "../../../../containers/comisionesDeportivaV2Carrusel/steps/DAutorizacion.store";
import { Container } from "reactstrap";
import FormBuilder from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder";
import { Inputs, Buttons, formConfig } from "./DAutorizacion.form";
import { MemoTable } from "./DAutorizacion.table";
import DeportivaComision from "../../../../../types/DeportivaComision";
import { deleteEmptyProps } from "../../../../../toolkit/utils/filter-utils";
import { errorNotification } from "../../../../../utils/notifications";
import s from "../../styles.scss";
import FormBuilderModal from "../../../common/formBuilderModal/formBuilderModal";
import { formConfigRechazar } from "./DAutorizacionRechazar.form";
import { formConfigReasignar } from "./DAutorizacionReasignar.form";
import ConfirmModal from "../../../common/confirmModal/confirmModal";

interface Props
  extends DAutorizacionDispatcher,
    DAutorizacionStore,
    RouterProps {}

interface State {
  downloading: boolean;
  waiting: boolean;
  filters: Inputs & {
    page: number;
    size: number;
  };

  // Actualización de empleado
  rowPopup: DeportivaComision;
  autorizarPopupOpen: boolean;
  autorizarPopupTitle: string;
  autorizarPopupText: string;
  rechazarPopupOpen: boolean;
  rechazarPopupTitle: string;
  rechazarPopupText: string;
  reasignarPopupOpen: boolean;
  reasignarPopupTitle: string;
  reasignarPopupText: string;

  selected: number[];
}

@Styled(s)
export default class DAutorizacionComponent extends Component<Props, State> {
  state = {
    downloading: false,
    waiting: false,
    filters: {
      page: 0,
      size: 10
    } as any,

    // Actualización de empleado
    rowPopup: null as DeportivaComision,
    autorizarPopupOpen: false,
    autorizarPopupTitle: "",
    autorizarPopupText: "",
    rechazarPopupOpen: false,
    rechazarPopupTitle: "",
    rechazarPopupText: "",
    reasignarPopupOpen: false,
    reasignarPopupTitle: "",
    reasignarPopupText: "",

    selected: []
  };

  componentDidMount(): void {
    this.fetchData({} as any);
  }

  submit = (values: any) => {
    // Búsqueda de datos en página 1
    this.fetchData({ page: 0 } as any, null, values);
  };

  // Carga de datos para poblar la tabla
  fetchData = async (
    { pageSize: tablePageSize, page: tablePage },
    _?: any,
    formFilters?
  ) => {
    const filters = formFilters || this.state.filters;
    const page = tablePage >= 0 ? tablePage : this.state.filters.page;
    const size = tablePageSize >= 0 ? tablePageSize : this.state.filters.size;
    const pageableFilters = deleteEmptyProps({ ...filters, page, size });

    this.setState({ filters: pageableFilters });

    const preview = await this.props.getCmsDeportivaAutorizacion(
      pageableFilters
    );
    if (!preview.fulfilled) {
      errorNotification(
        preview.gettingCmsDeportivaAutorizacion ||
          "Error al obtener las comisiones"
      );
    }
  };

  onExport = () => {
    // TODO: Verificar si se necesita
    // this.setState({ downloading: true });
  };

  onCheck = (id: number, checked: boolean) => {
    const selected = checked
      ? this.state.selected.includes(id)
        ? [...this.state.selected]
        : [...this.state.selected, id]
      : this.state.selected.filter(sel => sel != id);

    this.setState({ selected });
  };

  onCheckAll = (checked: boolean) => {
    const comisiones = this.props.cmsDeportivaAutorizacion || [];
    this.setState({
      selected: checked ? comisiones.map(c => c.id) : []
    });
  };

  onRechazar = (row: DeportivaComision) => {
    this.setState({
      rowPopup: row,
      rechazarPopupOpen: true,
      rechazarPopupTitle: "Rechazar comisión",
      rechazarPopupText: "Rechazar comisión"
    });
  };

  onReasignar = (row: DeportivaComision) => {
    this.setState({
      rowPopup: row,
      reasignarPopupOpen: true,
      reasignarPopupTitle: "Reasignar comisión",
      reasignarPopupText: "Reasignar comisión"
    });
  };

  onAutorizar = () => {
    this.setState({
      rowPopup: null,
      autorizarPopupOpen: true,
      autorizarPopupTitle: "Autorizar comisión",
      autorizarPopupText: "Autorizar comisión"
    });
  };

  rechazar = async value => {
    const preview = await this.props.putCmsDeportivaRechazo(
      this.state.rowPopup.id,
      value
    );
    if (preview.fulfilled) {
      this.setState({ rechazarPopupOpen: false });
      this.fetchData({ page: 0 } as any);
    } else {
      errorNotification(
        preview.puttingCmsDeportivaRechazo || "Error al rechazar la comisión"
      );
    }
  };

  reasignar = async value => {
    const preview = await this.props.putCmsDeportivaReasignacion(
      this.state.rowPopup.id,
      value
    );
    if (preview.fulfilled) {
      this.setState({ reasignarPopupOpen: false });
      this.fetchData({ page: 0 } as any);
    } else {
      errorNotification(
        preview.puttingCmsDeportivaReasignacion ||
          "Error al reasignar la comisión"
      );
    }
  };

  autorizar = async () => {
    const preview = await this.props.putCmsDeportivaAutorizar(
      this.state.selected
    );
    if (preview.fulfilled) {
      this.setState({ autorizarPopupOpen: false });
      this.fetchData({ page: 0 } as any);
    } else {
      errorNotification(
        preview.puttingCmsDeportivaAutorizar || "Error al autorizar la comisión"
      );
    }
  };

  render() {
    return (
      <Container>
        <FormBuilderModal
          isOpen={this.state.rechazarPopupOpen}
          title={this.state.rechazarPopupTitle}
          message={this.state.rechazarPopupText}
          ok={this.rechazar}
          cancel={() => this.setState({ rechazarPopupOpen: false })}
          formConfig={formConfigRechazar}
          size={"md"}
        />
        <FormBuilderModal
          isOpen={this.state.reasignarPopupOpen}
          title={this.state.reasignarPopupTitle}
          message={this.state.reasignarPopupText}
          ok={this.reasignar}
          cancel={() => this.setState({ reasignarPopupOpen: false })}
          formConfig={formConfigReasignar}
          size={"md"}
        />
        <ConfirmModal
          isOpen={this.state.autorizarPopupOpen}
          title={this.state.autorizarPopupTitle}
          message={this.state.autorizarPopupText}
          ok={this.autorizar}
          cancel={() => this.setState({ autorizarPopupOpen: false })}
          size={"md"}
          waiting={this.props.puttingCmsDeportivaAutorizar}
        />

        <FormBuilder<Inputs, Buttons>
          config={formConfig}
          submit={this.submit}
          processing={
            this.props.gettingCmsDeportivaAutorizacion ||
            this.props.puttingCmsDeportivaAutorizar
          }
        />

        <hr className="row" />

        <MemoTable
          data={this.props.cmsDeportivaAutorizacion || []}
          loading={
            this.state.downloading ||
            this.props.gettingCmsDeportivaAutorizacion ||
            this.props.puttingCmsDeportivaAutorizar
          }
          onExport={this.onExport}
          onRechazar={this.onRechazar}
          onReasignar={this.onReasignar}
          onCheck={this.onCheck}
          onCheckAll={this.onCheckAll}
          selected={this.state.selected}
        />
      </Container>
    );
  }
}
