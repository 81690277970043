const appConfig = {
  CLASIFICACIONES: {
    CLASIFICACION_CLASE_PERSONALIZADA: 29,
    CLASIFICACION_PROGRAMA_DEPORTIVO: 30,
    NIVEL_PADRE: 3
  },
  CLUB_SELECTION: {
    ALL_CONCEPTOS: "Todos los Conceptos",
    ALL_CONCEPTOS_ID: -1,
    ALL_EMPRESAS: "Todas las empresas",
    ALL_EMPRESAS_ID: -1
  },
  ROLES: {
    ADMIN: "ADMIN",
    USER: "USER"
  },
  ROLE_LABELS: {
    ADMIN: "Admin",
    USER: "User"
  },
  USER_STATUS: {
    ACTIVE: "ACTIVE",
    PENDING: "PENDING",
    SUSPENDED: "SUSPENDED"
  },
  ITEM_STATUS: {
    ACTIVE: "Active",
    INACTIVE: "Inactive"
  },
  VIEW_MODES: {
    LIST: "LIST",
    GRID: "GRID"
  },
  DOMAIN: "KAMINO",
  VERIFY_USER_PAGE_TYPE: "activate",
  CHANGE_PASSWORD_PAGE_TYPE: "change",
  TRANSLATION_MAP: {
    ACTIVE: "user.active-status-label",
    PENDING: "user.pending-status-label",
    SUSPENDED: "user.suspended-status-label"
  },
  DEBOUNCE_INPUT_TIME: 500,
  BASE_URL: "",
  LOCAL_BASE_URL: "",
  TABLES_HEIGHT: "645px",
  SMALL_TABLES_HEIGHT: "345px",
  TABLES_HEIGHT_DATA_GRID: 645,
  TABLES_HEIGHT_DATA_GRID_MIN: 300,
  DEFAULT_PAGE_SIZE: 20,
  CASH_DEFAULT_PRICE_ID: 1,
  DEFAULT_SORTING_NONE: {},
  DEFAULT_SORTING_PARAMS: {
    sort: "updatedDate,desc"
  },
  DEFAULT_SORTING_NAMEPARAMS: {
    sort: "nombre"
  },
  DOCUMENTS_BASE_URL: "api/partners/membership-partner/documents",
  PRICES_GRID_BASE_URL: "api/partners/membership-partner/prices-grid",
  LOCKER_STATUS: {
    FREE: "LIBRE",
    OCCUPIED: "OCUPADO"
  },
  SPORTS_PROGRAM_REGISTRATION_MODE: {
    SEARCH: "BUSCAR",
    CREATE: "CREAR",
    EDIT: "EDITAR"
  }
};

export let BFF_URL = "";
export const IMAGES_ENDPOINT = "/api/partners/membership-partner/documents";

if (process.env.BROWSER) {
  BFF_URL = window["App"]["apiUrl"];
  appConfig["BASE_URL"] = window["App"]["apiUrl"];
  appConfig["LOCAL_BASE_URL"] = window["App"]["apiUrl"];
}

export const UNAUTHORIZED_STATUS = 401;
export const FORBIDDEN_STATUS = 403;

export const TARJETA_CREDITO = "TDC";
export const TARJETA_DEBITO = "TDD";
export const TARJETA_AMEX = "AMEX";
export const CONEKTA = "CONEKTA";
export const ID_EFECTIVO = "EFECTIVO";
export const ID_TRANSFERENCIA = "TRANSFERENCIA";
export const ID_CHEQUE = "CHEQUE";
export const POR_DEFINIR_99 = "POR_DEFINIR_99";
export const LINEA_PAGO = "LINEA_PAGO";
export const ORIGEN_PAGO_TDC = "CARGO_DIRECTO";

export const ID_CONTADO = 0;
export const ID_TRES_MESES = 3;
export const ID_SEIS_MESES = 6;
export const ID_NUEVE_MESES = 9;
export const ID_DOCE_MESES = 12;
export const IVA_NUMBER = 1.16;

export const TIPO_CODIGO_PRODUCTO = "PRODUCTO";
export const TIPO_CODIGO_MEMBRESIA = "MEMBRESIA";
export const TIPO_CODIGO_LOCKER = "LOCKER";
export const TIPO_CODIGO_PASE_DIARIO = "PASE_DIARIO";

export const ID_MONTO = "MONTO";
export const ID_PORCENTAJE = "PORCENTAJE";

export const STATUS_PRICE_ACTIVE = 2;
export const TIPO_ITEM_PRODUCTO = "PRODUCTO";
export const TIPO_ITEM_CARGO = "CARGO";

export const ESTATUS_POR_PAGAR = "POR_PAGAR";

export const ID_TIPO_PRODUCTO_TIENDA = 1;

export const DOCUMENT_ID_INVITED = "25";

export const ID_MEMBRESIA_SOCIO_INVITED = "-1";

export const ID_PERSONA_INVITED = "-1";

export const DEFAULT_PRODUCTS_THREE_LEVEL = "3";

export const DEFAULT_ID = 0;

export const OPTIONS_FORMA_PAGO = [
  ID_CHEQUE,
  TARJETA_CREDITO,
  TARJETA_DEBITO,
  TARJETA_AMEX,
  ID_TRANSFERENCIA,
  POR_DEFINIR_99
];

export const OPTIONS_FORMA_PAGO_EXTENDED = [LINEA_PAGO];

export const OPTIONS_FORMA_PAGO_CUSTOM = [ID_EFECTIVO, ID_CHEQUE];

export const OPTIONS_ESTATUS_EMPLEADOS = ["ACTIVO", "BAJA", "SUSPENDIDO"];

export const OPTIONS_EMPRESA = ["Todas", "GCS", "Human Access"];

export const OPTIONS_ESTATUS_EVALUACION = [
  "CONTRATO_INDETERMINADO",
  "VENCIDO",
  "SIN_CONTRATO_INDETERMINADO",
  "EVALUACION_ENVIADA",
  "ESPERA_EVALUACION",
  "NO_APLICA"
];

export const OPTIONS_ORIGEN_PAGO = [
  ID_EFECTIVO,
  ID_CHEQUE,
  "CONEKTA_VENTA_LINEA",
  "CARGO_DIRECTO",
  "DOMICILIADO",
  "CARGOS_AUTOMATICOS_VSMC",
  "CARGOS_AUTOMATICOS_AMEX",
  "CONEKTA_APP",
  "CARGO_DIRECTO_AMEX"
];

export enum MEMBER_TYPES {
  TITULAR = "TITULAR",
  INTEGRANTE = "INTEGRANTE",
  EXTRA = "EXTRA",
  BEBE = "BEBE",
  INVITADO = "INVITADO"
}

export const MEMBER_TYPE_LIST = [
  MEMBER_TYPES.BEBE,
  MEMBER_TYPES.EXTRA,
  MEMBER_TYPES.INTEGRANTE,
  MEMBER_TYPES.INVITADO,
  MEMBER_TYPES.TITULAR
];

export enum QUOTATIONS_MEMBER_TYPE {
  BABY = "bebe",
  EXTRA = "extra",
  NORMAL = "normal"
}

export enum FORMA_DE_PAGO {
  TDC = "TDC",
  TDD = "TDD",
  MENSUAL = "EFECTIVO",
  ANUALIDAD = "ANUALIDAD"
}

export enum MEMBERSHIP_TYPE {
  VENTA = "VENTA",
  REACTIVACION = "REACTIVACION"
}

export enum QUOTATION_TYPE {
  VENTA = "VENTA",
  REACTIVACION = "REACTIVACION"
}

export enum FILTER_TYPES {
  MAINTENANCE = "mantenimiento",
  MEMBERSHIP = "membresia",
  CLUB = "club",
  CLUB_ALTERNO = "clubAlterno",
  PROMOTION = "promocion",
  AGREEMENT = "convenio",
  DISCOUNT = "descuento"
}

export const DEFAULT_CATEGORY_ID = 1;

export enum STATUS_ACTIVITY {
  LEIDA = "LEILA",
  CREADA = "CREADA",
  COMPLETADA = "COMPLETADA",
  INCOMPLETA = "INCOMPLETA"
}

export const MEMBRESIA_TYPE = [
  { label: "LOAD", value: 108 },
  { label: "ZUMA", value: 117 },
  { label: "SW", value: 21 }
];

export const MEMBRESIA_REACTIVACION_TYPE = [
  { label: "ZUMA", value: 118 },
  { label: "SW", value: 29 }
];

export const MANTENIMIENTO_TYPE = [
  { label: "LOAD", value: 109 },
  { label: "ZUMA", value: 119 },
  { label: "SW", value: 22 }
];

export const CONCEPTOS = [
  { label: "SW", value: "SW" },
  { label: "GYM_PLUS", value: "GYM_PLUS" },
  { label: "LOAD", value: "LOAD" },
  { label: "ZUMA", value: "ZUMA" },
  { label: "BTB", value: "BTB" },
  { label: "GYM", value: "GYM" }
];

export const REPORT_TYPE = [
  {
    label: "Estado de cuenta por concesionario",
    value: "ESTADO_CUENTA_CONCESIONARIO"
  },
  { label: "Antigüedad de facturas", value: "ANTIGUEDAD_FACTURAS" }
];

export const ESTATUS = [
  { label: "PENDIENTE", value: "PENDIENTE" },
  { label: "PENDIENTE DE VALIDAR", value: "PENDIENTE_DE_VALIDAR" },
  { label: "PAGADO", value: "PAGADO" }
];

export const MONTHS = [
  { label: "JAN", value: "JAN" },
  { label: "FEB", value: "FEB" },
  { label: "MAR", value: "MAR" },
  { label: "APR", value: "APR" },
  { label: "MAY", value: "MAY" },
  { label: "JUN", value: "JUN" },
  { label: "JUL", value: "JUL" },
  { label: "AUG", value: "AUG" },
  { label: "SEP", value: "SEP" },
  { label: "OCT", value: "OCT" },
  { label: "NOV", value: "NOV" },
  { label: "DEC", value: "DEC" }
];

export const PAYMENT_METHODS = [
  { label: "PUE", value: "PUE" },
  { label: "PPD", value: "PPD" }
];

export const FORM_PAYMENT_RETURN = [
  { label: "TARJETA CREDITO", value: "TDC" },
  { label: "TARJETA DEBITO", value: "TDD" },
  { label: "DOMICILIADO", value: "DOMICILIADO" },
  { label: "AMERICAN EXPRESS", value: "AMEX" }
];

export const CARD_TYPES_MIT = [
  { label: "MASTERCARD", value: "MASTERCARD" },
  { label: "VISA", value: "VISA" },
  { label: "AMERICAN EXPRESS", value: "AMEX" },
  { label: "JCB", value: "JCB" },
  { label: "DISCOVER", value: "DISCOVER" },
  { label: "DINERS", value: "DINERS" }
];

export const TIPO_MONTO = ["DEBITO", "CREDITO"];

export const KID_MAXIMUM_AGE = 15;

export default appConfig;

export const SYSADMIN = "SYSADMIN";

export const DEMAND_CLASSIFICATION_TYPES = {
  QUEJA: "QUEJA",
  SUGERENCIA: "SUGERENCIA",
  INFORMACION: "INFORMACION",
  FELICITACION: "FELICITACION"
};

export const DEMAND_CRITICITY = {
  ALTA: "ALTA",
  MEDIA: "MEDIA",
  BAJA: "BAJA"
};

export const SHOPPING_CART_PRODUCTS_TYPES = {
  PRODUCTO: "PRODUCTO",
  CARGO: "CARGO",
  MEMBRESIA: "MEMBRESIA",
  ANUALIDAD: "ANUALIDAD",
  MANTENIMIENTO: "MANTENIMIENTO",
  CARGO_CONCESIONARIO: "CARGO_CONCESIONARIO"
};

export const PARTNER_MEMB_PENDIENTE_STATUS = "PENDIENTE";
export const PARTNER_MEMB_PENDIENTE_CON_ANUALIDAD_STATUS =
  "PENDIENTE_CON_ANUALIDAD";
export const PARTNER_MEMB_PENDIENTE_REACTIVACION_STATUS =
  "PENDIENTE_REACTIVACION";
export const PARTNER_MEMB_PENDIENTE_FIRMA_CAT_STATUS =
  "PENDIENTE_FIRMA_EXPEDIENTE";
export const PARTNER_MEMB_ACTIVE_STATUS = "ACTIVA";
export const PARTNER_MEMB_INACTIVE_STATUS = "INACTIVA";
export const PARTNER_MEMB_PASIVE_STATUS = "PASIVA";
export const PARTNER_MEMB_TRASPASSED_STATUS = "TRASPASADA";
export const PARTNER_MEMB_CANCELLED_STATUS = "ELIMINADA";

export const MOTIVO_CANCELACION_CAT = [
  {
    label: "Personal",
    options: [
      {
        value: "SALUD",
        label: "Salud / lesiones"
      },
      {
        value: "CONTAGIO_COVID",
        label: "Contagio COVID"
      },
      {
        value: "FALTA_TIEMPO",
        label: "Falta de tiempo / trabajo / escuela"
      },
      {
        value: "ECONOMIA_PERSONAL",
        label: "Economía Personal / Desempleo"
      },
      {
        value: "VIAJE",
        label: "Viaje"
      }
    ]
  },
  {
    label: "Servicio",
    options: [
      {
        value: "ATENCION",
        label: "Actitud / atención del Staff"
      },
      {
        value: "AMENIDADES",
        label: "Amenidades / Toallas"
      },
      {
        value: "ADMINISTRACION",
        label: "Administración"
      },
      {
        value: "LIMPIEZA",
        label: "Limpieza"
      },
      {
        value: "MANTENIMIENTO",
        label: "Mantenimiento"
      },
      {
        value: "OFERTA_DEPORTIVA",
        label: "Oferta deportiva"
      },

      {
        value: "VENTAS",
        label: "Ventas"
      }
    ]
  },
  {
    label: "Precio",
    options: [
      {
        value: "PRECIO",
        label: "Alto precio"
      }
    ]
  },
  {
    label: "Competencia",
    options: [
      {
        value: "NELSON_VARGAS",
        label: "Nelson Vargas"
      },
      {
        value: "SPORTS_CITY",
        label: "Sport City"
      },
      {
        value: "SMART_FIT",
        label: "Smart Fit"
      },
      {
        value: "OTRO",
        label: "Otro"
      }
    ]
  },
  {
    label: "Cambio de membresía",
    options: [
      {
        value: "CAMBIO_MEMBRESIA",
        label: "Cambio de membresía"
      }
    ]
  },
  {
    label: "CAT",
    options: [
      {
        value: "CAMBIO_TARJETA",
        label: "Cambio de Tarjeta"
      },
      {
        value: "DEPURACION",
        label: "Depuración"
      },
      {
        value: "PAGO_ANUALIDAD",
        label: "Pago de Anualidad"
      },
      {
        value: "CESION_DERECHOS",
        label: "Cesión de Derechos"
      },
      {
        value: "TRASPASO_CLUB",
        label: "Traspaso a otro club"
      }
    ]
  }
];

export const MOTIVO_BAJA_SOCIO = [
  {
    label: "Personal",
    options: [
      {
        value: "PERSONAL",
        label: "Personal"
      },
      {
        value: "FALTA_TIEMPO_TRABAJO_ESCUELA",
        label: "Falta de tiempo / trabajo / escuela"
      },
      {
        value: "CAMBIO_RESIDENCIA_VIAJE",
        label: "Cambio de residencia / viaje"
      },
      {
        value: "SALUD_LESIONES",
        label: "Salud / lesiones"
      },
      {
        value: "CAMBIO_MEMBRESIA",
        label: "Cambio de membresía"
      }
    ]
  },
  {
    label: "Servicio",
    options: [
      {
        value: "SERVICIO",
        label: "Servicio"
      }
    ]
  },
  {
    label: "Precio",
    options: [
      {
        value: "PRECIO",
        label: "Precio"
      }
    ]
  },
  {
    label: "Competencia",
    options: [
      {
        value: "COMPETENCIA",
        label: "Competencia"
      }
    ]
  }
];
