import { INTEREST_STATUS } from "../types-business/User";

export interface ProspectArguments {
  nombre?: string;
  fechaCreacion?: string;
  asignadoA?: string;
  convenioId?: string;
  fechaNacimiento?: string;
  apellido?: string;
  apellidoMaterno?: string;
  curp?: string;
  tipo?: string;
  page?: string;
  size?: string;
}

export interface ProspectReportArguments {
  clubId?: string;
  vendedorId?: string;
  estatus?: string;
  fechaDesde?: string;
  fechaHasta?: string;
  mes;
  anio;
  page?: string;
  size?: string;
}

interface Email {
  correo: string;
}

interface PhoneNumber {
  numero: string;
}

interface Getter {
  getData: () => string;
}

class EmailClass implements Getter {
  correo: string;

  constructor(emailObject: Email) {
    this.correo = emailObject.correo;
  }

  getData() {
    return this.correo;
  }
}

class PhoneNumberClass {
  numero: string;

  constructor(phoneNumberObject: PhoneNumber) {
    this.numero = phoneNumberObject.numero;
  }

  getData() {
    return this.numero;
  }
}

export interface Prospect {
  personaId: string;
  nombre: string;
  fechaNacimiento: string;
  primerApellido: string;
  segundoApellido: string;
  telefonos: PhoneNumberClass[];
  correosElectronicos: EmailClass[];
  status: INTEREST_STATUS;
  nombreAsignadoA: string;
  asignadoA: string;
  convenioId: string;
  nombreConvenio: string;
}

export enum PARTNER_TYPES {
  EMPLEADO = "EMPLEADO",
  EXTERNO = "EXTERNO",
  SOCIO = "SOCIO",
  CORPORATIVO = "CORPORATIVO"
}

export enum PEOPLE_TYPES {
  CLIENTE = "CLIENTE",
  CLIENTE_TITULAR = "CLIENTE_TITULAR",
  CLIENTE_INTEGRANTE = "CLIENTE_INTEGRANTE",
  EXTERNO = "EXTERNO",
  PROSPECTO = "PROSPECTO",
  EMPLEADO = "EMPLEADO",
  CORPORATIVO = "CORPORATIVO"
}

export enum SEX_TYPES {
  MASCULINO = "MASCULINO",
  FEMENINO = "FEMENINO"
}

export enum PROSPECT_REPORT_STATUS {
  ACTIVO = "ACTIVO",
  VENDIDO = "VENDIDO",
  CANCELADO = "CANCELADO"
}
