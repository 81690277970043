import {
  faBan,
  faCameraRetro,
  faChild,
  faDoorClosed,
  faDoorOpen,
  faEdit,
  faExchangeAlt,
  faExclamationCircle,
  faFingerprint,
  faPortrait,
  faRetweet,
  faShoppingCart,
  faTimes,
  faTrash,
  faUniversalAccess,
  faUserClock,
  faUserFriends,
  faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import ReactTable from "react-table";
import { Button, Row, TabPane, UncontrolledTooltip } from "reactstrap";
import BaseButtonWithIconAndTooltip from "../../../toolkit/baseButtonWithIconAndTooltip";
import IAction from "../../../types/IAction";
import { userHasPermission } from "../../../utils/accessTree";
import { MEMBER_TYPES } from "../../../utils/constants";
import { handleRequestError } from "../../../utils/helpers";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import permissionCodes from "../../../utils/permissionCodes";
import {
  deleteFreeGuestsAccess,
  forceBlockClubAccessForPartner,
  partnerFingerprintCapturedValidation,
  updateTitularRequest
} from "../../actions/partners";
import { createMaintenanceChargesForAccessExtensionRequest } from "../../actions/products";
import ConfirmModal from "../../components/common/confirmModal/confirmModal";
import { Partner } from "./interfaces";
import PawPrintModal from "./pawPrintModal";
import PhotoModal from "./photoModal";
import s from "./tabPartners.scss";
import ExtraClubAccessConfigurationModal from "./tabPartners/extraClubAccessConfigurationModal";

interface Props {
  partner: Partner;
  filters: object;
  partnersMembership: any;
  membershipTypesMembers: any[];
  history: any;
  toggleAddPartner: () => any;
  toggleAddFreePartner: () => any;
  toggleChangeFreePartner: (data: any) => any;
  toggleAddFreeChildPartner: () => any;
  getMembershipMembers: (membresiaId: string) => any;
  updatePhoto: (
    idMantenimiento: string,
    idMembresia: string,
    idMembresiaSocio: string,
    idPersona: string,
    photo: Blob
  ) => any;
  updatePawPrint: (
    idMantenimiento: string,
    idMembresia: string,
    idMembresiaSocio: string,
    idPersona: string,
    pawPrint: string
  ) => any;
  accessRegister: (idPersona: string) => void;
  getPartnersMembership: (
    idMembresiaSocio: string,
    idMantenimiento: string,
    idMembresia: string
  ) => void;
  toggleDeletePartner: (
    partner?: boolean,
    idMembresiaSocio?: string
  ) => IAction;
  updateTitular: (data: object, membresiaSocioId: string) => IAction;
  titularUpdated: () => void;
  invalidateStateValue: (fieldName: string, value: any) => void;
  executePartnerMembershipPartners: () => void;
  refreshPartner: () => void;
  descripcionTraspaso: any;
  membershipBlocked: boolean;
  membershipBlockedNuevoFlujoVenta: boolean;
}

const MEMBERSHIP_TYPE_FREE_GUEST = 17;
const MEMBERSHIP_TYPE_FREE_CHILD_GUEST = 18;
const FREE_MEMBER_TYPES = [
  MEMBERSHIP_TYPE_FREE_GUEST,
  MEMBERSHIP_TYPE_FREE_CHILD_GUEST
];

class TabPartners extends React.Component<Props> {
  state = {
    partner: {
      nombrePersona: "",
      nombreMembresia: "",
      nombreConvenio: "",
      certificado: false,
      estatusMembresia: "",
      fechaAdquisicion: "",
      fechaInicioMantenimiento: "",
      idClubAlterno: "",
      idClubBase: "",
      idMembresia: "",
      idMantenimiento: "",
      idMembresiaSocio: "",
      idPersona: "",
      intransferible: false,
      limiteInicioMantenimiento: "",
      nueva: false,
      precioBaseMembresia: "",
      precioVentaMembresia: "",
      ventaEnLinea: false,
      partnersMembership: null,
      configAccesoClubPatronHorario: null
    },
    isOpen: false,
    socioId: null,
    isPhotoOpen: false,
    isPawPrintOpen: false,

    action: "",
    nuevoTitular: null,
    nuevoTitularId: null,
    confirmModalIsOpen: false,
    idPartnerToExtend: null,
    allowedDatesToExtend: null,

    confirmDeleteFreeGuestsAccessModalIsOpen: false,
    idFreeGuestsAccessToDelete: null,

    confirmForceBlockModalIsOpen: false,
    idPartnerToForceBlock: null,
    partnerIsBlocked: null,

    isAccessClubExtraModalOpen: false,
    idPartnerAccessClubExtra: null,
    selectedPartnerAccessClubExtra: null,

    isChangeFreeGuestModalOpen: false,
    partnerMembershipIdChangeFreeGuests: null,
    personIdChangeFreeGuests: null,
    personNameChangeFreeGuests: null
  };

  columnsTraspaso = [
    {
      Header: "Id Persona",
      width: "150",
      maxWidth: "150",
      accessor: "idPersona"
    },
    {
      Header: "Nombre",
      width: "400",
      maxWidth: "400",
      accessor: "nombrePersona"
    },
    {
      Header: "Tipo Integrante",
      width: "150",
      maxWidth: "150",
      accessor: "tipoSocio"
    }
  ];

  columns = [
    {
      Header: "Id Persona",
      width: "150",
      maxWidth: "150",
      accessor: "idPersona"
    },
    {
      Header: "Nombre",
      width: "400",
      maxWidth: "400",
      accessor: "nombrePersona"
    },
    {
      Header: "Tipo Integrante",
      width: "150",
      maxWidth: "150",
      accessor: "tipoSocio"
    },
    {
      Header: "Activo hasta",
      width: "150",
      maxWidth: "150",
      accessor: "activoHasta",
      className: "text-center"
    },
    {
      Header: "",
      width: "220",
      maxWidth: "220",
      Cell: row => {
        const {
          tieneAccesoAClub,
          razonSinAccesoAClub,
          forzarBloqueoTorniquete,
          eliminado,
          tieneAccesoCarrito,
          idTipoIntegrante,
          idMembresiaSocio,
          idPersona
        } = row.original;
        const accesoAlClub = !forzarBloqueoTorniquete && !!tieneAccesoAClub;
        return !eliminado ? (
          <div>
            {" "}
            <BaseButtonWithIconAndTooltip
              id={`isBlockedForAccess_${row.original.idPersona}`}
              icon={faBan}
              color={!forzarBloqueoTorniquete ? "#000" : "#710"}
              tooltipTitle={
                !forzarBloqueoTorniquete
                  ? "Aplicar bloqueo de acceso a club"
                  : "Quitar bloqueo de acceso a club"
              }
              onClick={() => {
                if (
                  userHasPermission(
                    permissionCodes.PARTNER_MEMBERSHIP_TAB_PARTNERS_FORCE_BLOCK_CLUB_ACCESS
                  )
                ) {
                  this.setState({
                    confirmForceBlockModalIsOpen: true,
                    idPartnerToForceBlock: row.original.idPersona,
                    partnerIsBlocked: !!forzarBloqueoTorniquete
                  });
                }
              }}
            />
            <BaseButtonWithIconAndTooltip
              id={`hasClubAccess_${row.original.idPersona}`}
              icon={accesoAlClub ? faDoorOpen : faDoorClosed}
              color={accesoAlClub ? "#070" : "#710"}
              tooltipTitle={
                accesoAlClub
                  ? "Tiene acceso a club"
                  : `No tiene acceso a club: ${
                      !!razonSinAccesoAClub
                        ? "(" + razonSinAccesoAClub + ")"
                        : ""
                    }`
              }
              onClick={() => {
                if (
                  userHasPermission(
                    permissionCodes.PARTNER_MEMBERSHIP_TAB_PARTNERS_ACCESS_CLUB_EXTENSION
                  ) &&
                  !forzarBloqueoTorniquete &&
                  !!row.original.allowExtendClubAccess
                ) {
                  this.setState({
                    idPartnerToExtend: row.original.idPersona,
                    confirmModalIsOpen: true,
                    allowedDatesToExtend: [
                      ...(row.original.fechasExtensionAcceso || [])
                    ]
                  });
                }
              }}
            />
            <BaseButtonWithIconAndTooltip
              id={`editPersona_${row.original.idPersona}`}
              icon={faEdit}
              tooltipTitle="Editar"
              onClick={() => {
                window.open(
                  `/socio/${row.original.idPersona}/detalle`,
                  "_blank"
                );
              }}
            />
            {userHasPermission(
              permissionCodes.PARTNER_MEMBERSHIP_TAB_PARTNERS_CHANGE_TITULAR
            ) &&
              !!row.original.cambioTitular && (
                <BaseButtonWithIconAndTooltip
                  id={`cambiarTitular_${row.original.idPersona}`}
                  icon={faExchangeAlt}
                  tooltipTitle="Cambio de titular"
                  onClick={() => {
                    let socioId = row.original.idPersona;

                    const partner = (this.props.partnersMembership || []).find(
                      partner => partner.idPersona == socioId
                    );

                    if (!!partner) {
                      this.setState({
                        nuevoTitularId: partner.idPersona,
                        nuevoTitular: partner.nombrePersona,
                        socioId,
                        isOpen: true
                      });
                    }
                  }}
                />
              )}
            {!!tieneAccesoCarrito && (
              <BaseButtonWithIconAndTooltip
                id={`carritoPersona_${row.original.idPersona}`}
                icon={faShoppingCart}
                tooltipTitle="Ir a carrito del integrante"
                onClick={() => {
                  this.props.history.push(
                    `/productos/carrito/${row.original.idPersona}`
                  );
                }}
              />
            )}
            {userHasPermission(
              permissionCodes.PARTNER_MEMBERSHIP_TAB_PARTNERS_ACCESS_CLUB_EXTRA_CONFIG
            ) && (
              <BaseButtonWithIconAndTooltip
                id={`configExtraClubAccesPersona_${row.original.idPersona}`}
                icon={faUniversalAccess}
                tooltipTitle="Configuración de accesos a club adicional"
                onClick={() => {
                  let partnerId = row.original.idPersona;
                  const partner = (this.props.partnersMembership || []).find(
                    partner => partner.idPersona == partnerId
                  );
                  this.setState({
                    isAccessClubExtraModalOpen: true,
                    idPartnerAccessClubExtra: partnerId,
                    selectedPartnerAccessClubExtra: partner
                  });
                }}
              />
            )}
            {(idTipoIntegrante || 0) == MEMBERSHIP_TYPE_FREE_GUEST && (
              <BaseButtonWithIconAndTooltip
                id={`changeFreeGuests_${idPersona}`}
                icon={faRetweet}
                tooltipTitle="Cambio de invitado gratis"
                onClick={() => {
                  const partner = (this.props.partnersMembership || []).find(
                    partner => partner.idPersona == idPersona
                  );

                  this.setState({
                    isChangeFreeGuestModalOpen: true,
                    partnerMembershipIdChangeFreeGuests: idMembresiaSocio,
                    personIdChangeFreeGuests: idPersona,
                    personNameChangeFreeGuests:
                      (partner || {}).nombrePersona || ""
                  });
                }}
              />
            )}
          </div>
        ) : null;
      }
    },
    {
      Header: () => (
        <div className="w-100 text-center">
          <Button
            color="link"
            id="btnAgregarSocio"
            onClick={() => this.toggleAddPartner()}
            className={`${s.innerButton} ${s.primaryLink}`}
            disabled={
              this.props.partner && this.currentFullPartnersMaxAllowedReached()
            }
          >
            <FontAwesomeIcon icon={faUserPlus} />
            <UncontrolledTooltip placement="top" target={`btnAgregarSocio`}>
              Agregar socio
            </UncontrolledTooltip>
          </Button>

          {/*INVITADOS GRATIS*/}
          {!!this.props.partner &&
            (this.props.partner.invitadosGratisDisponibles || []).length >
              0 && (
              <Button
                color="link"
                id="btnAgregarInvitadoGratis"
                onClick={() => this.toggleAddFreePartner()}
                className={`${s.innerButton} ${s.primaryLink}`}
              >
                <FontAwesomeIcon icon={faUserFriends} />
                <UncontrolledTooltip
                  placement="top"
                  target={`btnAgregarInvitadoGratis`}
                >
                  Agregar invitado gratis
                </UncontrolledTooltip>
              </Button>
            )}

          {/*NIÑOS GRATIS*/}
          {!!this.props.partner &&
            (this.props.partner.ninosGratisDisponibles || []).length > 0 && (
              <Button
                color="link"
                id="btnAgregarNinosGratis"
                onClick={() => this.toggleAddFreeChildPartner()}
                className={`${s.innerButton} ${s.primaryLink}`}
              >
                <FontAwesomeIcon icon={faChild} />
                <UncontrolledTooltip
                  placement="top"
                  target={`btnAgregarNinosGratis`}
                >
                  Agregar niño gratis
                </UncontrolledTooltip>
              </Button>
            )}
        </div>
      ),
      Cell: row => {
        const {
          eliminado,
          tipoSocio,
          esNinoGratis: isChildGuest
        } = row.original;
        const isMainPartner =
          (tipoSocio || "").toUpperCase() == MEMBER_TYPES.TITULAR;

        return !eliminado ? (
          <div>
            {" "}
            {!isMainPartner &&
            !isChildGuest && ( //Si no es titular
                <BaseButtonWithIconAndTooltip
                  id={`deletePerson_${row.original.idPersona}`}
                  icon={faTrash}
                  color={"#b3b53a"}
                  tooltipTitle={"Dar de baja integrante "}
                  onClick={() => {
                    this.handleRemovePartner(row.original);
                  }}
                />
              )}
            <BaseButtonWithIconAndTooltip
              id={`captureFingerprintPerson_${row.original.idPersona}`}
              icon={faFingerprint}
              color={"#f4a124"}
              tooltipTitle={
                (row.original.isHuella ? "Actualice" : "Registre") +
                " la huella digital dela persona"
              }
              onClick={() => {
                this.handlePawPrint(row.original);
              }}
            />
            <BaseButtonWithIconAndTooltip
              id={`validateFingerprintPerson_${row.original.idPersona}`}
              icon={faExclamationCircle}
              color={"#077"}
              tooltipTitle={
                "Validar captura de la huella digital de la persona"
              }
              onClick={() => {
                this.handlePawPrintValidation(row.original);
              }}
            />
            <BaseButtonWithIconAndTooltip
              id={`biometricFingerprintPerson_${row.original.idPersona}`}
              icon={faPortrait}
              color={"#4da6ff"}
              tooltipTitle={" Reconocimiento facial"}
              onClick={() => {
                const {
                  idPersona,
                  idClubBase,
                  idMembresiaSocio
                } = row.original;
                window.open(
                  `https://cloud2.sportsworld.com.mx/apiMonitor/fotografia/crm2/home/${idPersona}/${this.props.partner.idClubBase}/${idMembresiaSocio}`
                );
              }}
            />
            <BaseButtonWithIconAndTooltip
              id={`capturePhotoPerson_${row.original.idPersona}`}
              icon={faCameraRetro}
              color={row.original.isFoto ? "#077" : "#cea13e"}
              tooltipTitle={
                (row.original.isFoto ? "Actualice" : "Registre") + " fotografía"
              }
              onClick={() => {
                this.handlePhoto(row.original);
              }}
            />
            <BaseButtonWithIconAndTooltip
              id={`fullRegistryPerson_${row.original.idPersona}`}
              icon={faUserClock}
              color={
                row.original.isHuella && row.original.isFoto
                  ? "#077"
                  : "#cea13e"
              }
              tooltipTitle={
                row.original.isHuella && row.original.isFoto
                  ? "Registro completo"
                  : "Registro incompleto"
              }
              onClick={() => {
                this.handleRegister(row.original);
              }}
            />
          </div>
        ) : null;
      },
      width: "200",
      maxWidth: "200"
    }
  ];

  columnsFreeGuests = [
    {
      Header: "Inicio acceso",
      accessor: "fechaInicioPeriodo",
      className: "text-center"
    },
    {
      Header: "Fin acceso",
      accessor: "fechaFinPeriodo",
      className: "text-center"
    },
    {
      Header: "",
      Cell: row => {
        return userHasPermission(
          permissionCodes.PARTNER_MEMBERSHIP_TAB_PARTNERS_DELETE_FREE_GUEST_ACCESS
        ) ? (
          <div>
            {" "}
            <BaseButtonWithIconAndTooltip
              id={`deleteFreeGuestsAccess_${row.original.id}`}
              icon={faTimes}
              tooltipTitle={"Eliminar acceso de invitado gratis"}
              onClick={() => {
                this.setState({
                  idFreeGuestsAccessToDelete: row.original.id,
                  confirmDeleteFreeGuestsAccessModalIsOpen: true
                });
              }}
            />
          </div>
        ) : null;
      }
    }
  ];

  componentDidMount() {
    //Se obtienen los tipos de miembros disponibles para la membresía
    this.props.getMembershipMembers(this.props.partner.idMembresia);
  }

  toggleAddPartner = () => {
    this.props.toggleAddPartner();
  };
  toggleAddFreePartner = () => {
    this.props.toggleAddFreePartner();
  };
  toggleAddFreeChildPartner = () => {
    this.props.toggleAddFreeChildPartner();
  };

  toggleChangeFreePartner = (data: any) => {
    this.props.toggleChangeFreePartner(data);
  };

  handleRemovePartner = partner => {
    this.props.toggleDeletePartner(
      partner,
      this.props.partner.idMembresiaSocio
    );
  };

  handlePawPrint = p => {
    this.setState(state => ({
      ...state,
      isPawPrintOpen: true,
      action: "huella",
      partner: p
    }));
  };

  handlePawPrintValidation = p => {
    infoNotification("Validando captura de huella dígital...");

    const personaId = p.idPersona;
    const idMembresiaSocio = this.props.partner.idMembresiaSocio;

    partnerFingerprintCapturedValidation(idMembresiaSocio, personaId)
      .then(() => {
        successNotification("Huella dígital capturada correctamente.");
      })
      .catch(handleRequestError);
  };

  handlePhoto = p => {
    this.setState(state => ({
      ...state,
      isPhotoOpen: true,
      action: "foto",
      partner: p
    }));
  };

  handleRegister = p => {
    this.props.accessRegister(p.idPersona);
  };

  handleToggle = () => {
    this.setState(state => ({
      ...state,
      isPhotoOpen: false,
      isPawPrintOpen: false
    }));
  };

  handleAction = async (action, data) => {
    if (action == "huella") {
      this.props.updatePawPrint(
        this.props.partner.idMantenimiento,
        this.props.partner.idMembresia,
        this.props.partner.idMembresiaSocio,
        this.state.partner.idPersona,
        data
      );
    } else {
      this.props.updatePhoto(
        this.props.partner.idMantenimiento,
        this.props.partner.idMembresia,
        this.props.partner.idMembresiaSocio,
        this.state.partner.idPersona,
        data
      );
    }
    this.handleToggle();
  };

  onChangeFilter = (e, fieldName) => {
    let socioId = e.target.value;
    this.props.partnersMembership &&
      this.props.partnersMembership.map(partner => {
        if (partner.idPersona.toString() === socioId) {
          this.setState({
            nuevoTitularId: partner.idPersona,
            nuevoTitular: partner.nombrePersona
          });
        }
      });

    this.setState({
      [fieldName]: socioId
    });
  };

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleSubmitMainPartnerchange = () => {
    updateTitularRequest(
      { personaNuevoTitular: this.state.nuevoTitularId },
      this.props.partner.idMembresiaSocio
    )
      .then(() => {
        this.setState({
          isOpen: false,
          nuevoTitularId: null,
          nuevoTitular: null,
          socioId: null
        });

        this.props.titularUpdated();
      })
      .catch(handleRequestError);
  };

  handleSubmitAccessExtension = selectedDate => {
    const startDate = this.state.allowedDatesToExtend[0].fechaInicial;
    const finalDate =
      selectedDate || this.state.allowedDatesToExtend[0].fechaFinal;
    const personaId = this.state.idPartnerToExtend;
    const idMembresiaSocio = this.props.partner.idMembresiaSocio;

    createMaintenanceChargesForAccessExtensionRequest({
      startDate,
      finalDate,
      personaId,
      idMembresiaSocio
    })
      .then(() => {
        this.setState({
          idPartnerToExtend: null,
          confirmModalIsOpen: false,
          allowedDatesToExtend: null
        });

        if (!!this.props.invalidateStateValue) {
          this.props.invalidateStateValue("movementsList", []);
        }

        if (!!this.props.executePartnerMembershipPartners) {
          this.props.executePartnerMembershipPartners();
        }
      })
      .catch(handleRequestError);
  };

  handleSubmitForceBlock = () => {
    const personaId = this.state.idPartnerToForceBlock;
    const idMembresiaSocio = this.props.partner.idMembresiaSocio;

    forceBlockClubAccessForPartner(idMembresiaSocio, personaId)
      .then(() => {
        this.setState({
          confirmForceBlockModalIsOpen: false,
          idPartnerToForceBlock: null,
          partnerIsBlocked: null
        });

        if (!!this.props.invalidateStateValue) {
          this.props.invalidateStateValue("movementsList", []);
        }

        if (!!this.props.executePartnerMembershipPartners) {
          this.props.executePartnerMembershipPartners();
        }
      })
      .catch(handleRequestError);
  };

  handleFreeGuestsAccessDelete = () => {
    const { idFreeGuestsAccessToDelete: id } = this.state;
    const { idMembresiaSocio } = this.props.partner;

    deleteFreeGuestsAccess(idMembresiaSocio, id)
      .then(() => {
        this.setState({
          idFreeGuestsAccessToDelete: null,
          confirmDeleteFreeGuestsAccessModalIsOpen: false
        });

        if (!!this.props.refreshPartner) {
          this.props.refreshPartner();
        }
      })
      .catch(handleRequestError);
  };

  currentFullPartnersMaxAllowedReached = () => {
    const {
      integrantes,
      integrantesExtra,
      integrantesBebe
    } = this.props.partner.membresia;

    const currentFullPartnersCount = (
      this.props.partnersMembership || []
    ).filter(x => !FREE_MEMBER_TYPES.includes(x.idTipoIntegrante)).length;
    const maxAllowedPartnersCount =
      integrantes + integrantesExtra + integrantesBebe;
    return currentFullPartnersCount >= maxAllowedPartnersCount;
  };

  isMembershipBlocked = () => {
    return this.props.membershipBlocked || false;
  };

  isMembershipBlockedNuevoFlujoVenta = () => {
    return this.props.membershipBlockedNuevoFlujoVenta || false;
  };

  render() {
    const { partnersMembership } = this.props;
    const mainPartner = (partnersMembership || []).find(
      x => (x.tipoSocio || "").toLowerCase() == "titular"
    );
    const mainPartnerHasClubAccess = !!mainPartner
      ? mainPartner.tieneAccesoAClub
      : false;
    const partnersData = [...partnersMembership].map(x => {
      const partnerType = (x.tipoSocio || "").toLowerCase();
      const isFreeGuest =
        partnerType == "usuario_gratis" ||
        x.idTipoIntegrante == MEMBERSHIP_TYPE_FREE_GUEST;
      const isChildGuest =
        partnerType == "niño_gratis" ||
        x.idTipoIntegrante == MEMBERSHIP_TYPE_FREE_CHILD_GUEST;
      const isMainPartner = partnerType == "titular";

      x["cambioTitular"] = !(isMainPartner || isFreeGuest || isChildGuest);
      x["tieneAccesoAClub"] = !(isFreeGuest || isChildGuest)
        ? x.tieneAccesoAClub
        : x.tieneAccesoAClub && mainPartnerHasClubAccess;
      x["allowExtendClubAccess"] = !isChildGuest;
      x["tieneAccesoCarrito"] = !isChildGuest;
      x["activoHasta"] = !isChildGuest ? x["activoHasta"] : "";

      return x;
    });

    const invitadosGratisDisponibles = (
      this.props.partner.invitadosGratisDisponibles || []
    ).filter(x => !!x.visible);

    return (
      <TabPane className="my-2" tabId="1">
        <PhotoModal
          isOpen={this.state.isPhotoOpen}
          toggle={this.handleToggle}
          uploadAction={(action, partner) => this.handleAction(action, partner)}
          partner={this.state.partner}
          action={this.state.action}
        />
        <PawPrintModal
          isOpen={this.state.isPawPrintOpen}
          toggle={this.handleToggle}
          uploadAction={(action, partner) => this.handleAction(action, partner)}
          partner={this.state.partner}
          partnerClubId={this.props.partner.idClubBase}
          action={this.state.action}
        />

        <ExtraClubAccessConfigurationModal
          isOpen={this.state.isAccessClubExtraModalOpen}
          partnerName={
            (this.state.selectedPartnerAccessClubExtra || {}).nombrePersona
          }
          config={
            (this.state.selectedPartnerAccessClubExtra || {})
              .configAccesoClubPatronHorario || {}
          }
          cancel={() => {
            this.setState({
              isAccessClubExtraModalOpen: false,
              idPartnerAccessClubExtra: null,
              selectedPartnerAccessClubExtra: null
            });
          }}
          success={() => {
            this.setState({
              isAccessClubExtraModalOpen: false,
              idPartnerAccessClubExtra: null,
              selectedPartnerAccessClubExtra: null
            });
            if (!!this.props.executePartnerMembershipPartners) {
              this.props.executePartnerMembershipPartners();
            }
            if (!!this.props.invalidateStateValue) {
              this.props.invalidateStateValue("partnersClubAccessInfo", []);
            }
          }}
        />
        <ConfirmModal
          title="Cambio de titular"
          message={`El titular [${this.props.partner.nombrePersona}] será reemplazado por [${this.state.nuevoTitular}]`}
          isOpen={this.state.isOpen}
          requireInput={false}
          ok={this.handleSubmitMainPartnerchange}
          cancel={() => {
            this.setState({
              isOpen: false,
              nuevoTitularId: null,
              nuevoTitular: null,
              socioId: null
            });
          }}
        />
        <ConfirmModal
          title="Extensión de Acceso"
          message="Se extenderá el período desde el día uno del siguiente mes a partir de la fecha límite de acceso actual hasta la fecha seleccionada. ¿Desea extender el acceso del socio?"
          isOpen={this.state.confirmModalIsOpen}
          requireInput={true}
          inputType="select"
          options={(this.state.allowedDatesToExtend || []).map(x => {
            return { label: x.fechaFinal, value: x.fechaFinal };
          })}
          currentValue={
            (this.state.allowedDatesToExtend || []).length > 0
              ? this.state.allowedDatesToExtend[0].fechaFinal
              : null
          }
          ok={this.handleSubmitAccessExtension}
          cancel={() => {
            this.setState({
              idPartnerToExtend: null,
              confirmModalIsOpen: false,
              allowedDatesToExtend: null
            });
          }}
        />
        <ConfirmModal
          title="Forzar bloqueo de acceso a club"
          message={
            !this.state.partnerIsBlocked
              ? "Se forzará el bloqueo de acceso a club del integrante. ¿Desea continuar?"
              : "Se quitará el bloqueo de acceso a club del integrante. ¿Desea continuar?"
          }
          isOpen={this.state.confirmForceBlockModalIsOpen}
          requireInput={false}
          ok={this.handleSubmitForceBlock}
          cancel={() => {
            this.setState({
              confirmForceBlockModalIsOpen: false,
              idPartnerToForceBlock: null,
              partnerIsBlocked: null
            });
          }}
        />
        <ConfirmModal
          title="Eliminar acceso para invitado gratis"
          message="Se eliminara el acceso para invitado gratis ¿Desea continuar?"
          isOpen={this.state.confirmDeleteFreeGuestsAccessModalIsOpen}
          requireInput={false}
          ok={this.handleFreeGuestsAccessDelete}
          cancel={() => {
            this.setState({
              idFreeGuestsAccessToDelete: null,
              confirmDeleteFreeGuestsAccessModalIsOpen: false
            });
          }}
        />

        <ConfirmModal
          title="Cambio de invitado gratis"
          message="Se cambiará la persona beneficiaria del acceso para invitado gratis. Esto puede generar un cargo. ¿Desea continuar?"
          isOpen={this.state.isChangeFreeGuestModalOpen}
          requireInput={false}
          ok={() => {
            const data = {
              membresiaSocioId: this.state.partnerMembershipIdChangeFreeGuests,
              personaId: this.state.personIdChangeFreeGuests,
              personaNombre: this.state.personNameChangeFreeGuests
            };
            this.toggleChangeFreePartner(data);

            this.setState({
              isChangeFreeGuestModalOpen: false,
              partnerMembershipIdChangeFreeGuests: null,
              personIdChangeFreeGuests: null,
              personNameChangeFreeGuests: null
            });
          }}
          cancel={() => {
            this.setState({
              isChangeFreeGuestModalOpen: false,
              partnerMembershipIdChangeFreeGuests: null,
              personIdChangeFreeGuests: null,
              personNameChangeFreeGuests: null
            });
          }}
        />

        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>Socios</p>
          </div>
        </div>

        <Row className="p-0 m-0">
          <ReactTable
            manual
            data={partnersData || []}
            columns={
              !!this.isMembershipBlocked() ||
              !!this.isMembershipBlockedNuevoFlujoVenta()
                ? this.columnsTraspaso
                : this.columns
            }
            className="w-100"
            style={{
              maxHeight: "400px"
            }}
            sortable={false}
            showPagination={false}
            defaultPageSize={(partnersData || []).length + 4}
            pageSize={(partnersData || []).length + 4}
          />
        </Row>

        {!!this.props.partner &&
          invitadosGratisDisponibles &&
          invitadosGratisDisponibles.length > 0 &&
          !this.isMembershipBlocked() &&
          !this.isMembershipBlockedNuevoFlujoVenta() && (
            <React.Fragment>
              <div className="row mt-3 mb-3">
                <div className="col">
                  <p className={`${s.primaryTitle} w-100 m-0`}>
                    Accesos invitados gratis disponibles
                  </p>
                </div>
              </div>
              <Row className="p-0 m-0">
                <ReactTable
                  manual
                  data={invitadosGratisDisponibles}
                  columns={this.columnsFreeGuests}
                  className="w-100"
                  sortable={false}
                  showPagination={false}
                  defaultPageSize={
                    (invitadosGratisDisponibles || []).length + 2
                  }
                  pageSize={(invitadosGratisDisponibles || []).length + 2}
                />
              </Row>
            </React.Fragment>
          )}
      </TabPane>
    );
  }
}

export default withStyles(s)(TabPartners);
