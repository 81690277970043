import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import FullCalendar from "./fullcalendar";
import s from "./tabCalendarStyles.scss";
import axios from "axios";
import constants from "../../../../utils/constants";
import { Row, Container, Col, TabPane, Input } from "reactstrap";
import { allowedTo } from "../../../../utils/accessTree";
import accessCodes from "../../../../utils/accessCodes";
import StatusActivityModal from "../../../components/statusActivity";
import ReactDOM from "react-dom";
import {
  USER_BASIC_INFO,
  Props,
  State,
  transformEvent
} from "./tabCalendarInterfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faPhone } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const { CONSULT_SCHEDULE } = accessCodes;

class TabCalendar extends React.Component<Props, State> {
  state = {
    changeActivityStatusModalIsOpen: false,
    changeActivityStatusModalActivityId: null,
    randomToForceReload: null
  };

  loadCalendarEventsByUserId = (start, end, _, cb) => {
    const fechaInicio = start.format("YYYY-MM-DD");
    const fechaFin = end.format("YYYY-MM-DD");
    const tipo = "AGENDA,LLAMADA,CITA";

    const params = { fechaInicio, fechaFin, tipo };
    const url = `${constants.BASE_URL}/api/activity/quotes/${this.props.selectedSeller}`;
    const config = { withCredentials: true, params };

    if (this.props.selectedSeller.length != 0) {
      axios.get(url, config).then(({ data }) => {
        cb(data.map(transformEvent));
      });
    }
  };

  handleChangeActivityStatusClick = (activityId: number) => {
    this.setState({
      changeActivityStatusModalIsOpen: true,
      changeActivityStatusModalActivityId: activityId
    });
  };

  eventRenderContent = event => {
    return [
      `<div class="timePickerCanvas">
            <b>Prospecto: </b> ${event.persona} <br>
            <b>Teléfono: </b> ${event.phone} <br>
            <b>Nota: </b> ${event.note} <br>
            <b>Estatus: </b> ${event.status}
      </div>`
    ].join("");
  };

  eventRender = (event, element, view) => {
    if (view.name === "agendaDay" || view.name === "agendaWeek") {
      element.popover({
        title: event.title,
        content: this.eventRenderContent(event),
        trigger: "hover",
        placement: "top",
        container: "body",
        html: true
      });
    }

    let markup;
    switch (view.name) {
      case "agendaDay":
        markup = (
          <div
            onClick={() =>
              this.handleChangeActivityStatusClick(event.activityId)
            }
            style={{
              backgroundColor: "#84432",
              color: "#FFF"
            }}
          >
            <span
              style={{
                backgroundColor: "#3a87ad",
                color: "#FFF",
                display: "inline-block",
                width: "100%",
                marginTop: "4px"
              }}
            >
              {event.title}
            </span>
          </div>
        );
        break;
      case "agendaWeek":
        markup = (
          <div
            onClick={() =>
              this.handleChangeActivityStatusClick(event.activityId)
            }
          >
            <FontAwesomeIcon
              style={{
                margin: "3 auto",
                display: "block",
                color: "white"
              }}
              icon={event.title == "CITA" ? faCalendarAlt : faPhone}
            />
          </div>
        );
        break;
      case "listWeek":
        markup = (
          <div
            className={`${s.agendaItemContainer}`}
            onClick={() =>
              this.handleChangeActivityStatusClick(event.activityId)
            }
          >
            <div className={`${s.header}`}>
              <Row>
                <Col>
                  <span>{event.title}</span>
                </Col>
                <Col>
                  <span>
                    {event.start.format("HH:mm")} a {event.end.format("HH:mm")}
                  </span>
                </Col>
              </Row>
            </div>
            <div className={`${s.content}`}>
              <div className="mb-2">
                <span className={`${s.label}`}>Fecha y hora registro: </span>{" "}
                {`${event.createdDate} ${event.createdTime}   /  ${event.createdByName}`}
              </div>
              <div>
                <span className={`${s.label}`}>Prospecto: </span>{" "}
                {event.persona}
              </div>
              <div>
                <span className={`${s.label}`}>Teléfono: </span> {event.phone}
              </div>
              <div>
                <span className={`${s.label}`}>Nota: </span> {event.note}
              </div>
              <div>
                <span className={`${s.label}`}>Estatus: </span>{" "}
                {(event.status || "").replaceAll("_", " ")}
              </div>
            </div>
          </div>
        );
        break;
      default:
        markup = (
          <div
            onClick={() =>
              this.handleChangeActivityStatusClick(event.activityId)
            }
          >
            Evento
          </div>
        );
        break;
    }

    ReactDOM.render(markup, element[0]);
  };

  render() {
    return (
      <TabPane className="h-100" tabId="3">
        <StatusActivityModal
          isOpen={this.state.changeActivityStatusModalIsOpen}
          activityId={this.state.changeActivityStatusModalActivityId}
          onOk={() => {
            this.setState({
              changeActivityStatusModalIsOpen: false,
              changeActivityStatusModalActivityId: null,
              randomToForceReload: moment().format("YYYYMMDDHHmmSS")
            });
          }}
          onCancel={() => {
            this.setState({
              changeActivityStatusModalIsOpen: false,
              changeActivityStatusModalActivityId: null
            });
          }}
        />

        <div className="h-100" id="calendar-container">
          {this.props.allowedClubs.length >= 1 ? (
            <Container className="px-0" fluid>
              <Row>
                <Col className="pr-1">
                  <Input
                    value={this.props.selectedClub}
                    onChange={this.props.selectClub}
                    type="select"
                    name="select"
                    id="allowedClubs"
                    // disabled={this.props.allowedClubs.length === 1}
                    className={s.allowedClubs}
                    size="xs"
                  >
                    {[
                      { clubId: -1, nombre: "Seleccione un club" },
                      ...this.props.allowedClubs
                    ].map(club => (
                      <option key={club.clubId} value={club.clubId}>
                        {club.nombre}
                      </option>
                    ))}
                  </Input>
                </Col>
                <Col className="pl-1">
                  <Input
                    value={this.props.selectedSeller}
                    onChange={this.props.selectSeller}
                    type="select"
                    name="select"
                    id="clubSellets"
                    disabled={this.props.clubSellers.length === 1}
                    className={s.allowedClubs}
                  >
                    <option key="-1" value="">
                      Seleccione un vendedor
                    </option>
                    {this.props.clubSellers.map(seller => (
                      <option key={seller.userId} value={seller.userId}>
                        {`${seller.name} ${seller.firstSurname ||
                          ""} ${seller.secondSurname || ""}`}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
            </Container>
          ) : null}

          {allowedTo(CONSULT_SCHEDULE) ? (
            <FullCalendar
              id="activities-calendar"
              header={{
                left: "prev,next,today",
                center: "title",
                rightbc: "agendaWeek,agendaDay,listWeek",
                right: "listWeek"
              }}
              titleFormat={"MMM D"}
              timeZone="UTC"
              eventRender={this.eventRender}
              createdAction={this.state.randomToForceReload}
              navLinks={true}
              eventLimit={true}
              events={this.loadCalendarEventsByUserId}
              defaultView={"listWeek"}
              firstDay={"1"}
              lastDay={"7"}
              locale={"es"}
              selectedSeller={this.props.selectedSeller}
              height={1000}
              weekends={true}
              views={{
                agenda: {
                  allDaySlot: false,
                  minTime: "06:00:00"
                }
              }}
              buttonText={{
                today: "Hoy",
                month: "Mes",
                week: "Semana",
                day: "Día",
                list: "Lista"
              }}
            />
          ) : null}
        </div>
      </TabPane>
    );
  }
}
export default withRouter(withStyles(s)(TabCalendar));
