export const hiddenForClub = formValue => {
  return (
    !!formValue.tipoBusqueda &&
    formValue.tipoBusqueda !== "EMPLEADO" &&
    formValue.tipoBusqueda !== "CLIENTE"
  );
};

export const hiddenIfNotBusquedaProducto = formValue =>
  !!formValue.tipoBusqueda;

export const hiddenIfNotBusquedaEmpleado = formValue =>
  formValue.tipoBusqueda !== "EMPLEADO";

export const hiddenIfNotBusquedaFactura = formValue =>
  formValue.tipoBusqueda !== "FACTURA";

export const hiddenIfNotBusquedaCliente = formValue =>
  formValue.tipoBusqueda !== "CLIENTE";

export const hiddenIfNotBusquedaMembresia = formValue =>
  formValue.tipoBusqueda !== "MEMBRESIA";

export const requiredIfBusquedaProducto = {
  validator: (value, formValue) => !formValue.tipoBusqueda && !value,
  message: () => "Campo obligatorio"
};

export const requiredIfBusquedaEmpleado = {
  validator: (value, formValue) =>
    formValue.tipoBusqueda === "EMPLEADO" && !value,
  message: () => "Campo obligatorio"
};

export const requiredIfBusquedaFactura = {
  validator: (value, formValue) =>
    formValue.tipoBusqueda === "FACTURA" && !value,
  message: () => "Campo obligatorio"
};

export const requiredIfBusquedaCliente = {
  validator: (value, formValue) =>
    formValue.tipoBusqueda === "CLIENTE" && !value,
  message: () => "Campo obligatorio"
};

export const requiredIfBusquedaMembresia = {
  validator: (value, formValue) =>
    formValue.tipoBusqueda === "MEMBRESIA" && !value,
  message: () => "Campo obligatorio"
};
