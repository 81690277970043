import {
  GetCmsProductosVenta,
  GetCmsVentas,
  PutCmsCoordinador,
  PutCmsInstructorPrincipal,
  PutComisionVendedor
} from "../../../reducers/comisionesV2";
import * as actions from "../../../actions/comisionesV2";
import { Preview } from "../../../../types/IAction";

export interface AVentaDispatcher {
  getCmsProductosVenta: (
    params?: any
  ) => Promise<Preview<GetCmsProductosVenta>>;
  getCmsVentas: (params?: any) => Promise<Preview<GetCmsVentas>>;
  putCmsVendedor: (
    idInstancia: any,
    body: any
  ) => Promise<Preview<PutComisionVendedor>>;
  putCmsInstructorPrincipal: (
    idInstancia: any,
    body: any
  ) => Promise<Preview<PutCmsInstructorPrincipal>>;
  putCmsCoordinador: (
    idInstancia: any,
    body: any
  ) => Promise<Preview<PutCmsCoordinador>>;
}

export const aVentaDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => AVentaDispatcher = dispatch => ({
  getCmsProductosVenta: (params?: any) => {
    return dispatch(actions.getCmsProductosVenta(params));
  },
  getCmsVentas: (params?: any) => {
    return dispatch(actions.getCmsVentas(params));
  },
  putCmsVendedor: (idInstancia: any, body: any) => {
    return dispatch(actions.putCmsVendedor(idInstancia, body));
  },
  putCmsInstructorPrincipal: (idInstancia: any, body: any) => {
    return dispatch(actions.putCmsInstructorPrincipal(idInstancia, body));
  },
  putCmsCoordinador: (idInstancia: any, body: any) => {
    return dispatch(actions.putCmsCoordinador(idInstancia, body));
  }
});
