import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";

import ModalFrame from "../../../toolkit/modalFrame";
import s from "./styles.scss";

import { Button, Container } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { editStatusActivityRequest } from "../../actions/modals";
import { handleRequestError } from "../../../utils/helpers";

const ACTIVITY_STATUS = ["COMPLETADA", "INCOMPLETA"];

interface Props {
  isOpen: boolean;
  activityId: number;
  onOk: () => void;
  onCancel: () => void;
}

interface State {
  status: string;
  editing: boolean;
}

const ACTIVITY_STATUS_CATALOG = [
  { value: "COMPLETADA", label: "COMPLETADA" },
  { value: "INCOMPLETA", label: "INCOMPLETA" },
  { value: "NO_LLEGO", label: "NO LLEGÓ" },
  { value: "REAGENDO", label: "REAGENDÓ" },
  {
    value: "NO_ENCONTRO_LO_QUE_BUSCABA",
    label: "NO ENCONTRÓ LO QUE BUSCABA"
  },
  { value: "MOTIVOS_DE_NO_COMPRA", label: "MOTIVOS DE NO COMPRA" }
];

class StatusActivityModal extends React.Component<Props, State> {
  state = {
    status: ACTIVITY_STATUS[0],
    editing: false
  };

  onSubmit = e => {
    e.preventDefault();

    this.setState({ editing: true });

    infoNotification("Actualizando el estatus del evento.");

    editStatusActivityRequest(this.props.activityId, this.state.status)
      .then(() => {
        this.setState({ status: ACTIVITY_STATUS[0], editing: false });

        successNotification("Estatus del evento actualizado correctamente");

        if (!!this.props.onOk) {
          this.props.onOk();
        }
      })
      .catch(handleRequestError);
  };

  render() {
    return (
      <ModalFrame
        title={"Cambiar estatus de la actividad"}
        isOpen={this.props.isOpen}
        toggle={() => {
          this.setState({ status: ACTIVITY_STATUS[0], editing: false });

          if (!!this.props.onCancel) {
            this.props.onCancel();
          }
        }}
      >
        <Container>
          <BaseInput
            label={"Estatus de la actividad"}
            name={"status"}
            type="select"
            id={"status"}
            placeholder="Estatus de la actividad"
            value={this.state.status}
            options={[...ACTIVITY_STATUS_CATALOG]}
            onChange={e => {
              e.preventDefault();
              const value = e.target.value;
              this.setState({
                status: value
              });
            }}
          />

          <Button
            className={`${s.buttonMarginTop} ${s.primaryButton}`}
            disabled={!!this.state.editing}
            onClick={this.onSubmit}
            block
            size="lg"
          >
            {!!this.state.editing ? "Cambiando" : "Cambiar Estatus"}
          </Button>
        </Container>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(StatusActivityModal));
