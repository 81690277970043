import React, { Component } from "react";
import {
  RouterProps,
  Styled
} from "../../../../../toolkit/decorators/Artifact";
import { CImparticionDispatcher } from "../../../../containers/comisionesDeportivaV2Carrusel/steps/CImparticion.dispatcher";
import { CImparticionStore } from "../../../../containers/comisionesDeportivaV2Carrusel/steps/CImparticion.store";
import { Container } from "reactstrap";
import FormBuilder from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder";
import { Inputs, Buttons, formConfig } from "./CImparticion.form";
import { MemoTable } from "./CImparticion.table";
import DeportivaEventoAsignado from "../../../../../types/DeportivaEventoAsignado";
import { deleteEmptyProps } from "../../../../../toolkit/utils/filter-utils";
import { errorNotification } from "../../../../../utils/notifications";
import s from "../../styles.scss";
import FormBuilderModal from "../../../common/formBuilderModal/formBuilderModal";
import ConfirmModal from "../../../common/confirmModal/confirmModal";
import { formConfigMarcar } from "./CImparticionMarcar.form";
import { formConfigClase } from "./CImparticionClase.form";
import { formConfigPrograma } from "./CImparticionPrograma.form";

interface Props
  extends CImparticionDispatcher,
    CImparticionStore,
    RouterProps {}

interface State {
  downloading: boolean;
  waiting: boolean;
  filters: Inputs & {
    page: number;
    size: number;
  };

  // Actualización del estado del evento
  rowPopup: DeportivaEventoAsignado;
  marcaPopupOpen: boolean;
  marcaPopupTitle: string;
  marcaPopupText: string;
  marcaPopupTipo: "IMPARTIDO" | "FALTA_INSTRUCTOR" | "FALTA_CLIENTE";
  marcaPopupWithForm: boolean;
  clasePopupOpen: boolean;
  clasePopupTitle: string;
  clasePopupText: string;
  programaPopupOpen: boolean;
  programaPopupTitle: string;
  programaPopupText: string;
}

@Styled(s)
export default class CImparticionComponent extends Component<Props, State> {
  state = {
    downloading: false,
    waiting: false,
    filters: {
      page: 0,
      size: 10
    } as any,

    // Actualización de empleado
    rowPopup: null as DeportivaEventoAsignado,
    marcaPopupOpen: false,
    marcaPopupTitle: "",
    marcaPopupText: "",
    marcaPopupTipo: null as "IMPARTIDO" | "FALTA_INSTRUCTOR" | "FALTA_CLIENTE",
    marcaPopupWithForm: false,
    clasePopupOpen: false,
    clasePopupTitle: "",
    clasePopupText: "",
    programaPopupOpen: false,
    programaPopupTitle: "",
    programaPopupText: ""
  };

  componentDidMount(): void {
    this.fetchData({} as any);
  }

  submit = (values: any) => {
    // Búsqueda de datos en página 1
    this.fetchData({ page: 0 } as any, null, values);
  };

  // Carga de datos para poblar la tabla
  fetchData = async (
    { pageSize: tablePageSize, page: tablePage },
    _?: any,
    formFilters?
  ) => {
    const filters = formFilters || this.state.filters;
    const page = tablePage >= 0 ? tablePage : this.state.filters.page;
    const size = tablePageSize >= 0 ? tablePageSize : this.state.filters.size;
    const pageableFilters = deleteEmptyProps({ ...filters, page, size });

    this.setState({ filters: pageableFilters });

    const preview = await this.props.getCmsClasesAsignadas(pageableFilters);
    if (!preview.fulfilled) {
      errorNotification(
        preview.gettingCmsClasesAsignadas ||
          "Error al obtener las sesiones asignadas"
      );
    }
  };

  onExport = () => {
    // TODO: Verificar si se necesita
    // this.setState({ downloading: true });
  };

  onMarcarImpartido = (row: DeportivaEventoAsignado) => {
    this.setState({
      rowPopup: row,
      marcaPopupOpen: true,
      marcaPopupTitle: "Marcar como impartido",
      marcaPopupText: "¿Está seguro de marcar esta clase como impartida?",
      marcaPopupTipo: "IMPARTIDO",
      marcaPopupWithForm: true
    });
  };

  onMarcarFaltaInstructor = (row: DeportivaEventoAsignado) => {
    this.setState({
      rowPopup: row,
      marcaPopupOpen: true,
      marcaPopupTitle: "Marcar falta de instructor",
      marcaPopupText:
        "¿Está seguro de marcar esta clase como falta de instructor?",
      marcaPopupTipo: "FALTA_INSTRUCTOR",
      marcaPopupWithForm: false
    });
  };

  onMarcarFaltaCliente = (row: DeportivaEventoAsignado) => {
    this.setState({
      rowPopup: row,
      marcaPopupOpen: true,
      marcaPopupTitle: "Marcar falta de cliente",
      marcaPopupText:
        "¿Está seguro de marcar esta clase como falta de cliente?",
      marcaPopupTipo: "FALTA_CLIENTE",
      marcaPopupWithForm: false
    });
  };

  onActualizar = (row: DeportivaEventoAsignado) => {
    if (row.idClase) {
      this.setState({
        rowPopup: row,
        clasePopupOpen: true,
        clasePopupTitle: "Reprogramar clase",
        clasePopupText: "¿Está seguro de reprogramar esta clase?"
      });
    } else {
      this.setState({
        rowPopup: row,
        programaPopupOpen: true,
        programaPopupTitle: "Reprogramar participación",
        programaPopupText: "¿Está seguro de reprogramar esta participación?"
      });
    }
  };

  marcar = async (value?) => {
    const { rowPopup: row, marcaPopupTipo } = this.state;
    let preview;

    if (marcaPopupTipo === "IMPARTIDO") {
      preview = await this.props.putCmsClasesImparticion({
        ...row,
        ...value,
        estatus: "IMPARTIDO"
      });
    } else if (marcaPopupTipo === "FALTA_INSTRUCTOR") {
      preview = await this.props.putCmsClasesImparticion({
        ...row,
        estatus: "FALTA_INSTRUCTOR"
      });
    } else if (marcaPopupTipo === "FALTA_CLIENTE") {
      preview = await this.props.putCmsClasesImparticion({
        ...row,
        estatus: "FALTA_CLIENTE"
      });
    }

    if (!preview.fulfilled) {
      errorNotification(
        preview.puttingCmsClasesImparticion ||
          "Error al actualizar la clase o programa deportivo"
      );
    }

    this.setState({ marcaPopupOpen: false });
  };

  actualizar = () => {};

  render() {
    return (
      <Container>
        <FormBuilderModal
          isOpen={this.state.marcaPopupOpen && this.state.marcaPopupWithForm}
          title={this.state.marcaPopupTitle}
          message={this.state.marcaPopupText}
          ok={this.marcar}
          cancel={() => this.setState({ marcaPopupOpen: false })}
          formConfig={formConfigMarcar}
          size={"md"}
        />
        <FormBuilderModal
          isOpen={this.state.clasePopupOpen}
          title={this.state.clasePopupTitle}
          message={this.state.clasePopupText}
          ok={this.actualizar}
          cancel={() => this.setState({ clasePopupOpen: false })}
          formConfig={formConfigClase}
          size={"md"}
        />
        <FormBuilderModal
          isOpen={this.state.programaPopupOpen}
          title={this.state.programaPopupTitle}
          message={this.state.programaPopupText}
          ok={this.actualizar}
          cancel={() => this.setState({ programaPopupOpen: false })}
          formConfig={formConfigPrograma}
          size={"md"}
        />
        <ConfirmModal
          isOpen={this.state.marcaPopupOpen && !this.state.marcaPopupWithForm}
          title={this.state.marcaPopupTitle}
          message={this.state.marcaPopupText}
          ok={this.marcar}
          cancel={() => this.setState({ marcaPopupOpen: false })}
          size={"md"}
          waiting={this.props.puttingCmsClasesImparticion}
        />

        <FormBuilder<Inputs, Buttons>
          config={formConfig}
          submit={this.submit}
          processing={this.props.gettingCmsClasesAsignadas}
        />

        <hr className="row" />

        <MemoTable
          page={
            this.props.cmsClasesAsignadas ||
            ({} as Page<DeportivaEventoAsignado>)
          }
          loading={
            this.state.downloading || this.props.gettingCmsClasesAsignadas
          }
          onFetchData={this.fetchData}
          onExport={this.onExport}
          onMarcarImpartido={this.onMarcarImpartido}
          onMarcarFaltaInstructor={this.onMarcarFaltaInstructor}
          onMarcarFaltaCliente={this.onMarcarFaltaCliente}
          onActualizar={this.onActualizar}
        />
      </Container>
    );
  }
}
