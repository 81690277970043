import React from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import IButtonProps from "./IButton.props";
import IButtonState from "./IButton.state";

export default class IButton extends React.Component<
  IButtonProps,
  IButtonState
> {
  public static get whoIAm() {
    return "IButton";
  }

  btsClassName = () => {
    const p = this.props;
    let className: string = "";
    className =
      p.xCol || p.xCol == 0 ? className.concat(`col-${p.xCol} `) : className;
    className =
      p.sCol || p.sCol == 0 ? className.concat(`col-sm-${p.sCol} `) : className;
    className =
      p.mCol || p.mCol == 0 ? className.concat(`col-md-${p.mCol} `) : className;
    className =
      p.lCol || p.lCol == 0 ? className.concat(`col-lg-${p.lCol} `) : className;
    className =
      p.xOff || p.xOff == 0 ? className.concat(`offset-${p.xOff} `) : className;
    className =
      p.sOff || p.sOff == 0
        ? className.concat(`offset-sm-${p.sOff} `)
        : className;
    className =
      p.mOff || p.mOff == 0
        ? className.concat(`offset-md-${p.mOff} `)
        : className;
    className =
      p.lOff || p.lOff == 0
        ? className.concat(`offset-lg-${p.lOff} `)
        : className;

    className = className || "col ";
    return p.classCol ? className + `${p.classCol}` : className;
  };

  onClick = () => {
    if (this.props.onClick) {
      const values = this.props.formValues && this.props.formValues();
      this.props.onClick(values);
    }
  };

  render() {
    if (this.props.hidden) {
      return null;
    }

    const btnClass =
      this.props.addTopMargin !== false
        ? `${this.props.buttonClass || ""}mt-4 btn-block`
        : `${this.props.buttonClass || ""}mt-2 btn-block`;

    return (
      <div className={this.btsClassName()}>
        <Button
          size="sm"
          className={btnClass}
          onClick={this.onClick}
          disabled={this.props.disabled || false}
          type={this.props.isSubmit ? "submit" : "button"}
        >
          {this.props.processing ? (
            <FontAwesomeIcon
              icon={faSpinner}
              style={{ color: "#fff", fontSize: "1.1em" }}
              pulse={true}
            />
          ) : (
            this.props.label
          )}
        </Button>
      </div>
    );
  }
}
