import { Preview } from "../../../../types/IAction";
import {
  GetCmsDeportivaAutorizacion,
  PutCmsDeportivaAutorizar,
  PutCmsDeportivaRechazo,
  PutCmsDeportivaReasignacion
} from "../../../reducers/comisionesV2";
import * as actions from "../../../actions/comisionesV2";

export interface DAutorizacionDispatcher {
  getCmsDeportivaAutorizacion: (
    params?: any
  ) => Promise<Preview<GetCmsDeportivaAutorizacion>>;
  putCmsDeportivaAutorizar: (
    body: any
  ) => Promise<Preview<PutCmsDeportivaAutorizar>>;
  putCmsDeportivaRechazo: (
    id: any,
    body: any
  ) => Promise<Preview<PutCmsDeportivaRechazo>>;
  putCmsDeportivaReasignacion: (
    id: any,
    body: any
  ) => Promise<Preview<PutCmsDeportivaReasignacion>>;
}

export const dAutorizacionDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => DAutorizacionDispatcher = dispatch => ({
  getCmsDeportivaAutorizacion: (params?: any) => {
    return dispatch(actions.getCmsDeportivaAutorizacion(params));
  },
  putCmsDeportivaAutorizar: (body: any) => {
    return dispatch(actions.putCmsDeportivaAutorizar(body));
  },
  putCmsDeportivaRechazo: (id: any, body: any) => {
    return dispatch(actions.putCmsDeportivaRechazar(id, body));
  },
  putCmsDeportivaReasignacion: (id: any, body: any) => {
    return dispatch(actions.putCmsDeportivaReasignar(id, body));
  }
});
