import FormBuilderConfig from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IDateBuilder from "../../../../../toolkit/baseForms2/iDateBuilder/IDateBuilder";
import ISelectBuilder from "../../../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import IClubSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iClubSelectorBuilder/IClubSelectorBuilder";
import IUserSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";
import * as validators from "./CImparticion.validators";

export type Inputs =
  | "tipoBusqueda"
  | "idClub"
  | "fechaInicio"
  | "fechaFin"
  | "anio"
  | "mes"
  | "idEmpleado"
  | "folioFactura";
export type Buttons = "buscar";

const anioActual = new Date().getFullYear();

export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "tipoBusqueda",
      label: "* Tipo de búsqueda",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Seleccione una opción",
        options: [
          { value: "", label: "Asignados" },
          { value: "FECHA", label: "Fecha asignada (Clases personalizadas)" },
          { value: "PERIODO", label: "Periodo (Programas deportivos)" },
          { value: "EMPLEADO", label: "Empleado" },
          { value: "FACTURA", label: "Factura" }
        ]
      }
    },
    {
      name: "idClub",
      label: "* Club",
      bts: {
        mCol: 12
      },
      component: IClubSelectorBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaFecha
      }
    },
    {
      name: "fechaInicio",
      label: "* Fecha de inicio",
      bts: {
        mCol: 12
      },
      component: IDateBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaFecha
      }
    },
    {
      name: "fechaFin",
      label: "* Fecha de fin",
      bts: {
        mCol: 12
      },
      component: IDateBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaFecha
      }
    },
    {
      name: "anio",
      label: "* Periodo",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Año",
        options: [
          { value: anioActual - 1, label: anioActual - 1 },
          { value: anioActual, label: anioActual },
          { value: anioActual + 1, label: anioActual + 1 }
        ]
      }
    },
    {
      name: "mes",
      label: "",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        options: [
          monthOption(-3),
          monthOption(-2),
          monthOption(-1),
          monthOption(0),
          monthOption(1)
        ]
      }
    },
    {
      name: "idEmpleado",
      label: "* Empleado",
      bts: {
        mCol: 12
      },
      component: IUserSelectorBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaEmpleado
      }
    },
    {
      name: "folioFactura",
      label: "* Folio de factura",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Seleccione una opción",
        options: [],
        hidden: validators.hiddenIfNotBusquedaFactura
      }
    }
  ],
  buttons: [
    {
      name: "buscar",
      label: "Buscar",
      isSubmit: true,
      bts: {
        mCol: 4
      }
    }
  ],
  validations: {
    tipoBusqueda: {
      required: true
    },
    idClub: {
      requiredIfBusquedaFecha: validators.requiredIfBusquedaFecha
    },
    fechaInicio: {
      requiredIfBusquedaFecha: validators.requiredIfBusquedaFecha
    },
    fechaFin: {
      requiredIfBusquedaFecha: validators.requiredIfBusquedaFecha
    },
    idEmpleado: {
      requiredIfBusquedaEmpleado: validators.requiredIfBusquedaEmpleado
    },
    folioFactura: {
      requiredIfBusquedaFactura: validators.requiredIfBusquedaFactura
    }
  },
  rows: []
};

function monthOption(months: number) {
  const fechaNueva = new Date();
  fechaNueva.setMonth(fechaNueva.getMonth() + months);
  return {
    value: fechaNueva.getMonth(),
    label: fechaNueva.toLocaleString("es-MX", { month: "long" }).toUpperCase()
  };
}
