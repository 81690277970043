import FormBuilderConfig from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import ISelectBuilder from "../../../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import ITextBuilder from "../../../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import IClubSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iClubSelectorBuilder/IClubSelectorBuilder";
import IUserSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";
import * as validators from "./DAutorizacion.validators";

export type Inputs =
  | "tipoBusqueda"
  | "idClub"
  | "periodo"
  | "idEmpleado"
  | "folioFactura"
  | "estatus";
export type Buttons = "buscar";

export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "tipoBusqueda",
      label: "* Tipo de búsqueda",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Seleccione una opción",
        options: [
          { value: "PERIODO", label: "Periodo" },
          { value: "EMPLEADO", label: "Empleado" },
          { value: "FACTURA", label: "Factura" }
        ]
      }
    },
    {
      name: "idClub",
      label: "Club",
      bts: {
        mCol: 12
      },
      component: IClubSelectorBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaPeriodo
      }
    },
    {
      name: "periodo",
      label: "* Producto",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Seleccione",
        options: getPeriodos(new Date()),
        hidden: validators.hiddenIfNotBusquedaPeriodo
      }
    },
    {
      name: "idEmpleado",
      label: "* Empleado",
      bts: {
        mCol: 12
      },
      component: IUserSelectorBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaEmpleado
      }
    },
    {
      name: "folioFactura",
      label: "* Folio de factura",
      bts: {
        mCol: 12
      },
      component: ITextBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaFactura
      }
    },
    {
      name: "estatus",
      label: "Estatus",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Seleccione",
        options: [
          { value: "AUTORIZADA", label: "Autorizada" },
          { value: "RECHAZADA", label: "Rechazada" },
          { value: "PENDIENTE", label: "Pendiente" },
          { value: "ERRONEA", label: "Errónea" }
        ],
        hidden: validators.hiddenIfNotBusquedaFactura
      }
    }
  ],
  buttons: [
    {
      name: "buscar",
      label: "Buscar",
      isSubmit: true,
      bts: {
        mCol: 4
      }
    }
  ],
  validations: {
    tipoBusqueda: {
      required: true
    },
    idClub: {
      requiredIfBusquedaProducto: validators.requiredIfBusquedaPeriodo
    },
    periodo: {
      requiredIfBusquedaProducto: validators.requiredIfBusquedaPeriodo
    },
    idEmpleado: {
      requiredIfBusquedaEmpleado: validators.requiredIfBusquedaEmpleado
    },
    folioFactura: {
      requiredIfBusquedaFactura: validators.requiredIfBusquedaFactura
    }
  },
  rows: []
};

export function getPeriodos(fecha: Date) {
  const periodos = [];
  const primerDia = fecha.getDate() >= 16 ? 16 : 1;

  for (let i = -1; i < 3; i++) {
    if (primerDia === 1) {
      periodos.push(
        optionPeriodo(new Date(fecha.setMonth(fecha.getMonth() - i, 16)))
      );
      periodos.push(
        optionPeriodo(new Date(fecha.setMonth(fecha.getMonth() - (i + 1), 1)))
      );
    } else {
      periodos.push(
        optionPeriodo(new Date(fecha.setMonth(fecha.getMonth() - i, 1)))
      );
      periodos.push(
        optionPeriodo(new Date(fecha.setMonth(fecha.getMonth() - i, 16)))
      );
    }
  }
  return periodos;
}

function optionPeriodo(firstDay: Date) {
  const lastDay = new Date(
    firstDay.getFullYear(),
    firstDay.getMonth(),
    firstDay.getDate() === 1 ? 15 : 0
  );
  const mes = firstDay.toLocaleDateString("es-MX", { month: "long" });

  return {
    value: firstDay,
    label: `Del ${firstDay.getDate()} al ${lastDay.getDate()} de ${mes} de ${firstDay.getFullYear()}`
  };
}
