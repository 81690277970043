import FormBuilderConfig from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import ISelectBuilder from "../../../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import IUserSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";
import { userHasPermission } from "../../../../../utils/accessTree";
import permissionCodes from "../../../../../utils/permissionCodes";

const {
  COMISIONES_V2_IMPARTICION_EVENTO_IGNORAR_PUESTO: IGNORAR_PUESTO,
  COMISIONES_V2_IMPARTICION_EVENTO_IGNORAR_CODIGO: IGNORAR_CODIGO
} = permissionCodes;

export type Inputs =
  | "ignorarCodigo"
  | "codigo"
  | "razonIgnorarCodigo"
  | "ignorarPuesto"
  | "razonIgnorarPuesto";
export type Buttons = "aceptar" | "cancelar";

export const formConfigMarcar: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "ignorarCodigo",
      label: "* Ignorar código",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "No",
        options: [{ value: "true", label: "Si" }],
        hidden: () => userHasPermission(IGNORAR_CODIGO)
      }
    },
    {
      name: "codigo",
      label: "* Código del usuario",
      bts: {
        mCol: 12
      },
      component: IUserSelectorBuilder
    },
    {
      name: "razonIgnorarCodigo",
      label: "Razón",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Seleccione una opción",
        options: [
          {
            value: "El cliente no tiene la aplicación móvil",
            label: "El cliente no tiene la aplicación móvil"
          },
          {
            value: "El cliente no admite la (in)asistencia",
            label: "El cliente no admite la (in)asistencia"
          },
          {
            value: "El cliente no se pudo contactar",
            label: "El cliente no se pudo contactar"
          }
        ],
        hidden: () => userHasPermission(IGNORAR_PUESTO),
        disabled: formValue => formValue.ignorarPuesto !== "true"
      }
    },
    {
      name: "ignorarPuesto",
      label: "Ignorar puesto de trabajo",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "No",
        options: [{ value: "true", label: "Si" }],
        hidden: () => userHasPermission(IGNORAR_PUESTO)
      }
    },
    {
      name: "razonIgnorarPuesto",
      label: "Razón",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Seleccione una opción",
        options: [
          {
            value: "Personal no cubierto en el puesto",
            label: "Personal no cubierto en el puesto"
          },
          {
            value: "Baja inesperada de colaborador(a)",
            label: "Baja inesperada de colaborador(a)"
          },
          {
            value: "Excepción aceptada por T. y C.",
            label: "Excepción aceptada por T. y C."
          }
        ],
        hidden: () => userHasPermission(IGNORAR_PUESTO),
        disabled: formValue => formValue.ignorarPuesto !== "true"
      }
    }
  ],
  buttons: [
    {
      name: "aceptar",
      label: "Aceptar",
      isSubmit: true,
      bts: {
        mCol: 4
      }
    },
    {
      name: "cancelar",
      label: "Cancelar",
      bts: {
        mCol: 4
      }
    }
  ],
  validations: {
    codigo: {
      requiredIfNotIgnorarCodigo: {
        validator: (value, formValue) =>
          formValue.ignorarCodigo !== "true" && !value,
        message: () => "Debe proporcionarlo el cliente"
      }
    },
    razonIgnorarCodigo: {
      requiredIfIgnorarCodigo: {
        validator: (value, formValue) =>
          formValue.ignorarCodigo === "true" && !value,
        message: () => "Campo obligatorio"
      }
    },
    razonIgnorarPuesto: {
      requiredIfIgnorarPuesto: {
        validator: (value, formValue) =>
          formValue.ignorarPuesto === "true" && !value,
        message: () => "Campo obligatorio"
      }
    }
  },
  rows: []
};
