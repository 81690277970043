import { Preview } from "../../../../types/IAction";
import {
  GetCmsClasesAsignadas,
  PutCmsClasesImparticion
} from "../../../reducers/comisionesV2";
import * as actions from "../../../actions/comisionesV2";

export interface CImparticionDispatcher {
  getCmsClasesAsignadas: (
    params?: any
  ) => Promise<Preview<GetCmsClasesAsignadas>>;
  putCmsClasesImparticion: (
    body: any
  ) => Promise<Preview<PutCmsClasesImparticion>>;
}

export const cImparticionDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => CImparticionDispatcher = dispatch => ({
  getCmsClasesAsignadas: (params?: any) => {
    return dispatch(actions.getCmsClasesAsignadas(params));
  },
  putCmsClasesImparticion: (body: any) => {
    return dispatch(actions.putCmsClasesImparticion(body));
  }
});
