import React, { Component } from "react";
import {
  ReduxConnect,
  Router,
  Routing
} from "../../../toolkit/decorators/Artifact";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import Carousel2 from "../../../toolkit/carousel2/Carousel2";
import AVentaContainer from "./steps/AVenta";
import BAsignacionContainer from "./steps/BAsignacion";
import CImparticionContainer from "./steps/CImparticion";
import DAutorizacionContainer from "./steps/DAutorizacion";

interface Props {}
interface State {
  step: number;
}

@Router()
@Routing("/comisiones-deportiva")
export default class ComisionesDeportivaCarrusel extends Component<
  Props,
  State
> {
  componentDidMount(): void {
    // Inicializar el paso
    this.setState({ step: 3 });
  }

  render() {
    return (
      <main>
        <Layout>
          <Helmet title="Comisiones" />
          <Carousel2 active={true} step={1}>
            <AVentaContainer
              data-carousel-name="Información de venta"
              {...({} as any)}
            />

            {/* Comentarios permitidos */}
            <BAsignacionContainer
              data-carousel-name="Asignación de clases"
              data-carousel-enabled={true}
              data-carousel-disabled-tooltip="No tiene permisos para asignar clases"
              {...({} as any)}
            />
            <CImparticionContainer
              data-carousel-name="Confirmación de impartición"
              data-carousel-enabled={true}
              data-carousel-disabled-tooltip="No tiene permisos para confirmar la impartición"
              {...({} as any)}
            />
            <DAutorizacionContainer
              data-carousel-name="Autorización de comisiones"
              data-carousel-enabled={true}
              data-carousel-disabled-tooltip="No tiene permisos para autorizar comisiones"
              {...({} as any)}
            />
          </Carousel2>
        </Layout>
      </main>
    );
  }
}
