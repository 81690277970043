exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "span._5NYqo{cursor:pointer}span._3aS-z,span._5NYqo{font-size:16px;margin:0 8px}.-s5vh{background:#f9fad2}._3HLmD{color:#700;font-weight:700;background:#f3d8d8}._31ZzA{padding:0!important}._30Nhu{padding:7px 3px}.xBZfz div{overflow-x:hidden!important}.xBZfz div div div div{overflow:hidden!important}", ""]);

// exports
exports.locals = {
	"btn": "_5NYqo",
	"info": "_3aS-z",
	"noComisionable": "-s5vh",
	"errorComision": "_3HLmD",
	"tdClear": "_31ZzA",
	"field": "_30Nhu",
	"hidden": "xBZfz"
};