import React from "react";
import { Col, Container, Row, TabPane, Button } from "reactstrap";
import { userHasPermission } from "../../../../utils/accessTree";
import ReferredCodesDetailTable from "./referredCodesDetailTable";
import ReferredDetailTable from "./referredDetailTable";
import AssignReferredUntimelyModal from "./assignReferredUntimelyModal/assignReferredUntimelyModal";
import permissionCodes from "../../../../utils/permissionCodes";
import { Club } from "../../../../types-business/Clubs";

interface Props {
  referredCodesDetail: any[];
  referredDetail: any[];
  idMembresiaSocio: string;
  clubs: Club[];
  invalidateStateValue: (fieldName: string, value: any) => void;
  createReferredCodes: () => void;
  getMembershipReferredDetail: () => void;
  getMembershipReferredCodesDetail: () => void;
}

interface State {
  assignReferredUntimelyModalIsOpen: boolean;
}

class TabReferred extends React.Component<Props, State> {
  state = {
    assignReferredUntimelyModalIsOpen: false
  };

  componentDidMount() {}

  render() {
    const {
      referredCodesDetail,
      referredDetail,
      idMembresiaSocio,
      createReferredCodes
    } = this.props;
    return (
      <TabPane className="my-2" tabId="22">
        <Container className="p-0 m0">
          {userHasPermission(
            permissionCodes.PARTNER_MEMBERSHIP_TAB_REFERRED_UNTIMELY
          ) && (
            <AssignReferredUntimelyModal
              clubs={this.props.clubs}
              idMembresiaSocio={this.props.idMembresiaSocio}
              isOpen={this.state.assignReferredUntimelyModalIsOpen}
              cancel={() => {
                this.setState({ assignReferredUntimelyModalIsOpen: false });
              }}
              success={() => {
                this.setState({ assignReferredUntimelyModalIsOpen: false });
                this.props.invalidateStateValue("referredDetail", []);
                this.props.invalidateStateValue("referredCodesDetail", []);
                this.props.getMembershipReferredCodesDetail();
                this.props.getMembershipReferredDetail();
              }}
            />
          )}

          <Row>
            <Col md="6">
              <div className="m-4">
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_REFERRED_UNTIMELY
                ) && (
                  <Button
                    className="bg-info"
                    size="sm"
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        assignReferredUntimelyModalIsOpen: true
                      });
                    }}
                  >
                    Asociar referido extemporáneo
                  </Button>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={3} lg={3}>
              <ReferredCodesDetailTable
                referredCodesDetailList={referredCodesDetail || []}
                partnerMembershipId={idMembresiaSocio}
                createReferredCodes={createReferredCodes}
              />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <ReferredDetailTable
                referredDetailList={referredDetail || []}
                partnerMembershipId={idMembresiaSocio}
              />
            </Col>
          </Row>
        </Container>
      </TabPane>
    );
  }
}

export default TabReferred;
