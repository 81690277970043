export const hiddenIfNotBusquedaProducto = formValue =>
  !!formValue.tipoBusqueda;

export const hiddenIfNotBusquedaEmpleado = formValue =>
  formValue.tipoBusqueda !== "EMPLEADO";

export const hiddenIfNotBusquedaFactura = formValue =>
  formValue.tipoBusqueda !== "FACTURA";

export const requiredIfBusquedaProducto = {
  validator: (value, formValue) => !formValue.tipoBusqueda && !value,
  message: () => "Campo obligatorio"
};

export const requiredIfBusquedaEmpleado = {
  validator: (value, formValue) =>
    formValue.tipoBusqueda === "EMPLEADO" && !value,
  message: () => "Campo obligatorio"
};

export const requiredIfBusquedaFactura = {
  validator: (value, formValue) =>
    formValue.tipoBusqueda === "FACTURA" && !value,
  message: () => "Campo obligatorio"
};
