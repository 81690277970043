import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import {
  Alert,
  Collapse,
  ListGroup,
  ListGroupItem,
  Container,
  Row,
  Col
} from "reactstrap";
import ModalFrame from "../../../toolkit/modalFrame";
import s from "./styles.scss";
import { Prospect } from "../../../types-business/User";
import debounce from "lodash.debounce";
import { ProspectArguments } from "../../../types/Prospects";
import CONSTANTS from "../../../utils/constants";
import PeopleBasicData, {
  ProspectToEdit
} from "../../../People/components/PeopleBasicData";
import isNil from "lodash.isnil";
import { getPersonsRequest } from "../../../Home/actions/persons";
import { getEmpresasConvenioRequest } from "../../actions/catalogs";
import { handleRequestError } from "../../../utils/helpers";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  createProspect: (prospect: object) => any;
  editProspect: (personaId: string, prospect: Prospect) => any;
  history: any;
  client: any;
  userId: string;
  creatingProspect: boolean;
  creatingProspectError: any;
  prospectToEdit: ProspectToEdit;
  getProspects: (args: ProspectArguments) => any;
  repeatedProspects: ProspectToEdit[];
  setProspectToEdit: (prospect: object) => any;
}

interface State {
  editing: boolean;
  collapse: boolean;
  visible: boolean;
  prospectData: ProspectToEdit;
  repeatedPersons: any[];
  empresasConvenio: any[];
}

class CreateBasicDataProspect extends React.Component<Props, State> {
  state = {
    editing: false,
    collapse: false,
    visible: false,
    prospectData: null,
    repeatedPersons: [],
    empresasConvenio: null
  };

  onDismiss = () => {
    this.setState({ visible: !this.state.visible });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.userContext && nextProps.isOpen) {
      this.props.toggle();
    }
  }

  componentDidMount(): void {
    if ((this.state.empresasConvenio || []).length == 0) {
      this.getAggreementsCompanies();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isOpen != prevProps.isOpen) {
      this.setState({ repeatedPersons: [] });
    }

    if (!this.props.isOpen && this.state.editing) {
      this.setState(prevState => {
        return {
          ...prevState,
          editing: false
        };
      });
    }

    if (this.props.isOpen && !this.state.editing) {
      let visible = this.state.visible;
      if (this.props.prospectToEdit || this.state.prospectData) {
        visible = !this.props.prospectToEdit;
      } else {
        visible = false;
      }

      this.setState(prevState => {
        return {
          ...prevState,
          visible,
          editing: true
        };
      });
    }

    if (
      !isNil(this.state.prospectData) &&
      !isNil(prevState.prospectData) &&
      prevState.prospectData !== this.state.prospectData &&
      !this.props.prospectToEdit
    ) {
      const nombre = this.state.prospectData.nombre;
      const primerApellido = this.state.prospectData.primerApellido;
      const segundoApellido = this.state.prospectData.segundoApellido;
      const fechaNacimiento = this.state.prospectData.fechaNacimiento;
      const curp = this.state.prospectData.curp;
      const prevNombre = prevState.prospectData.nombre;
      const prevPrimerApellido = prevState.prospectData.primerApellido;
      const prevSegundoApellido = prevState.prospectData.segundoApellido;
      const prevFechaNacimiento = prevState.prospectData.fechaNacimiento;
      const prevCurp = prevState.prospectData.curp;

      if (
        nombre &&
        primerApellido &&
        segundoApellido &&
        fechaNacimiento &&
        ((!!nombre &&
          primerApellido &&
          segundoApellido &&
          nombre !== prevNombre) ||
          primerApellido !== prevPrimerApellido ||
          segundoApellido !== prevSegundoApellido ||
          fechaNacimiento !== prevFechaNacimiento)
      ) {
        this.debouncedGetProspects({
          nombre,
          fechaNacimiento,
          apellido: primerApellido,
          apellidoMaterno: segundoApellido,
          curp
        });
      }
    }
  }

  getAggreementsCompanies = () => {
    if ((this.state.empresasConvenio || []).length <= 0) {
      getEmpresasConvenioRequest()
        .then(({ data }) => {
          this.setState({
            empresasConvenio: data.filter(item => item.convenioId != null)
          });
        })
        .catch(handleRequestError);
    }
  };

  getRepeatedPersons = () => {
    const nombre = this.state.prospectData.nombre;
    const primerApellido = this.state.prospectData.primerApellido;
    const segundoApellido = this.state.prospectData.segundoApellido;
    const fechaNacimiento = this.state.prospectData.fechaNacimiento;
    const curp = this.state.prospectData.curp;

    getPersonsRequest({
      nombre,
      fechaNacimiento,
      apellido: primerApellido,
      apellidoMaterno: segundoApellido,
      curp,
      tipo: "TODOS"
    }).then(({ data: repeatedPersons }) => {
      this.setState({
        repeatedPersons,
        visible: !!(repeatedPersons || []).length
      });
    });
  };

  debouncedGetProspects = debounce(
    this.getRepeatedPersons,
    CONSTANTS.DEBOUNCE_INPUT_TIME
  );

  onClickProspects = (e, prospectData) => {
    e.preventDefault();
    this.props.setProspectToEdit(prospectData);
    this.setState({ editing: false });
  };

  setProspectData = prospectData => {
    this.setState({ prospectData });
  };

  componentWillUnmount() {
    if (this.props.isOpen) this.props.toggle();
  }

  render() {
    return (
      <ModalFrame
        title={`${this.props.prospectToEdit ? "Editar" : "Crear"} Prospecto`}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        size="lg"
      >
        {(this.state.repeatedPersons || []).length !== 0 && (
          <Alert
            color="danger"
            isOpen={this.state.visible}
            toggle={this.onDismiss}
          >
            <h5>PERSONAS EXISTENTES</h5>
            <ListGroup>
              {(this.state.repeatedPersons || []).map(prospect => (
                <ListGroupItem
                  key={prospect.personaId}
                  tag="a"
                  href="#"
                  action
                  onClick={e => {
                    // this.onClickProspects(e, prospect)
                    window.open(
                      `productos/carrito/${prospect.personaId}`,
                      "_blank"
                    );
                  }}
                >
                  <Container fluid>
                    <Row>
                      <Col>
                        {prospect.nombre} {prospect.primerApellido}{" "}
                        {prospect.segundoApellido}
                      </Col>
                      <Col>{prospect.fechaNacimiento}</Col>
                      <Col>
                        {prospect.correosElectronicos[0]
                          ? prospect.correosElectronicos[0].correo
                          : null}
                      </Col>
                      <Col>
                        {prospect.telefonos[0]
                          ? prospect.telefonos[0].numero
                          : null}
                      </Col>
                      <Col>{prospect.tipo}</Col>
                    </Row>
                  </Container>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Alert>
        )}
        <Collapse isOpen={this.state.collapse} />
        {this.props.isOpen &&
          (this.state.empresasConvenio || []).length > 0 && (
            <PeopleBasicData
              prospectToEdit={this.props.prospectToEdit}
              createProspect={this.props.createProspect}
              editProspect={this.props.editProspect}
              userId={this.props.userId}
              creatingProspect={this.props.creatingProspect}
              creatingProspectError={this.props.creatingProspectError}
              onChange={this.setProspectData}
              empresasConvenio={this.state.empresasConvenio}
              debouncedGetProspects={this.debouncedGetProspects}
            />
          )}
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(CreateBasicDataProspect));
