import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import { Container } from "reactstrap";
import s from "./styles.scss";
import { getClubsRequest, getClubsRequestBe } from "../../actions/catalogs";
import { handleRequestError } from "../../../utils/helpers";
import { Props, State } from "./interfaces";
import {
  getAfiliaciones,
  updateActiveForCat,
  updateActivateZumaGarantia,
  putAfiliaciones,
  getMitTerminalCredentialsByClubIdRequest,
  saveMitTerminalCredentialsByClubIdRequest
} from "../../actions/clubs";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import {
  faArrowAltCircleRight,
  faCashRegister,
  faCheckCircle,
  faCreditCard,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { autChargesExecuteProcess } from "../../actions/products";
import SideActionButton from "../common/sideActionButton";
import { userHasPermission } from "../../../utils/accessTree";
import permissionCodes from "../../../utils/permissionCodes";
import FormBuilderModal from "../common/formBuilderModal/formBuilderModal";
import { afiliacionesFormConfig } from "./afiliacionesFormConfig";
import { errorNotification } from "../../../utils/notifications";
import { credencialesMitTpvFormConfig } from "./credencialesMitTpvFormConfig";
import BaseButtonWithIconAndTooltip from "../../../toolkit/baseButtonWithIconAndTooltip";

class CatClubConfig extends React.Component<Props, State> {
  state = {
    onSearch: false,
    clubs: [],
    data: [],
    afiliacionesShow: false,
    afiliaciones: null,
    afiliacionClub: null,
    afiliacionTdc1: null,
    afiliacionTdc2: null,
    afiliacionAmex: null,
    mitCredentialsIsOpen: false,
    mitCredentialsClubId: null,
    mitCredentialsValues: null
  };

  componentDidMount() {
    this.getClubsCatalog();
  }

  getClubsCatalog = () => {
    getClubsRequestBe()
      .then(({ data }) => {
        this.setState({ clubs: (data || []).filter(c => !!c.migradoCrm2) });
      })
      .catch(handleRequestError);
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Clave</p>,
      Cell: row => {
        return <p>{row.original.claveCorta}</p>;
      },
      width: 200
    },
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      Cell: row => {
        return <p>{row.original.nombre}</p>;
      },
      width: 400
    },
    {
      Header: () => <p className="font-weight-bold">Credenciales TPV</p>,
      Cell: row => (
        <div style={{ fontSize: "1.2em" }}>
          {userHasPermission(
            permissionCodes.REPORT_CLUBS_CAT_CONFIG_ACTIVATE_CLUB_FOR_CAT_PROCESS
          ) && (
            <BaseButtonWithIconAndTooltip
              id={`tpvmit-${row.original.clubId}`}
              icon={faCashRegister}
              tooltipTitle="Configuración credenciales TPV"
              onClick={() => {
                getMitTerminalCredentialsByClubIdRequest(row.original.clubId)
                  .then(({ data }) => {
                    this.setState({
                      mitCredentialsClubId: row.original.clubId,
                      mitCredentialsValues: data || {
                        mitUser: "",
                        mitPassword: ""
                      },
                      mitCredentialsIsOpen: true
                    });
                  })
                  .catch(handleRequestError);
              }}
            />
          )}
        </div>
      ),
      width: 150
    },
    {
      Header: () => <p className="font-weight-bold">Proceso automático CAT</p>,
      Cell: row => (
        <div style={{ fontSize: "1.2em" }}>
          {userHasPermission(
            permissionCodes.REPORT_CLUBS_CAT_CONFIG_ACTIVATE_CLUB_FOR_CAT_PROCESS
          ) && (
            <SideActionButton
              buttonId={`activate_${row.original.clubId}`}
              tooltipTitle={
                !!row.original.activoProcesoCat ? "Desactivar" : " Activar"
              }
              icon={
                !!row.original.activoProcesoCat ? faTimesCircle : faCheckCircle
              }
              color={!!row.original.activoProcesoCat ? "#710" : "#070"}
              onClick={() =>
                this.activateCatProcess(
                  row.original.clubId,
                  !row.original.activoProcesoCat
                )
              }
              success={null}
              confirmModal={{
                title: !!row.original.activoProcesoCat
                  ? "Desactivar para proceso CAT"
                  : " Activar para proceso CAT",
                message: !!row.original.activoProcesoCat
                  ? `¿Desea desactivar el club ${row.original.nombre} para el proceso automátco de CAT?`
                  : `¿Desea activar el club ${row.original.nombre} para el proceso automátco de CAT?`
              }}
            />
          )}
          {userHasPermission(
            permissionCodes.REPORT_CLUBS_CAT_CONFIG_EXECUTE_MANUAL_CAT_PROCESS
          ) &&
            !!row.original.activoProcesoCat && (
              <SideActionButton
                buttonId={`execute${row.original.clubId}`}
                tooltipTitle={"Ejecutar manualmente"}
                icon={faArrowAltCircleRight}
                color={"#000"}
                onClick={() =>
                  this.executeCatProcessForClub(
                    row.original.clubId,
                    row.original.clubNombre
                  )
                }
                success={null}
                confirmModal={{
                  title: "Ejecución proceso CAT",
                  message: `¿Desea ejecutar manualmente el proceso de CAT para el club ${row.original.nombre}?`
                }}
              />
            )}
          {userHasPermission(
            permissionCodes.REPORT_CLUBS_CAT_CONFIG_ACTIVATE_CLUB_FOR_CAT_PROCESS
          ) && (
            <SideActionButton
              buttonId={`afiliaciones_${row.original.clubId}`}
              tooltipTitle="Afiliaciones"
              icon={faCreditCard}
              color={"#0059b3"}
              onClick={async () => {
                getAfiliaciones(row.original.clubId)
                  .then(response => {
                    const afiliaciones: any = {};
                    let afiliacionTdc1 = null;
                    let afiliacionTdc2 = null;
                    let afiliacionAmex = null;

                    response.data.forEach(a => {
                      switch (a.servicio) {
                        case "CARGO_AUTOMATICO_TDC":
                          if (a.tipoAfiliacion == "ORIGINAL") {
                            afiliacionTdc1 = a.idAfiliacion;
                            afiliaciones.tdc1Afiliacion = a.afiliacion;
                            afiliaciones.tdc1Buzon = a.idBuzon;
                            afiliaciones.tdc1Sucursal = a.idSucursal;
                          } else if (a.tipoAfiliacion == "ALTERNA") {
                            afiliacionTdc2 = a.idAfiliacion;
                            afiliaciones.tdc2Afiliacion = a.afiliacion;
                            afiliaciones.tdc2Buzon = a.idBuzon;
                            afiliaciones.tdc2Sucursal = a.idSucursal;
                          }
                          break;
                        case "CARGO_AUTOMATICO_AMEX":
                          afiliacionAmex = a.idAfiliacion;
                          afiliaciones.amexAfiliacion = a.afiliacion;
                          afiliaciones.amexBuzon = a.idBuzon;
                          afiliaciones.amexSucursal = a.idSucursal;
                          break;
                      }
                    });

                    this.setState({
                      afiliacionesShow: true,
                      afiliaciones,
                      afiliacionClub: row.original.clubId,
                      afiliacionTdc1,
                      afiliacionTdc2,
                      afiliacionAmex
                    });
                  })
                  .catch(e =>
                    errorNotification(
                      "No se pudieron consultar las afiliaciones actuales"
                    )
                  );
              }}
              success={null}
              confirmModal={null}
            />
          )}
        </div>
      ),
      width: 150
    },
    {
      Header: () => <p className="font-weight-bold">Zuma Garantía</p>,
      Cell: row => (
        <div style={{ fontSize: "1.2em" }}>
          {userHasPermission(
            permissionCodes.REPORT_CLUBS_CAT_CONFIG_ACTIVATE_CLUB_FOR_ZUMA_GARANTIA
          ) && (
            <SideActionButton
              buttonId={`activate_zuma_garantia_${row.original.clubId}`}
              tooltipTitle={
                !!row.original.zumaGarantiaActiva ? "Desactivar" : " Activar"
              }
              icon={
                !!row.original.zumaGarantiaActiva
                  ? faTimesCircle
                  : faCheckCircle
              }
              color={!!row.original.zumaGarantiaActiva ? "#710" : "#070"}
              onClick={() =>
                this.activateZumaGarantia(
                  row.original.clubId,
                  !row.original.zumaGarantiaActiva
                )
              }
              success={null}
              confirmModal={{
                title: !!row.original.zumaGarantiaActiva
                  ? "Desactivar Zuma Garantía"
                  : " Activar Zuma Garantía",
                message: !!row.original.zumaGarantiaActiva
                  ? `¿Desea desactivar el club ${row.original.nombre} para Zuma Garantía?`
                  : `¿Desea activar el club ${row.original.nombre} para Zuma Garantía?`
              }}
            />
          )}
        </div>
      ),
      width: 150
    }
  ];

  activateCatProcess = (clubId, active) => {
    infoNotification("Actualizando estatus de proceso cat...");
    return updateActiveForCat(clubId, active)
      .then(() => {
        successNotification("Estatus de proceso cat actualizado.");
        this.setState({ clubs: [] });
        this.getClubsCatalog();
      })
      .catch(handleRequestError);
  };

  activateZumaGarantia = (clubId, active) => {
    infoNotification("Actualizando estatus de activación de Zuma Garantía...");
    return updateActivateZumaGarantia(clubId, active)
      .then(() => {
        successNotification(
          "Estatus de activación de Zuma Garantía actualizado."
        );
        this.setState({ clubs: [] });
        this.getClubsCatalog();
      })
      .catch(handleRequestError);
  };

  executeCatProcessForClub = (clubId, clubName) => {
    infoNotification(
      "Ejecutando proceso de cargos automáticos para club " + clubName
    );
    return autChargesExecuteProcess(clubId)
      .then(() => {
        successNotification("Proceso ejecutado correctamente.");
      })
      .catch(handleRequestError);
  };

  saveClubMitCredentials = data => {
    infoNotification(
      "Actualizando credenciales MIT para uso de terminal TPV..."
    );
    saveMitTerminalCredentialsByClubIdRequest(data)
      .then(() => {
        successNotification("Credenciales actualizadas correctamente.");
        this.setState({
          mitCredentialsValues: null,
          mitCredentialsClubId: null,
          mitCredentialsIsOpen: false
        });
      })
      .catch(handleRequestError);
  };

  render() {
    const { clubs } = this.state;

    return (
      <Container className="p-0">
        <div
          className="alert alert-warning"
          style={{ margin: "10px 0 10px 0", padding: "6px 8px" }}
          role="alert"
        >
          <p className="m-2">
            * Los tipos de proceso de cargo automático (TDC, TDD y AMEX) que se
            ejecutarán en el día se basan en la configuración de calendario de
            envío de cargos automáticos <br />
            <br />
            * El horario de ejecución del proceso de envío de cargos automático
            es todos los días del mes a las 14:00 horas. Las respuestas son
            procesadas todos los días del mes a las 22:00 horas. <br />
          </p>
        </div>
        <ReactTable
          data={clubs || []}
          columns={this.columns}
          className="mt-2 -striped -highlight"
          sortable={false}
          resizable={false}
          pageSize={100}
        />

        <FormBuilderModal
          isOpen={this.state.mitCredentialsIsOpen}
          title="Configuración credenciales TPV"
          message="Complete la información requerida"
          ok={values => {
            this.saveClubMitCredentials({
              ...values,
              clubId: this.state.mitCredentialsClubId
            });
          }}
          cancel={() =>
            this.setState({
              mitCredentialsValues: null,
              mitCredentialsClubId: null,
              mitCredentialsIsOpen: false
            })
          }
          formConfig={credencialesMitTpvFormConfig}
          values={this.state.mitCredentialsValues}
          size={"md"}
        />

        {this.state.afiliaciones && (
          <FormBuilderModal
            isOpen={this.state.afiliacionesShow}
            title="Información adicional"
            message="Complete la información del documento"
            ok={values => {
              const afiliaciones = [
                {
                  idClub: this.state.afiliacionClub,
                  idAfiliacion: this.state.afiliacionTdc1,
                  afiliacion: values.tdc1Afiliacion,
                  tipoAfiliacion: "ORIGINAL",
                  servicio: "CARGO_AUTOMATICO_TDC",
                  idBuzon: values.tdc1Buzon,
                  idSucursal: values.tdc1Sucursal
                },
                {
                  idClub: this.state.afiliacionClub,
                  idAfiliacion: this.state.afiliacionAmex,
                  afiliacion: values.amexAfiliacion,
                  tipoAfiliacion: "ORIGINAL",
                  servicio: "CARGO_AUTOMATICO_AMEX",
                  idBuzon: values.amexBuzon,
                  idSucursal: values.amexSucursal
                }
              ];

              if (values.tdc2Afiliacion || this.state.afiliacionTdc2) {
                afiliaciones.push({
                  idClub: this.state.afiliacionClub,
                  idAfiliacion: this.state.afiliacionTdc2,
                  afiliacion: values.tdc2Afiliacion || "",
                  tipoAfiliacion: "ALTERNA",
                  servicio: "CARGO_AUTOMATICO_TDC",
                  idBuzon: values.tdc2Buzon || null,
                  idSucursal: values.tdc2Sucursal || null
                });
              }

              putAfiliaciones(afiliaciones, this.state.afiliacionClub)
                .then(resp => {
                  successNotification("Afiliaciones almacenadas correctamente");
                  this.setState({
                    afiliacionesShow: false,
                    afiliaciones: null,
                    afiliacionClub: null,
                    afiliacionTdc1: null,
                    afiliacionTdc2: null,
                    afiliacionAmex: null
                  });
                })
                .catch(e =>
                  errorNotification(
                    "No se pudieron consultar las afiliaciones actuales"
                  )
                );
            }}
            cancel={() => this.setState({ afiliacionesShow: false })}
            formConfig={afiliacionesFormConfig}
            values={this.state.afiliaciones}
            size={"md"}
          />
        )}
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(CatClubConfig));
