import FormBuilderConfig from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import IDateBuilder from "../../../../../toolkit/baseForms2/iDateBuilder/IDateBuilder";
import ISelectBuilder from "../../../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import ITextBuilder from "../../../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import IClubSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iClubSelectorBuilder/IClubSelectorBuilder";
import IUserSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";
import * as validators from "./BAsignacion.validators";

export type Inputs =
  | "tipoBusqueda"
  | "idClub"
  | "idProducto"
  | "fechaInicio"
  | "fechaFin"
  | "idEmpleado"
  | "idCliente"
  | "idClubBase"
  | "idPublico"
  | "folioFactura";
export type Buttons = "buscar";

export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "tipoBusqueda",
      label: "* Tipo de búsqueda",
      bts: {
        mCol: 3
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Producto",
        data: [
          { value: "EMPLEADO", label: "Empleado" },
          { value: "CLIENTE", label: "Cliente" },
          { value: "MEMBRESIA", label: "Membresía" },
          { value: "FACTURA", label: "Factura" }
        ]
      }
    },
    {
      name: "idClub",
      label: "Club",
      bts: {
        mCol: 3
      },
      component: IClubSelectorBuilder,
      props: {
        hidden: validators.hiddenForClub
      }
    },
    {
      name: "idProducto",
      label: "* Producto",
      bts: {
        mCol: 12
      },
      props: {
        hidden: validators.hiddenIfNotBusquedaProducto
      }
    },
    {
      name: "fechaInicio",
      label: "Fecha de inicio",
      bts: {
        mCol: 3
      },
      component: IDateBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaProducto
      }
    },
    {
      name: "fechaFin",
      label: "Fecha de fin",
      bts: {
        mCol: 3
      },
      component: IDateBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaProducto
      }
    },
    {
      name: "idEmpleado",
      label: "* Empleado",
      bts: {
        mCol: 6
      },
      component: IUserSelectorBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaEmpleado
      }
    },
    {
      name: "idCliente",
      label: "* Cliente",
      bts: {
        mCol: 6
      },
      props: {
        hidden: validators.hiddenIfNotBusquedaCliente
      }
    },
    {
      name: "idClubBase",
      label: "* Club base",
      bts: {
        mCol: 3
      },
      component: IClubSelectorBuilder,
      props: {
        emptyLabel: "Club base",
        options: [],
        hidden: validators.hiddenIfNotBusquedaMembresia
      }
    },
    {
      name: "idPublico",
      label: "* Membresía",
      bts: {
        mCol: 6
      },
      component: ITextBuilder,
      props: {
        emptyLabel: "Seleccione una opción",
        options: [],
        hidden: validators.hiddenIfNotBusquedaMembresia
      }
    },
    {
      name: "folioFactura",
      label: "* Folio de factura",
      bts: {
        mCol: 3
      },
      component: ITextBuilder,
      props: {
        emptyLabel: "Seleccione una opción",
        options: [],
        hidden: validators.hiddenIfNotBusquedaFactura
      }
    }
  ],
  buttons: [
    {
      name: "buscar",
      label: "Buscar",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        mOff: 9,
        mCol: 3
      },
      props: {
        addTopMargin: false
      }
    }
  ],
  validations: {
    idProducto: {
      requiredIfBusquedaProducto: validators.requiredIfBusquedaProducto
    },
    idEmpleado: {
      requiredIfBusquedaEmpleado: validators.requiredIfBusquedaEmpleado
    },
    idClubBase: {
      requiredIfBusquedaMembresia: validators.requiredIfBusquedaMembresia
    },
    idPublico: {
      requiredIfBusquedaMembresia: validators.requiredIfBusquedaMembresia
    },
    folioFactura: {
      requiredIfBusquedaFactura: validators.requiredIfBusquedaFactura
    }
  },
  rows: [
    [
      "tipoBusqueda",
      "idClub",
      "fechaInicio",
      "fechaFin",
      "idEmpleado",
      "idCliente",
      "idClubBase",
      "idPublico",
      "folioFactura"
    ],
    ["idProducto"],
    ["buscar"]
  ]
};
