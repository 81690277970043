import FormBuilderConfig from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import ISelectBuilder from "../../../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import IUserSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";
import { userHasPermission } from "../../../../../utils/accessTree";
import permissionCodes from "../../../../../utils/permissionCodes";

const {
  COMISIONES_V2_ASIGNAR_EVENTO_IGNORAR_PUESTO: IGNORAR_PUESTO
} = permissionCodes;

export type Inputs =
  | "idEmpleado"
  | "anio"
  | "mes"
  | "ignorarPuesto"
  | "razonIgnorarPuesto";
export type Buttons = "aceptar" | "cancelar";

const anioActual = new Date().getFullYear();

export const formConfigPrograma: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "idEmpleado",
      label: "* Instructor",
      bts: {
        mCol: 12
      },
      component: IUserSelectorBuilder
    },
    {
      name: "anio",
      label: "* Periodo",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Año",
        options: [
          { value: anioActual - 1, label: anioActual - 1 },
          { value: anioActual, label: anioActual },
          { value: anioActual + 1, label: anioActual + 1 }
        ]
      }
    },
    {
      name: "mes",
      label: "",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        options: [
          monthOption(-3),
          monthOption(-2),
          monthOption(-1),
          monthOption(0),
          monthOption(1)
        ]
      }
    },
    {
      name: "ignorarPuesto",
      label: "Ignorar puesto de trabajo",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "No",
        options: [{ value: "true", label: "Si" }],
        hidden: () => userHasPermission(IGNORAR_PUESTO)
      }
    },
    {
      name: "razonIgnorarPuesto",
      label: "Razón",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Seleccione una opción",
        options: [
          {
            value: "Personal no cubierto en el puesto",
            label: "Personal no cubierto en el puesto"
          },
          {
            value: "Baja inesperada de colaborador(a)",
            label: "Baja inesperada de colaborador(a)"
          },
          {
            value: "Excepción aceptada por T. y C.",
            label: "Excepción aceptada por T. y C."
          }
        ],
        hidden: () => userHasPermission(IGNORAR_PUESTO),
        disabled: formValue => formValue.ignorarPuesto !== "true"
      }
    }
  ],
  buttons: [
    {
      name: "aceptar",
      label: "Aceptar",
      isSubmit: true,
      bts: {
        mCol: 4
      }
    },
    {
      name: "cancelar",
      label: "Cancelar",
      bts: {
        mCol: 4
      }
    }
  ],
  validations: {
    idEmpleado: {
      required: true
    },
    anio: {
      required: true
    },
    mes: {
      required: true
    },
    razonIgnorarPuesto: {
      requiredIfIgnorarPuesto: {
        validator: (value, formValue) =>
          formValue.ignorarPuesto === "true" && !value,
        message: () => "Campo obligatorio"
      }
    }
  },
  rows: []
};

function monthOption(months: number) {
  const fechaNueva = new Date();
  fechaNueva.setMonth(fechaNueva.getMonth() + months);
  return {
    value: fechaNueva.getMonth(),
    label: fechaNueva.toLocaleString("es-MX", { month: "long" }).toUpperCase()
  };
}
